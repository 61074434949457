<template>
  <!-- :visible="true" -->
  <b-modal id="edit-poll-sidebar" ref="edit_poll_sidebar" hide-footer
    :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" size="lg"
    @shown="resetFormData" @change="(val) => $emit('update:sidebarPollEditActive', val)" header-class="sticky-top">
    <!-- <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="confirmCloseButton">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
     
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom sticky-top"
      >
        <h3 v-if="!data_local.hashid" class="mb-0 font-weight-bold">
          Create Poll
        </h3>
        <h3 v-else class="mb-0 font-weight-bold blog-title">Edit Poll</h3>
        <div>
          <b-button
            pill
            v-if="!data_local.hashid"
            variant="primary"
            @click="formSubmitted()"
            class="px-3 blog-btn"
          >
            Submit
          </b-button>

          <b-button
            pill
            v-else
            variant="primary"
            @click="formUpdate()"
            class="px-3 blog-btn"
          >
            Update
          </b-button>
        </div>
      </div>

     
      <validation-observer ref="pollUpdate" tag="form" style="overflow: hidden">
        <b-form autocomplete="off" id="poll-form" class="p-2">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Poll Name"
                  rules="required"
                >
                  <label class="required-l" for="name">Poll Title</label>
                  <b-form-input
                    id="name"
                    v-model="data_local.title"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Poll Description"
                  rules="required"
                >
                  <label class="required-l" for="description"
                    >Poll Description</label
                  >
                  <b-form-textarea
                    id="description"
                    v-model="data_local.description"
                    name="description"
                    placeholder="Enter Poll Description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Allow Multiple Selection"
                  rules="required"
                >
                  <label class="required-l" for="allow_multiple"
                    >Allow Multiple Selection </label
                  ><br />
                  <b-form-checkbox
                    class="ml-1"
                    name="allow_multiple"
                    v-model="data_local.allow_multiple"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Voter Identification"
                  rules="required"
                >
                  <label class="required-l" for="voter_identity_type"
                    >Voter Identification Type</label
                  ><br />
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.voter_identity_type"
                      plain
                      name="voter_identity_type"
                      value="anonymous"
                    >
                      Anonymous Vote
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.voter_identity_type"
                      plain
                      name="voter_identity_type"
                      value="identified"
                    >
                      Identified Vote
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Voter Identification"
                  rules="required"
                >
                  <label class="required-l" for="limited_time"
                    >Poll Duration in Hours</label
                  ><br />
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.limited_time"
                      plain
                      name="limited_time"
                      value="true"
                    >
                      Limited
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.limited_time"
                      plain
                      name="limited_time"
                      value="false"
                    >
                      Unlimited
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="data_local.limited_time == 'true'">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Enter Duration"
                  rules="required"
                >
                  <b-form-group label="Poll Duration in Hours" label-for="time_in_hours">
                    <b-form-input
                      id="time_in_hours"
                      v-model="data_local.time_in_hours"
                      placeholder="Enter Duration in Hours ex(24, 48,...)"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-for="(option, k) in data_local.options" :key="k">
            <b-col md="8">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Poll Option">
                  <label class="required-l" for="name"
                    >Poll Option #{{ k + 1 }}</label
                  >
                  <b-form-input
                    id="options"
                    v-model="data_local.options[k].option_title"
                    name="options"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Poll Option"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Poll Option">
                  <label class="required-l" for="name">Edit More Option</label
                  ><br />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle"
                    @click="addOption"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="k > 1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon rounded-circle ml-2"
                    @click="removeOption(k)"
                  >
                    <feather-icon icon="DeleteIcon" />
                  </b-button>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template> -->
     <!-- New Poll Design-->
     <template #modal-header="{ close }">
       <!-- Header -->
       <h3 class="mb-0 font-weight-bold blog-title" >Update Poll</h3>
        <div class="next-expand d-flex ml-auto align-items-center">
          <b-button id="next-btn" pill variant="outline-primary" class="px-3 py-75" v-if="!page2" @click="page2 = true;">
            Preview
          </b-button>
          <b-button id="back-edit" pill variant="outline-primary" class="px-3 py-75" v-if="page2" @click="page2 = false;">
            <span class="d-md-flex d-none">Back To Editing</span>
            <span class="d-md-none">Back</span>
          </b-button>
          <!-- <b-link
            class="btn btn-icon rounded-circle btn-outline-secondary mr-1 p-50 fullscreen-icon"
            @click="fullScreen = !fullScreen"
          >
          <feather-icon :icon="!fullScreen ? 'Maximize2Icon' : 'Minimize2Icon'" size="18" class="" />
        </b-link> -->
        <b-link class="fullscreen-icon"  @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-default title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-default title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
          </b-link>
      </div>
      <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="confirmCloseButton" />
    </template>
    <!-- BODY -->
    <validation-observer ref="pollUpdate" tag="form" v-if="!page2">
        <b-form autocomplete="off" id="poll-form">
          <b-row>
            <b-col md="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Voter Identification" rules="required">
                <div class="demo-inline-spacing justify-content-start poll-type ">
                  <b-form-radio v-model="data_local.voter_identity_type" plain name="voter_identity_type"
                    value="anonymous"  class="d-flex align-items-center custom-control custom-radio">
                    Anonymous Type
                  </b-form-radio>
                  <b-form-radio v-model="data_local.voter_identity_type" plain name="voter_identity_type"
                    value="identified" class="d-flex align-items-center custom-control custom-radio">
                    Identified Vote
                  </b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Poll Name"
                  rules="required"
                >
                  <label for="name">Poll Title</label>
                  <b-form-input
                    id="name"
                    v-model="data_local.title"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Poll Description"
                  rules="required"
                >
                  <label for="description"
                    >Poll Description</label
                  >
                  <b-form-textarea
                    id="description"
                    v-model="data_local.description"
                    name="description"
                    placeholder="Enter Poll Description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Voter Identification"
                  rules="required"
                >
                <div class="demo-inline-spacing justify-content-start">
                  <label for="limited_time">Poll Duration Type</label>
                  <v-select :options="typeOptions" v-model="selectedType"  class="w-100" :reduce="(val) => val.value"
                    placeholder="Select Option..." :clearable="false" />
                </div>
                  <!-- <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="data_local.limited_time"
                      plain
                      name="limited_time"
                      value="true"
                    >
                      Limited
                    </b-form-radio>
                    <b-form-radio
                      v-model="data_local.limited_time"
                      plain
                      name="limited_time"
                      value="false"
                    >
                      Unlimited
                    </b-form-radio>
                  </div> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="data_local.limited_time == 'true'">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Enter Duration"
                  rules="required"
                >
                  <b-form-group label="Poll Duration in Hours" label-for="time_in_hours">
                    <b-form-input
                      id="time_in_hours"
                      v-model="data_local.time_in_hours"
                      placeholder="Enter Duration in Hours ex(24, 48,...)"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="polloptions" v-for="(option, k) in data_local.options" :key="k">
            <b-col md="12">
            <validation-provider #default="{ errors }" name="Poll Option">           
              <b-form-group>
                  <label for="name"
                    >Poll Option {{ k + 1 }}</label
                  >
                  <b-form-input
                    id="options"
                    v-model="data_local.options[k].option_title"
                    name="options"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Poll Option"
                  />
              </b-form-group>
              <b-form-group class="w-100 text-right">
                <b-button v-if="k > 1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
                  class="btn-icon rounded-circle ml-2" @click="removeOption(k)">
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Poll Option">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle add-options d-flex align-items-center justify-content-end w-100 py-0 px-1"
                    @click="addOption"
                  >
                  <feather-icon icon="PlusIcon" size="15" /> <label for="name" class="mb-0 ml-50">Edit More Option</label>
                  </b-button>
                </validation-provider>
              </b-form-group>
            </b-col>
            
          <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Allow Multiple Selection"
                  rules="required"
                >
                  <b-form-checkbox
                    name="allow_multiple"
                    v-model="data_local.allow_multiple"
                    inline
                  >
                  <label for="allow_multiple">Allow Multiple Selection </label>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    <!-- Preview Popup -->
    <div class="community-preview back-editform px-2" v-if="page2 == true">
      <b-card>
        <div class="d-flex justify-content-between align-items-center post-header mb-2">
          <div class="d-flex align-items-center">
            <!-- avatar -->
            <b-avatar :src="userData.avatar" style="background: transparent" size="40" class="mr-75" />
            <!--/ avatar -->
            <div class="profile-user-info">
              <h6 class="mb-0">
                {{ userData.name }}
              </h6>
              <small style="font-size: 12px">
                {{ userData.designation }}
              </small>
            </div>
          </div>
          <!-- <div class="d-flex align-items-center">
            
            <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
              no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16"
                  class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                  title="More Options" />
              </template>
              <b-dropdown-item class="btn-sm p-0 text-muted">
                <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                <span class="">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item class="btn-sm p-0 text-muted">
                <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                <span class="">Copy</span>
              </b-dropdown-item>
              <b-dropdown-item class="btn-sm p-0">
                <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                <span class="">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div> -->
        </div>
        <b-card-text class="mb-50">
          <h3 class="text-default">{{ data_local.title }}</h3>
        </b-card-text>
        <div>
          <h3 class="qst">{{ data_local.description }}</h3>
          <div v-if="data_local.allow_multiple == 'true'">
            <b-form-checkbox
              v-for="(option, k) in data_local.options"
              :key="k"
              :name="'pollCheckbox' + k"
              :value="option.hashid"
              class="custom-control-primary mt-1"
            >
              {{ option.option_title }}
            </b-form-checkbox>
          </div>
          <div v-else>
            <b-form-radio
              v-for="(option, k) in data_local.options"
              :key="k"
              :name="'pollCheckbox' + k"
              :value="option.hashid"
              class="custom-control-primary mt-1"
            >
              {{ option.option_title }}
            </b-form-radio>
          </div>

          <!-- <b-row class="mt-1 align-items-center">
            <b-col md="7" class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Vote
              </b-button>
              <b-button
                class="ml-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
              >
                View Stats
              </b-button>
              <b-button
                class="ml-1"
                v-if="data_local.voter_identity_type == 'identified'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
              >
                View Voters
              </b-button>
            </b-col>
          </b-row>
          <b-badge variant="light-primary" class="mt-1 mb-1">
            Poll Status : In Progress
          </b-badge>
          <b-badge
            v-if="data_local.voter_identity_type == 'identified'"
            variant="info"
            class="badge-glow ml-1"
          >
            Identified Vote
          </b-badge>
          <b-badge
            v-else
            variant="danger"
            class="badge-glow ml-0 ml-lg-1 mt-2"
          >
            Anonymous Vote
          </b-badge> -->
          <b-button type="button" class="my-2" variant="outline-secondary" pill> Open Vote </b-button>
        </div>
      </b-card>
      <b-row class="pb-2">
        <b-col cols="12" class="select-post d-flex align-items-center justify-content-end">
          <div class="demo-inline-spacing justify-content-start">
            <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        pill
                        @click="formUpdate()"
                        class="mr-0 create-poll"
                      >
                        Update
                      </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Preview Popup -->
    <!-- End New Poll Design -->
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { bus } from "@/main.js";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import vSelect from "vue-select";
import VueCookies from "vue-cookies";
import QuillInput from "@/components/QuillInput.vue";
Vue.use(VueCookies);
import bomb from "@/libs/bomb/bomb";

import $ from "jquery";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    QuillInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarPollEditActive",
    event: "update:sidebarPollEditActive",
  },
  props: {
    itemId: {
      type: String,
      required: true,
      default: "",
    },
    sidebarPollEditActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      page2: false,
      fullScreen: false,
      userData: this.$cookies.get("userData"),
      data_local: {
        title: "",
        description: "",
        options: ["", ""],
        deleted_options: [],
        allow_multiple: false,
        voter_identity_type: "identified",
        limited_time: false,
      },
      customFields: [],
      typeOptions: [
        { value: "Limited", label: "Limited" },
        { value: "UnLimited", label: "Unlimited" },
      ],
      selectedType:'UnLimited',
    };
  },
  computed: {},
  setup(data_local) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarPollEditActive(val) {
      this.data_local = {
        title: "",
        description: "",
        options: ["", ""],
        deleted_options:[],
        allow_multiple: false,
        limited_time: false,
        time_in_hours: 12,
        voter_identity_type: "identified",
      };
      if (this.itemId) {
        this.fetchItem(this.itemId);
      }
    },
    selectedType(val){
      if(val == 'Limited'){
        this.data_local.limited_time = 'true';
      }else{
        this.data_local.limited_time = 'false' ;
      }
    }
  },
  created() {},
  mounted() {
    // this.getCustomFields();
  },
  methods: {
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$refs.edit_poll_sidebar.hide();
          this.data_local = {
            title: "",
            description: "",
            options: ["", ""],
            allow_multiple: false,
            limited_time: false,
            time_in_hours: 0,
            voter_identity_type: "identified",
          };
          this.page2 = false;
        }
      });
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/polls/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {})
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    resetFormData() {
      this.$refs.pollUpdate.reset();
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData();
      formData.append("description", self.data_local.description);
      formData.append(
        "voter_identity_type",
        self.data_local.voter_identity_type
      );
      formData.append("allow_multiple", self.data_local.allow_multiple);
      formData.append("title", self.data_local.title);
      formData.append("time_in_hours", self.data_local.time_in_hours);
      formData.append("limited_time", self.data_local.limited_time);
      formData.append("options", self.data_local.options);

      let data = {};
      data.url = "polls";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this poll
          this.isBusy = false;
          self.$refs.add_poll_sidebar.hide();
          this.page2 = false;
          bus.$emit("loadPolls");
          
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Poll Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Poll Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Poll Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Poll Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData();
      formData.append("description", self.data_local.description);
      formData.append(
        "voter_identity_type",
        self.data_local.voter_identity_type
      );
      formData.append("allow_multiple", self.data_local.allow_multiple);
      formData.append("title", self.data_local.title);
      formData.append("time_in_hours", self.data_local.time_in_hours);
      formData.append("limited_time", self.data_local.limited_time);
      formData.append("options", JSON.stringify(self.data_local.options));
      formData.append("deleted_options", JSON.stringify(self.data_local.deleted_options));
      formData.append("_method", "PATCH");

      let data = {};
      data.url = "polls/" + self.data_local.hashid;
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Update this Poll
          this.isBusy = false;
          this.$refs.edit_poll_sidebar.hide();
          bus.$emit("loadPolls");
          this.resetFormData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Poll Updated ",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Poll Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          bomb.realistic();
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Event Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Event Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.pollUpdate.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      let data = {};
      data.url = "polls/" + hashid;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.data_local = res.data.data;
          this.data_local.deleted_options=[];
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addOption() {
      this.data_local.options.push({
        created_at: "",
        hashid: "",
        id: 0,
        is_user_choice: false,
        option_title: "",
        poll_id: 0,
        updated_at: "",
      });
    },
    removeOption(index) {
      var option = this.data_local.options.splice(index, 1)[0];
      this.data_local.deleted_options.push(option);
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#edit-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>