<template>
  <div v-if="profileData && Object.keys(profileData).length" id="user-profile">
    <!-- Header Card -->
      <b-card class="profile-header">
      <!-- profile navbar -->
      <div class="profile-header-nav">
        <b-navbar toggleable="md" type="light" class="mb-0 p-0">
          <!-- toggle button -->
          <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
            <feather-icon icon="AlignJustifyIcon" size="21" />
          </b-navbar-toggle>
          <!--/ toggle button -->
          <!-- collapse -->
          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <div class="d-flex align-items-center justify-content-between w-100">
                  <b-tabs class="community-tabs">
                    <b-tab :active="channel.value == filterByType" @click="AddFilter(channel.value)"
                      v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value">
                      <template #title>
                        <span>{{ channel.label }}
                        </span>
                      </template>
                    </b-tab>
                    <b-tab :active="filterByType == 'post'" @click="AddFilter('post')" v-if="!$route.params.id && userData.application_mode == 'company'">
                      <template #title>
                        <span>Feeds</span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('event')" :active="filterByType == 'event'">
                      <template #title>
                        <span>Events
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.events"
                            v-b-modal="'unseen-events'" v-b-tooltip.hover.v-default
                            :title="unseenBadge.events + ' unseen event(s)'">
                            {{ unseenBadge.events }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('poll')" :active="filterByType == 'poll'">
                      <template #title>
                        <span>Polls
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.polls"
                            v-b-tooltip.hover.v-default :title="unseenBadge.polls + ' unseen poll(s)'">
                            {{ unseenBadge.polls }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('saved_posts')"
                      v-if="$route.params.id && $route.params.id == userData.hashid">
                      <template #title>
                        <span>Saved Posts </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('review_posts')"
                      v-if="$can('Zircly Admin') && userData.need_approval == 'true'">
                      <template #title>
                        <span>Review Posts </span>
                      </template>
                    </b-tab>

                    <!-- <b-tab v-if="onGoingProgram.hashid" @click="AddFilter('secret_santa_post')">
                      <template #title>
                        <span>Secret Santa Game
                          <b-avatar square style="background-color: transparent" v-b-tooltip.hover.v-default
                            title="Secret Santa Program" :to="{ name: 'secret-santa' }" :src="require('@/assets/images/secret-santa/santa-claus.png')
                              " v-if="$can('secret_santa_access') || $can('Zircly Admin')" size="sm" />
                        </span>
                      </template>
                    </b-tab> -->

                  </b-tabs>
                  <!-- Check in and check out-->
                  <div class="align-items-center ref-info d-none">
                    <b-button v-b-modal.modal-1 pill v-show="!userData.last_swipe_type ||
                      userData.last_swipe_type === 'Out'
                      " variant="primary" class="mr-1 login-btn"
                      v-if="$can('swipe_access') && userData.hashid == profileData.hashid">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#fff"
                            stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check In </span>
                    </b-button>
                    <b-button v-b-modal.modal-2 v-show="userData.last_swipe_type === 'In'
                      " variant="danger" class="mr-1 logout-btn" pill
                      v-if="$can('swipe_access') && userData.hashid == profileData.hashid" :hide-footer="true" centered modal-class="attend-check">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#fff"
                            stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check Out </span>
                    </b-button>
                    <b-button
                      v-if="$route.params.id && userData.hashid == profileData.hashid"
                        variant="primary"
                        class="ml-auto d-flex"
                        :to="{ name: 'profile' }"
                        pill
                      >
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span class="font-weight-bold d-none d-md-flex align-middle"
                          >Edit</span
                        >
                    </b-button>
                  </div>
                </div>
              </template>
              <!-- Recent Swipe Modal-->
              <b-modal id="recent-swipes" hide-footer size="lg" modal-class="modal-swipes d-none" centered
                title="Recent Swipes">
                <b-list-group v-if="recentSwipes && recentSwipes.length" class="swipes-group">
                  <b-list-group-item class="d-flex align-items-center swipe-mobile" v-for="swipe in recentSwipes"
                    :key="swipe.hashid">
                    <b>Date:</b><span>{{ swipe.date | formatDate }}</span>, <b>Time:</b>
                    <span>{{ swipe.time | formatTime }}</span>,<b>Swipe type:</b>
                    <span>{{ swipe.type }}</span>
                    <b-img v-if="swipe.user_feeling != 6" v-bind="{ width: 40, height: 40, class: 'mr-0 ml-auto' }" fluid
                      :src="mood_options[swipe.user_feeling]" alt="mood" />
                  </b-list-group-item>
                </b-list-group>
                <b-card-text v-else-if="recentSwipes && recentSwipes.length == 0">
                  <div class="item error text-center my-2 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                      style="background-color: transparent" />
                    <h4> OH NO!</h4>
                    <p class="d-block mb-1">No Records Found!</p>
                  </div>
                </b-card-text>
                <b-card-text v-else="recentSwipes == null">
                  <div class="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <b-spinner class="ml-auto" variant="primary" />
                  </div>
                </b-card-text>
              </b-modal>
            </b-tabs>
          </b-collapse>
          <!--/ collapse -->
        </b-navbar>
      </div>
      <!--/ profile navbar -->
    </b-card>
    <!-- End Header Card -->

    <!-- Posts Listing -->
    <div class="row mobile-size community-post justify-content-center" id="profile-info">
      <div class="col-lg-8 bottom-body left-data">
        <b-card class="your-post mb-0" v-if="!$route.params.id">
            <!-- <div :class="filterByType != 'post' ? 'col-sm-9' : 'col-sm-12'"> -->
              <div class="position-relative d-flex align-items-center cursor-pointer" v-if="filterByType != 'event' && filterByType != 'poll'">
                <b-link  :to="{ name: 'employee-profile', params: { id: userData.hashid },}" target='_blank'><b-avatar :src="userData.avatar" style="background: transparent" size="32" /></b-link>
                <b-input-group class="input-group-merge" v-b-modal.create-custompop>
                  <b-form-input  class="d-inline-block border-0" style="pointer-events: none;" placeholder="  Whats on your mind..." />
                </b-input-group>
                <div class="d-flex align-items-center create-events">
                  <feather-icon icon="CalendarIcon" stroke-width="1.25" class="cursor-pointer" v-b-tooltip.hover.v-default title="Create Event" v-b-toggle.add-event-sidebar/>
                  <svg v-b-tooltip.hover.v-default title="Create Poll" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" v-b-modal.add-poll-sidebar stroke-width="1.5">
                    <path d="M23.6172 3C23.1013 3 22.6065 3.20495 22.2416 3.56977C21.8768 3.93459 21.6719 4.42938 21.6719 4.94531V27.2397C21.6719 27.7556 21.8768 28.2504 22.2416 28.6152C22.6065 28.98 23.1013 29.185 23.6172 29.185H25.5625C26.0784 29.185 26.5732 28.98 26.938 28.6152C27.3029 28.2504 27.5078 27.7556 27.5078 27.2397V4.94531C27.5078 4.42938 27.3029 3.93459 26.938 3.56977C26.5732 3.20495 26.0784 3 25.5625 3H23.6172ZM12.918 15.3555C12.918 14.8395 13.1229 14.3447 13.4877 13.9799C13.8526 13.6151 14.3474 13.4102 14.8633 13.4102H16.8086C17.3245 13.4102 17.8193 13.6151 18.1841 13.9799C18.549 14.3447 18.7539 14.8395 18.7539 15.3555V27.2397C18.7539 27.7556 18.549 28.2504 18.1841 28.6152C17.8193 28.98 17.3245 29.185 16.8086 29.185H14.8633C14.3474 29.185 13.8526 28.98 13.4877 28.6152C13.1229 28.2504 12.918 27.7556 12.918 27.2397V15.3555ZM4.16406 8.5C4.16406 7.98407 4.36901 7.48927 4.73383 7.12446C5.09865 6.75964 5.59345 6.55469 6.10938 6.55469H8.05469C8.57062 6.55469 9.06541 6.75964 9.43023 7.12446C9.79505 7.48927 10 7.98407 10 8.5V27.2397C10 27.7556 9.79505 28.2504 9.43023 28.6152C9.06541 28.98 8.57062 29.185 8.05469 29.185H6.10938C5.59345 29.185 5.09865 28.98 4.73383 28.6152C4.36901 28.2504 4.16406 27.7556 4.16406 27.2397V8.5Z" stroke="#667085" stroke-width="1.5"/>
                  </svg>
                </div>
              </div>
              <div class="position-relative d-flex align-items-center cursor-pointer" v-if="filterByType == 'event' ">
                <b-link  :to="{ name: 'employee-profile', params: { id: userData.hashid },}" target='_blank'><b-avatar :src="userData.avatar" style="background: transparent" size="32" /></b-link>
                <b-input-group class="input-group-merge" v-b-toggle.add-event-sidebar>
                  <b-form-input  class="d-inline-block border-0" style="pointer-events: none;" placeholder="  Whats on your mind..." />
                </b-input-group>
                <div class="d-flex align-items-center create-events">
                  <!-- <feather-icon icon="CalendarIcon" stroke-width="1.5" class="cursor-pointer" v-b-tooltip.hover.v-default title="Create Post" v-b-toggle.add-event-sidebar/> -->

                  <svg v-b-modal.create-custompop id="tooltip-button-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_14263_33998)">
                      <mask id="mask0_14263_33998" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                        <path d="M32 0H0V32H32V0Z" fill="white"/>
                      </mask>
                      <g mask="url(#mask0_14263_33998)">
                        <mask id="mask1_14263_33998" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                          <path d="M32 0H0V32H32V0Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask1_14263_33998)">
                          <path d="M4 5.88889C4 5.12272 4.36123 4.3879 5.00421 3.84614C5.64718 3.30437 6.51927 3 7.42857 3H24.5714C25.4807 3 26.3529 3.30437 26.9959 3.84614C27.6389 4.3879 28 5.12272 28 5.88889V26.1111C28 26.8772 27.6389 27.6122 26.9959 28.1539C26.3529 28.6957 25.4807 29 24.5714 29H7.42857C6.51927 29 5.64718 28.6957 5.00421 28.1539C4.36123 27.6122 4 26.8772 4 26.1111V5.88889Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 10H22" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 16H22" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 22H18" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_14263_33998">
                        <rect width="32" height="32" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <b-tooltip target="tooltip-button-1" class="custom-tool">Create Post</b-tooltip>
                  <svg v-b-tooltip.hover.v-default title="Create Poll" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" v-b-modal.add-poll-sidebar stroke-width="1.5">
                    <path d="M23.6172 3C23.1013 3 22.6065 3.20495 22.2416 3.56977C21.8768 3.93459 21.6719 4.42938 21.6719 4.94531V27.2397C21.6719 27.7556 21.8768 28.2504 22.2416 28.6152C22.6065 28.98 23.1013 29.185 23.6172 29.185H25.5625C26.0784 29.185 26.5732 28.98 26.938 28.6152C27.3029 28.2504 27.5078 27.7556 27.5078 27.2397V4.94531C27.5078 4.42938 27.3029 3.93459 26.938 3.56977C26.5732 3.20495 26.0784 3 25.5625 3H23.6172ZM12.918 15.3555C12.918 14.8395 13.1229 14.3447 13.4877 13.9799C13.8526 13.6151 14.3474 13.4102 14.8633 13.4102H16.8086C17.3245 13.4102 17.8193 13.6151 18.1841 13.9799C18.549 14.3447 18.7539 14.8395 18.7539 15.3555V27.2397C18.7539 27.7556 18.549 28.2504 18.1841 28.6152C17.8193 28.98 17.3245 29.185 16.8086 29.185H14.8633C14.3474 29.185 13.8526 28.98 13.4877 28.6152C13.1229 28.2504 12.918 27.7556 12.918 27.2397V15.3555ZM4.16406 8.5C4.16406 7.98407 4.36901 7.48927 4.73383 7.12446C5.09865 6.75964 5.59345 6.55469 6.10938 6.55469H8.05469C8.57062 6.55469 9.06541 6.75964 9.43023 7.12446C9.79505 7.48927 10 7.98407 10 8.5V27.2397C10 27.7556 9.79505 28.2504 9.43023 28.6152C9.06541 28.98 8.57062 29.185 8.05469 29.185H6.10938C5.59345 29.185 5.09865 28.98 4.73383 28.6152C4.36901 28.2504 4.16406 27.7556 4.16406 27.2397V8.5Z" stroke="#667085" stroke-width="1.5"/>
                  </svg>
                </div>
              </div>
              <div class="position-relative d-flex align-items-center cursor-pointer" v-if="filterByType == 'poll'">
                <b-link  :to="{ name: 'employee-profile', params: { id: userData.hashid },}" target='_blank'><b-avatar :src="userData.avatar" style="background: transparent" size="32" /></b-link>

                <b-input-group class="input-group-merge" v-b-modal.add-poll-sidebar>
                  <b-form-input  class="d-inline-block border-0" style="pointer-events: none;" placeholder="  Whats on your mind..." />
                </b-input-group>
                <div class="d-flex align-items-center create-events">
                  <svg v-b-modal.create-custompop id="tooltip-button-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_14263_33998)">
                      <mask id="mask0_14263_33998" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                        <path d="M32 0H0V32H32V0Z" fill="white"/>
                      </mask>
                      <g mask="url(#mask0_14263_33998)">
                        <mask id="mask1_14263_33998" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                          <path d="M32 0H0V32H32V0Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask1_14263_33998)">
                          <path d="M4 5.88889C4 5.12272 4.36123 4.3879 5.00421 3.84614C5.64718 3.30437 6.51927 3 7.42857 3H24.5714C25.4807 3 26.3529 3.30437 26.9959 3.84614C27.6389 4.3879 28 5.12272 28 5.88889V26.1111C28 26.8772 27.6389 27.6122 26.9959 28.1539C26.3529 28.6957 25.4807 29 24.5714 29H7.42857C6.51927 29 5.64718 28.6957 5.00421 28.1539C4.36123 27.6122 4 26.8772 4 26.1111V5.88889Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 10H22" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 16H22" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10 22H18" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_14263_33998">
                        <rect width="32" height="32" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <b-tooltip target="tooltip-button-1" class="custom-tool">Create Post</b-tooltip>
                  <feather-icon icon="CalendarIcon" stroke-width="1.25" class="cursor-pointer" v-b-tooltip.hover.v-default title="Create Event" v-b-toggle.add-event-sidebar/>
                </div>
              </div>
            <!-- Custom Create Popup -->
            <b-modal id="create-custompop" size="lg" title="custom-community" hide-footer hide-header
              :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" centered>
              <div class="d-flex align-items-center justify-content-between pop-header">
                <!-- Option Select -->
                <!-- <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="post_form_data.other_details.post_type"
                  :options="postTypeOptions" class="w-auto create-options" :reduce="(val) => val.value"
                  placeholder="Select type.." :clearable="false" @input="handleTypeChange" 
                  /> -->
                  <b-tabs class="next-expand" v-model="postFormTabIndex">
                    <b-tab id="back-edit"
                    @click="page2 = false;writeai = false">
                      <template #title>
                        <span v-if="post_form_data.hashid">Edit Post</span>
                        <span v-else>Add Post</span>
                      </template>
                    </b-tab>
                    <b-tab id="write-ai"  @click="writeai = true; page2=false" >
                      <template #title class="ai-tab">
                        <span class="ai-header">Write With AI</span>
                      </template>
                    </b-tab>
                    <b-tab id="next-btn" :disabled="!(post_form_data.title || post_form_data.body)"
                    @click="page2 = true;writeai = false">
                      <template #title>
                        <span>Preview Post</span>
                      </template>
                    </b-tab>
                  </b-tabs> 
                  <div class="pop-rightaction d-flex align-items-center" >
                    <div class="d-flex align-items-center gap-4"  v-if="isSpinning" >
                      <feather-icon @click="saveDraft()" size="16" :class="{'fa-spinner': isSpinning, 'fa-spin': isSpinning}"  class=" text-secondary cursor-pointer fas fa-sync" icon="RefreshCcwIcon"
                      /> 
                      <p class="m-0 after-save"> Saving... </p>
                    </div>
                    <!--- After Saved -->
                    <div class="d-flex align-items-center gap-4" v-if="!isSpinning && post_form_data.hashid">
                      <feather-icon size="16" class="cursor-pointer" icon="CheckIcon"
                      /> 
                      <p class="m-0 after-save" @click="saveDraft()" > Saved </p>
                    </div>
                    <!-- End After Saved -->
                    <!-- Write with AI History -->
                    <div class="d-flex align-items-center gap-10" v-if="writeai" >
                      <feather-icon size="20" class="cursor-pointer" icon="PlusCircleIcon" stroke="#667085" stroke-width="1.5" @click="AIChat = []; currentThreadId = 0;"/>
                      <b-dropdown id="main-aihistory" @click="getAIHistoryThreads()" class="blog-dropdown ai-history flex-column mr-1" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                            no-caret>
                            <template v-slot:button-content>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <g clip-path="url(#clip0_14355_29887)">
                                  <path d="M12.2812 10.3438V13.8979L14.0583 15.675" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M4 12.442C4.20619 10.4178 5.15042 8.5403 6.65251 7.16778C8.15461 5.79526 10.1094 5.02378 12.144 5.00054C14.1786 4.9773 16.1506 5.70393 17.6836 7.04178C19.2167 8.37963 20.2035 10.2351 20.4559 12.2541C20.7083 14.2731 20.2085 16.3144 19.0519 17.9884C17.8954 19.6625 16.163 20.8521 14.1853 21.3304C12.2076 21.8087 10.123 21.5422 8.32925 20.5816C6.53552 19.6211 5.1582 18.0338 4.46007 16.1226M4 20.7233V16.1226H8.60069" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_14355_29887">
                                    <rect y="0.828125" width="24" height="24" rx="4" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            </template>
                            <div class="aihistory-list" v-if="AIThreads && AIThreads.today.length">
                              <h6 class="m-0 px-1 pt-50 pb-25">Today</h6>
                              <ul class="list-unstyled m-0 p-0 d-block">
                                <li class="px-1 py-75 border-0  " v-for="thread in AIThreads.today">
                                  <div v-if="thread.editMode == false" class="d-flex align-items-center justify-content-between">
                                    <div @click="getAIThreadMessages(thread)" >{{thread.thread_name}}</div>
                                    <b-dropdown id="history-rename" class="blog-dropdown" toggle-class="text-decoration-none p-0">
                                        <template v-slot:button-content>
                                          <feather-icon icon="MoreVerticalIcon" size="15" class="text-body align-middle text-muted"/>
                                        </template>
                                        <b-dropdown-item class="btn-sm p-0" @click="thread.editMode = true; newThreadName = thread.thread_name" >
                                          <feather-icon icon="Edit2Icon" size="16" class="ml-50" stroke-width="1.5" />
                                          <span class="ml-25">Rename</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteThread(thread)">
                                          <feather-icon icon="TrashIcon" size="16" class="ml-50 text-danger" stroke-width="1.5" />
                                          <span class="text-danger ml-25">Delete</span>
                                        </b-dropdown-item>
                                      </b-dropdown>
                                  </div>
                                  <div v-else>
                                    <div class="p-0 after-rename">
                                      <b-form-input v-model="newThreadName" 
                                      @keydown.enter="renameThread(thread,newThreadName)" 
                                      @keydown.esc="thread.editMode = false">
                                      </b-form-input>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="aihistory-list" v-if="AIThreads && AIThreads.yesterday.length">
                              <h6 class="m-0 px-1 pt-50 pb-25">Past 30 Days</h6>
                              <ul class="list-unstyled m-0 p-0 d-block">
                                <li class="px-1 py-75 border-0  " v-for="thread in AIThreads.yesterday">
                                  <div v-if="thread.editMode == false" class="d-flex align-items-center justify-content-between">
                                    <div @click="getAIThreadMessages(thread)" >{{thread.thread_name}}</div>
                                    <b-dropdown id="history-rename" class="blog-dropdown" toggle-class="text-decoration-none p-0">
                                        <template v-slot:button-content>
                                          <feather-icon icon="MoreVerticalIcon" size="15" class="text-body align-middle text-muted"/>
                                        </template>
                                        <b-dropdown-item class="btn-sm p-0" @click="thread.editMode = true; newThreadName = thread.thread_name" >
                                          <feather-icon icon="Edit2Icon" size="16" class="ml-50" stroke-width="1.5" />
                                          <span class="ml-25">Rename</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteThread(thread)">
                                          <feather-icon icon="TrashIcon" size="16" class="ml-50 text-danger" stroke-width="1.5" />
                                          <span class="text-danger ml-25">Delete</span>
                                        </b-dropdown-item>
                                      </b-dropdown>
                                  </div>
                                  <div v-else>
                                    <div class="p-0 after-rename">
                                      <b-form-input v-model="newThreadName" 
                                      @keydown.enter="renameThread(thread,newThreadName)"
                                      @keydown.esc="thread.editMode = false">
                                      </b-form-input>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="aihistory-list" v-if="AIThreads && AIThreads.past_30_days.length">
                              <h6 class="m-0 px-1 pt-50 pb-25">Past 30 Days</h6>
                              <ul class="list-unstyled m-0 p-0 d-block">
                                <li class="px-1 py-75 border-0  " v-for="thread in AIThreads.past_30_days">
                                  <div v-if="thread.editMode == false" class="d-flex align-items-center justify-content-between">
                                    <div @click="getAIThreadMessages(thread)">{{thread.thread_name}}</div>
                                    <b-dropdown id="history-rename" class="blog-dropdown" toggle-class="text-decoration-none p-0">
                                        <template v-slot:button-content>
                                          <feather-icon icon="MoreVerticalIcon" size="15" class="text-body align-middle text-muted"/>
                                        </template>
                                        <b-dropdown-item class="btn-sm p-0" @click="thread.editMode = true; newThreadName = thread.thread_name " >
                                          <feather-icon icon="Edit2Icon" size="16" class="ml-50" stroke-width="1.5" />
                                          <span class="ml-25">Rename</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteThread(thread)">
                                          <feather-icon icon="TrashIcon" size="16" class="ml-50 text-danger" stroke-width="1.5" />
                                          <span class="text-danger ml-25">Delete</span>
                                        </b-dropdown-item>
                                      </b-dropdown>
                                  </div>
                                  <div v-else>
                                    <div class="p-0 after-rename">
                                      <b-form-input v-model="newThreadName" 
                                      @keydown.enter="renameThread(thread,newThreadName)"
                                      @keydown.esc="thread.editMode = false">
                                      </b-form-input>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                      </b-dropdown>
                    </div>
                    <!-- End Write with AI History -->
                    <div v-if="nonPublishedPosts.length" class="d-flex align-items-center">
                      <b-dropdown class="blog-dropdown drafted-list mr-1" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                            no-caret>
                            <template v-slot:button-content>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-default
                              title="Drafts">
                                  <g clip-path="url(#clip0_12859_40295)">
                                    <path d="M8 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V17H8.5" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17 8.5V5C17 4.60444 17.1173 4.21776 17.3371 3.88886C17.5568 3.55996 17.8692 3.30362 18.2346 3.15224C18.6001 3.00087 19.0022 2.96126 19.3902 3.03843C19.7781 3.1156 20.1345 3.30608 20.4142 3.58579C20.6939 3.86549 20.8844 4.22186 20.9616 4.60982C21.0387 4.99778 20.9991 5.39992 20.8478 5.76537C20.6964 6.13082 20.44 6.44318 20.1111 6.66294C19.7822 6.8827 19.3956 7 19 7H17" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19 3H8C7.20435 3 6.44129 3.31607 5.87868 3.87868C5.31607 4.44129 5 5.20435 5 6V17" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9 7H13" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9 11H13" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.42 12.6112C18.8138 12.2174 19.348 11.9961 19.905 11.9961C20.462 11.9961 20.9962 12.2174 21.39 12.6112C21.7838 13.005 22.0051 13.5392 22.0051 14.0962C22.0051 14.6532 21.7838 15.1874 21.39 15.5812L15 22.0012H12V19.0012L18.42 12.6112Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_12859_40295">
                                      <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                            </template>
                            <li v-for="draft in nonPublishedPosts" v-if="draft.is_draft == 'true'" @click="resumeEditing(draft)">
                              <h6 class="m-0">{{draft.title}}</h6>
                              <small>Edited On <span>
                                {{ draft.created_at | formatMonthDay }}</span>, <span>
                                  {{ draft.created_at | formatJustTime }}</span></small>
                            </li>
                            <!-- Overall Draft List View Link -->
                            <li class="all-view"><b-link class="view-link p-0" :to="{name:'draft-posts'} " @click="ClosePostForm">Manage Drafts</b-link></li>
                            <!-- End Overall Draft List View Link -->
                      </b-dropdown>
                      <b-dropdown class="blog-dropdown scheduled-list" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                            no-caret>
                            <template v-slot:button-content>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  v-b-tooltip.hover.v-default
                              title="Scheduled">
                                <g clip-path="url(#clip0_12859_40303)">
                                  <path d="M11.795 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V11" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M14 18C14 19.0609 14.4214 20.0783 15.1716 20.8284C15.9217 21.5786 16.9391 22 18 22C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18C22 16.9391 21.5786 15.9217 20.8284 15.1716C20.0783 14.4214 19.0609 14 18 14C16.9391 14 15.9217 14.4214 15.1716 15.1716C14.4214 15.9217 14 16.9391 14 18Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M15 3V7" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M7 3V7" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M3 11H19" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M18 16.4961V18.0001L19 19.0001" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_12859_40303">
                                    <rect width="24" height="24" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            </template>
                            <li v-for="scheduled in nonPublishedPosts" v-if="scheduled.is_scheduled == true  && scheduled.is_published == 'false'"  @click="resumeEditing(scheduled)">
                              <h6 class="m-0">{{scheduled.title}}</h6>
                              <small>Edited On <span>
                                {{ scheduled.created_at | formatMonthDay }}</span>, <span>
                                  {{ scheduled.created_at | formatJustTime }}</span></small>
                            </li>
                            <!-- Overall Draft List View Link -->
                            <li class="all-view"><b-link hred="" class="view-link p-0" :to="{name:'scheduled-posts'} " @click="ClosePostForm">Manage Scheduled Posts</b-link></li>
                            <!-- End Overall Draft List View Link -->
                      </b-dropdown>
                    </div> 
                    <div class="next-expand d-flex align-items-center">
                      <b-link class="fullscreen-icon"
                        @click="fullScreen = !fullScreen">
                        <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 22px; height: 22px;" v-b-tooltip.hover.v-default
                                title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')" width="26px" height="26px" />
                                <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 22px; height: 22px;" v-b-tooltip.hover.v-default
                                title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  width="26px" height="26px"/>
                      </b-link>
                    </div>
                  </div>
                  <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="confirmCloseButton" />
              </div>
              <!-- Text Post -->
              <b-form v-if="!page2 && !writeai" autocomplete="off" id="post-form" class="post-body">
                <div v-if="isReWriting" class="max-body">
                  <b-skeleton-wrapper :loading="true" class="skeleton-loader">
                    <template #loading>
                      <div class="title mb-2">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="50%"></b-skeleton>
                      </div>
                      <div class="discription">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </div>
                    </template>
                  </b-skeleton-wrapper>
                </div>
                <div v-else>
                  <b-row v-if="!page2 && post_form_data.other_details.post_type == 'text-post' " class="schedule-post mx-0 max-body">
                    <!-- New Design -->
                     <b-col class="newpost-inner h-100 w-100">
                      <div class="post-data mb-1">
                        <!-- Rewrite with AI -->
                          <h1 class="previous-header" v-if="old_title" >{{old_title}}</h1>
                          <h1 class="rewrite-header" v-if="new_title">{{new_title}}</h1>
                        <!-- Write with ai -->
                        <div class="bottom-post rewrite-ai-footer" v-if="(old_title && new_title)">
                          <ul class="m-0 p-0 list-unstyled">
                            <li class="d-flex align-items-center" @click.prevent="AcceptNewTitle(new_title)"><feather-icon size="16" class="" icon="CheckIcon"/><span>Accept</span></li>
                            <li class="d-flex align-items-center" @click.prevent="RejectTitle()" ><feather-icon size="16" class="" icon="XIcon"/><span>Discard</span></li>
                            <li class="d-flex align-items-center" @click="AskChatGPT(new_title,'rewrite_title')" ><feather-icon size="16" class="" icon="RefreshCcwIcon"/><span>Regenerate</span></li>
  
                          </ul>
                      </div>  
                <!-- End Write with ai -->
                        <!-- End Rewrite with AI -->
                        <b-form-input rows="2" v-if="!old_title && !new_title" id="title" @click="lastBoxClicked = 'Post Title'" v-model="post_form_data.title" name="title" class="w-100 h-100 shadow-none pl-0" placeholder="Title" />
                        <VEmojiPicker :pack="emojisNative" labelSearch="Search" @select="onSelectEmoji" class="emoji-picker"
                          :hidden="dialogHidden" />
                        <div @click="lastBoxClicked = 'Post Body'" v-if="!rewrite"  >
                          <QuillInput id="PostEditor" ref="PostEditor" :toolbar="toolbarState" className="post-editor custom-textpost pt-50"
                            :value.sync="post_form_data.body" placeholder="Share your thought..."
                            :mentionList="employeeListOptions" />
                        </div>
                        <!-- Rewrite with AI -->
                        <div class="withai-content my-75" v-if="rewrite && rewrite.body_comparitions.length">
                          <div  v-for="compare in rewrite.body_comparitions">
                            <p class="previous-description mt-50" v-html="compare.old"></p>
                            <p class="rewrite-description mt-50" v-if="compare.isInline == false " v-html="compare.new"></p>
                          </div>

                          
                        </div>
                        <!-- End Rewrite with AI -->
                     </div>
                     </b-col>
                  </b-row>
                  <!-- End Text Post -->
                  <!-- Video Post -->
                  <b-row v-if="!page2 && post_form_data.other_details.post_type == 'video-post'" class="schedule-post">
                    <b-col md="12" v-if="post_form_data.attachments && post_form_data.attachments.length">
                      <b-embed type="iframe" aspect="16by9" :src="post_form_data.attachments[0] && post_form_data.attachments[0].link" allowfullscreen />
                    </b-col>
                    <b-col md="12">
                      <div class="upload-files files-custom">
                        <div class="upload-file">
                          <file-upload ref="upload" accept="video/*"  :drop="true" v-model="myFiles" @input="getSrc('video')" post-action="/post.method" put-action="/put.method"
                            :multiple="false">
                            <template>
                              <div class="flex align-items-center flex-column justify-content-center">
                                <b-button class="upload-btn mb-0" variant="outline-primary" pill>Upload Video</b-button>
                                <p class="mb-0 pt-1" variant="outline-primary" pill><i class="fa-solid fa-upload"></i> Drag & Drop files to upload</p>
                                <!-- <h5>Or drop a file,</h5> -->
                              </div>
                            </template>
                          </file-upload>
                        </div>
                        <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                          {{fileError}}
                        </b-badge>
                      </div>
                    <div class="attched-file mt-1" v-if="myFiles && myFiles[0]">
                      <ul class="p-0">
                        <li>
                          <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <g>
                                <path
                                  d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                  stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath>
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>{{ myFiles[0].file.name }}</span>
                            <span class="close d-flex align-items-center justify-content-center">
                              <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                @click="deleteFile(myFiles[0].file, 0)" />
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    </b-col>
                    <b-col md="12">
                      <b-form-group class="mt-1">
                        <div class="d-flex align-items-center justify-content-between">
                          <label for="Description"> Description </label>
                          <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-default
                          title="Schedule" v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop>
                            <g clip-path="url(#clip0_9674_28132)">
                              <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_9674_28132">
                                <rect width="24" height="24" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                          <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                          post_form_data.user.hashid == userData.hashid) ||
                          $can('Zircly Admin'))
                          "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                        </div>
                        <div class="post-content">
                          <QuillInput :toolbar="toolbarState" className="post-editor"
                            :value.sync="post_form_data.body" placeholder="Start Typing To Add Description..."
                            :mentionList="employeeListOptions" />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- End Video Post -->
                  <!-- Photo Post -->
                  <b-row v-if="!page2 && post_form_data.other_details.post_type == 'photo-post'" class="schedule-post">
                    <b-col md="12" v-if="post_form_data.attachments && post_form_data.attachments.length">
                      <img
                        :src="post_form_data.attachments[0].link"
                        class="img-fluid"
                        alt="Attachment"
                      />
                    </b-col>
                    <b-col md="12">
                      <div class="upload-files files-custom">
                        <div class="upload-file">
                          <file-upload ref="upload" accept="image/*" :drop="true" v-model="myFiles" @input="getSrc('image')" post-action="/post.method" put-action="/put.method">
                            <template>
                              <div class="flex align-items-center flex-column justify-content-center">
                                <b-button class="upload-btn mb-0" variant="outline-primary" pill>Upload Photo</b-button>
                                <p class="mb-0 pt-1" variant="outline-primary" pill><i class="fa-solid fa-upload"></i> Drag & Drop files to upload</p>
                                <!-- <h5>Or drop a file,</h5> -->
                              </div>
                            </template>
                          </file-upload>
                        </div>
                        <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                          {{fileError}}
                        </b-badge>
                      </div>
  
                    <div class="attched-file mt-1" v-if="myFiles && myFiles[0]">
                      <ul class="p-0">
                        <li>
                          <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <g>
                                <path
                                  d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                  stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath>
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>{{ myFiles[0].file.name }}</span>
                            <span class="close d-flex align-items-center justify-content-center">
                              <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                @click="deleteFile(myFiles[0].file, 0)" />
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    </b-col>
                    <b-col md="12">
                      <b-form-group class="mt-1">
                        <div class="d-flex align-items-center justify-content-between">
                          <label for="Description"> Description </label>
                          <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-default
                          title="Schedule" v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop>
                            <g clip-path="url(#clip0_9674_28132)">
                              <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_9674_28132">
                                <rect width="24" height="24" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                          <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                          post_form_data.user.hashid == userData.hashid) ||
                          $can('Zircly Admin'))
                          "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                        </div>
                        <div class="post-content">
                          <QuillInput :toolbar="toolbarState" className="post-editor"
                            :value.sync="post_form_data.body" placeholder="Start Typing To Add Description..."
                            :mentionList="employeeListOptions" />
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- End Photp Post -->
                  <!-- Quote Post -->
                  <b-row v-if="!page2 && post_form_data.other_details.post_type == 'quote-post'" class="schedule-post">
                    <b-col md="12">
                      <b-form-group class="post-content">
                        <div class="d-flex align-items-center justify-content-between">
                          <label for="description">Description</label>
                          <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none"v-b-tooltip.hover.v-default
                          title="Schedule" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.is_scheduled != true  " v-b-modal.schedule-timepop>
                            <g clip-path="url(#clip0_9674_28132)">
                              <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_9674_28132">
                                <rect width="24" height="24" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                          <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                          post_form_data.user.hashid == userData.hashid) ||
                          $can('Zircly Admin'))
                          "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                        </div>
                        <b-form-textarea v-model="post_form_data.body" placeholder="Start Typing To Add Quote..." rows="15">
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <label for="description">About Author</label>
                        <b-form-input v-model="post_form_data.other_details.author" id="title" name="title"
                          placeholder="Start Typing To Add Author..." />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <!-- New Design -->
                      <div class="uploaded-attach">
                        <ul class="p-0">
                          <!-- @click="openFile(file)"  -->
                          <li v-for="file, key in myFiles" :value="key" class="d-flex align-items-center justify-content-between uploaded-file position-relative mt-1">
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                  fill="none" class="file-icon">
                                  <g>
                                    <path
                                      d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                      stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath>
                                      <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span class="file-name pl-25">{{ file.file.name.slice(0, 10) + file.file.name.slice(file.file.name.lastIndexOf('.')) }}</span>
                              </div>
                              <span class="file-size mx-1"> {{bytesToMB(file.size)}} MB</span>
                              <!-- <svg  class="download" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M2.66406 11.3346V12.668C2.66406 13.0216 2.80454 13.3607 3.05459 13.6108C3.30464 13.8608 3.64377 14.0013 3.9974 14.0013H11.9974C12.351 14.0013 12.6902 13.8608 12.9402 13.6108C13.1903 13.3607 13.3307 13.0216 13.3307 12.668V11.3346M4.66406 7.33464L7.9974 10.668M7.9974 10.668L11.3307 7.33464M7.9974 10.668V2.66797" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg> -->
                              <span class="close d-flex align-items-center justify-content-center">
                                <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                  @click.stop="deleteFile(file, key)" />
                              </span>
                          </li>
                        </ul>
                      </div>
                    
                      <div  v-if="post_form_data.attachments && post_form_data.attachments.length > 0" class="border-top uploaded-attach">
                        <!-- <b class="text-secondary">Uploaded Attachments</b> -->
                          <ul class="p-0">
                          <li v-for="(file, index) in post_form_data.attachments"
                            :key="index" class="d-flex align-items-center justify-content-between uploaded-file position-relative mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                  fill="none" class="file-icon">
                                  <g>
                                    <path
                                      d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                      stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath>
                                      <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                  </defs>
                              </svg>
                            <!-- <img
                              v-if="file.type.includes('image')"
                              :src="file.link"
                              class="img-fluid"
                              width="100px"
                              height="100px"
                              alt="Attachment"
                            /> -->
                            <span
                              class="file-name pl-25"
                            >
                              {{ file.name.slice(0, 15) + file.name.slice(file.name.lastIndexOf('.')) }}
                            </span>
                            <span class="file-size ml-1"> {{bytesToMB(file.size)}} MB</span>
                            <span class="close d-flex align-items-center justify-content-center">
                                <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                @click.stop.prevent="confirmDeleteFile(file.uuid)" />
                              </span>
                              </li>
                              </ul>
                              <!-- <svg v-b-tooltip.hover.top="'View Attachment'" @click.stop.prevent="openWindow(file.link)" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.9974 4.0013H3.9974C3.64377 4.0013 3.30464 4.14178 3.05459 4.39183C2.80454 4.64187 2.66406 4.98101 2.66406 5.33464V12.0013C2.66406 12.3549 2.80454 12.6941 3.05459 12.9441C3.30464 13.1942 3.64377 13.3346 3.9974 13.3346H10.6641C11.0177 13.3346 11.3568 13.1942 11.6069 12.9441C11.8569 12.6941 11.9974 12.3549 11.9974 12.0013V8.0013M7.33073 8.66797L13.3307 2.66797M13.3307 2.66797H9.9974M13.3307 2.66797V6.0013" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg> -->
                              <!-- <feather-icon
                                v-b-tooltip.hover.top="'Remove Attachment'"
                                size="20"
                                class="btn-link cursor-pointer ml-1 text-danger"
                                icon="TrashIcon"
                                @click.stop.prevent="confirmDeleteFile(file.uuid)"
                              /> -->
                      </div>
              </b-form>
              <div class="bottom-post d-flex align-items-center justify-content-between" v-if="!page2 && !writeai && !rewrite" >
                    <div class="d-flex align-items-center">
                      <ul class="list-unstyled d-flex align-items-center m-0 create-icon">
                        <li>
                          <feather-icon icon="SmileIcon" size="22" class="" stroke-width="1.5" @click="EmojiPicker" v-b-tooltip.hover.v-default title="Emoji"/>
                        </li>
                        <!-- v-b-modal.upload-img -->
                        <li >
                          <feather-icon icon="ImageIcon" size="22" class="" stroke-width="1.5" @click="ImageUpload" v-b-tooltip.hover.v-default title="Upload Image"/>
                        </li>
                        <!--  v-b-modal.upload-video -->
                        <!-- <li >
                          <feather-icon icon="FilmIcon" size="20" class=""/>
                        </li> -->
                        <li>
                          <!-- <feather-icon icon="PaperclipIcon" size="20" class="" v-b-modal.attach-doc/> -->
                          <file-upload ref="upload" v-model="myFiles" post-action="/post.method" put-action="/put.method"
                            :multiple="true" class="attach-upload" v-b-tooltip.hover.v-default title="Attach File">
                            <template>
                              <feather-icon icon="PaperclipIcon" size="20" class="" stroke-width="1.5"/>
                            </template>
                          </file-upload>
                        </li>
                        <!-- <li v-b-toggle.add-event-sidebar @click="ClosePostForm()" >
                          <feather-icon icon="CalendarIcon" size="20" stroke-width="1.5" class="" v-b-tooltip.hover.v-default title="New Event"/>
                        </li>
                        <li v-b-modal.add-poll-sidebar @click="ClosePostForm()" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="1.5" v-b-tooltip.hover.v-default title="New Poll">
                            <path d="M17.7148 3.73438C17.3279 3.73438 16.9568 3.88809 16.6832 4.1617C16.4096 4.43531 16.2559 4.80641 16.2559 5.19336V19.7832C16.2559 20.1701 16.4096 20.5412 16.6832 20.8149C16.9568 21.0885 17.3279 21.2422 17.7148 21.2422H19.1738C19.5608 21.2422 19.9319 21.0885 20.2055 20.8149C20.4791 20.5412 20.6328 20.1701 20.6328 19.7832V5.19336C20.6328 4.80641 20.4791 4.43531 20.2055 4.1617C19.9319 3.88809 19.5608 3.73438 19.1738 3.73438H17.7148ZM9.69043 11.5156C9.69043 11.1287 9.84414 10.7576 10.1178 10.484C10.3914 10.2104 10.7625 10.0566 11.1494 10.0566H12.6084C12.9953 10.0566 13.3664 10.2104 13.6401 10.484C13.9137 10.7576 14.0674 11.1287 14.0674 11.5156V19.7832C14.0674 20.1701 13.9137 20.5412 13.6401 20.8149C13.3664 21.0885 12.9953 21.2422 12.6084 21.2422H11.1494C10.7625 21.2422 10.3914 21.0885 10.1178 20.8149C9.84414 20.5412 9.69043 20.1701 9.69043 19.7832V11.5156ZM3.125 7.13867C3.125 6.75173 3.27871 6.38063 3.55233 6.10701C3.82594 5.8334 4.19704 5.67969 4.58398 5.67969H6.04297C6.42992 5.67969 6.80101 5.8334 7.07463 6.10701C7.34824 6.38063 7.50195 6.75173 7.50195 7.13867V19.7832C7.50195 20.1701 7.34824 20.5412 7.07463 20.8149C6.80101 21.0885 6.42992 21.2422 6.04297 21.2422H4.58398C4.19704 21.2422 3.82594 21.0885 3.55233 20.8149C3.27871 20.5412 3.125 20.1701 3.125 19.7832V7.13867Z" stroke="#667085" stroke-width="1.5"/>
                          </svg>
                        </li> -->
                      </ul>
                      <b-button v-if="post_form_data.body || post_form_data.title" class="rewrite-aibtn ml-50 shadow-none" @click="rewriteWithAI()">
                        <img :src="require('@/assets/images/icons/ai-icon.svg')" width="16px" height="16px" />
                        <span>Rewrite with AI</span>
                      </b-button>
                  </div>
                    <!-- Upload image popup-->
                    <b-modal id="upload-img" hide-header hide-footer>
                      <div class="pop-header text-left">
                        <h5 class="mb-1 pb-50 pt-75">Upload Image</h5>
                      </div>
                      <div class="upload-files files-custom">
                        <div class="upload-file">
                          <file-upload ref="upload" accept="image/png, image/gif, image/jpeg"  :drop="true" v-model="myFiles" @input="getSrc('image')" post-action="/post.method" put-action="/put.method"
                            :multiple="false">
                            <template>
                              <div class="flex align-items-center flex-column justify-content-center upload-note">
                                <feather-icon class="cursor-pointer" icon="UploadCloudIcon" size="20"/>
                                <p class="mb-0"><span class="text-primary">Click to upload</span> <span>or drag and drop</span></p>
                                <small class="mb-0 pt-25">SVG, PNG, JPG or GIF (max. XXmb each)</small>
                                <!-- <h5>Or drop a file,</h5> -->
                              </div>
                            </template>
                          </file-upload>
                        </div>
  
                        <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                          {{fileError}}
                        </b-badge>
                      </div>
  
                    <div class="attched-file mt-1" v-if="myFiles && myFiles.length">
                      <ul class="p-0 list-unstyled">
                        <li class="d-flex justify-content-between file-list" v-for="uploadedFile in myFiles">
                          <div class="d-flex align-items-center main-div">
                            <div class="file-img"  >
                              <img :src="require('@/assets/images/avatars/1.png')" width="40px" height="40px" />
                            </div>
                            <div class="file-detail">
                              <h6 class="mb-0">{{ uploadedFile.name }}</h6>
                              <small class="file-size">{{bytesToMB(uploadedFile.size)}}mb</small>
                            </div>
                          </div>
                            <span class="file-trash">
                              <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                @click="deleteFile(uploadedFile.file, 0)" />
                            </span>
                        </li>
                        <li class="d-flex justify-content-between file-list not-limited">
                          <div class="d-flex align-items-center main-div">
                            <div class="file-img">
                                <img :src="require('@/assets/images/avatars/2.png')" width="40px" height="40px" />
                            </div>
                            <div class="file-detail">
                              <h6 class="mb-0">Tech design requirements.png</h6>
                              <small class="file-size">56MB (File size should not exceed XX MB)</small>
                            </div>
                          </div>
                            <span class="file-trash">
                              <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                @click="deleteFile(myFiles[0].file, 0)" />
                            </span>
                        </li>
                        <li class="d-flex justify-content-between file-list">
                          <div class="d-flex align-items-center main-div">
                            <div class="file-img">
                              <img :src="require('@/assets/images/avatars/3.png')" width="40px" height="40px" />
                            </div>
                            <div class="file-detail">
                              <h6 class="mb-0">Tech-design-requirement-24124124214124.png</h6>
                              <small class="file-size">50mb</small>
                            </div>
                          </div>
                            <span class="file-trash">
                              <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                @click="deleteFile(myFiles[0].file, 0)" />
                            </span>
                        </li>
                        <li class="d-flex justify-content-between file-list">
                          <div class="d-flex align-items-center main-div">
                            <div class="file-img">
                              <img :src="require('@/assets/images/avatars/5.png')" width="40px" height="40px" />
                            </div>
                            <div class="file-detail">
                              <h6 class="mb-0">Tech-design-requirements.png</h6>
                              <small class="file-size">20mb</small>
                            </div>
                          </div>
                            <span class="file-trash">
                              <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                @click="deleteFile(myFiles[0].file, 0)" />
                            </span>
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex align-items-center w-100 attach-footer pt-1" v-if="myFiles && myFiles[0]">
                        <b-button
                          variant="outline-secondary"
                          class="cancel-btn w-100" pill
                        >
                          Cancel
                        </b-button>
                        <b-button
                          variant="primary"
                          class="okay-btn w-100"
                          pill
                        >
                        Attach files
                        </b-button>
                      </div>
                    </b-modal>
                    <!-- End Upload image popup -->
                    <!-- Upload video popup -->
                    <b-modal id="upload-video" hide-header hide-footer centered>
                      <b-tabs class="next-expand">
                      <b-tab id="upload-video">
                        <template #title>
                          <div class="pop-header text-left">
                          <span class="mb-0">Upload</span>
                        </div>
                        </template>
                        <div class="upload-files files-custom">
                          <div class="upload-file">
                            <file-upload ref="upload" accept="video/*"  :drop="true" v-model="myFiles" @input="getSrc('video')" post-action="/post.method" put-action="/put.method"
                              :multiple="false">
                              <template>
                                <div class="flex align-items-center flex-column justify-content-center upload-note">
                                  <feather-icon class="cursor-pointer" icon="UploadCloudIcon" size="20"/>
                                  <p class="mb-0"><span class="text-primary">Click to upload</span> <span>or drag and drop</span></p>
                                  <small class="mb-0 pt-25">SVG, PNG, JPG or GIF (max. XXmb each)</small>
                                  <!-- <h5>Or drop a file,</h5> -->
                                </div>
                              </template>
                            </file-upload>
                          </div>
  
                          <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                            {{fileError}}
                          </b-badge>
                        </div>
                        <div class="attched-file mt-1" v-if="myFiles && myFiles[0]">
                          <ul class="p-0 list-unstyled">
                            <li class="d-flex justify-content-between file-list">
                              <div class="d-flex align-items-center main-div">
                                <div class="file-detail">
                                  <h6 class="mb-0">{{ myFiles[0].file.name }}</h6>
                                  <small class="file-size">200mb</small>
                                </div>
                              </div>
                                <span class="file-trash">
                                  <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                    @click="deleteFile(myFiles[0].file, 0)" />
                                </span>
                            </li>
                            <li class="d-flex justify-content-between file-list not-limited">
                              <div class="d-flex align-items-center main-div">
                                <div class="file-detail">
                                  <h6 class="mb-0">Tech design requirements.mp4</h6>
                                  <small class="file-size">56MB (File size should not exceed XX MB)</small>
                                </div>
                              </div>
                                <span class="file-trash">
                                  <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                    @click="deleteFile(myFiles[0].file, 0)" />
                                </span>
                            </li>
                            <li class="d-flex justify-content-between file-list">
                              <div class="d-flex align-items-center main-div">
                                <div class="file-detail">
                                  <h6 class="mb-0">Tech-design-requirements-design-requirements2412124124124124214124.mp4</h6>
                                  <small class="file-size">200KB</small>
                                </div>
                              </div>
                                <span class="file-trash">
                                  <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                    @click="deleteFile(myFiles[0].file, 0)" />
                                </span>
                            </li>
                            <li class="d-flex justify-content-between file-list">
                              <div class="d-flex align-items-center main-div">
                                <div class="file-detail">
                                  <h6 class="mb-0">Tech-design-requirements.png</h6>
                                  <small class="file-size">20mb</small>
                                </div>
                              </div>
                                <span class="file-trash">
                                  <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                    @click="deleteFile(myFiles[0].file, 0)" />
                                </span>
                            </li>
                          </ul>
                        </div>
                        <div class="d-flex align-items-center w-100 attach-footer pt-1" v-if="myFiles && myFiles[0]">
                            <b-button
                              variant="outline-secondary"
                              class="cancel-btn w-100" pill
                            >
                              Cancel
                            </b-button>
                            <b-button
                              variant="primary"
                              class="okay-btn w-100"
                              pill
                            >
                            Attach files
                            </b-button>
                        </div>
                      </b-tab>
                      <b-tab id="embed-link">
                        <template #title>
                          <div class="pop-header text-left">
                          <span class="mb-0">Embed Link</span>
                        </div>
                        </template>
                        <div class="enter-link">
                          <div class="post-url my-1 pt-75">
                            <b-form-group>
                              <b-form-input placeholder="Paste URL to embed" v-model="videoURL" />
                            </b-form-group>
                          </div> 
                        </div>
                        <div class="d-flex align-items-center w-100 attach-footer pt-1">
                            <b-button
                              variant="outline-secondary"
                              class="cancel-btn w-100" pill
                            >
                              Cancel
                            </b-button>
                            <b-button
                              variant="primary"
                              class="okay-btn w-100"
                              pill
                              @click="embedURL"
                            >
                            Embed
                            </b-button>
                        </div>
                      </b-tab>
                    </b-tabs>
                    </b-modal>
                    <!-- End Upload video popup -->
                     <!-- Upload Attachment popup-->
                    <b-modal id="attach-doc" hide-header hide-footer centered>
                      <div class="pop-header text-left">
                        <h5 class="mb-1 pb-50 pt-75">Upload Attachment</h5>
                      </div>
                      <div class="upload-height">
                        <div class="upload-files files-custom">
                          <div class="upload-file">
                            <file-upload ref="upload" accept="image/jpeg, image/png, image/gif, .pdf, .zip, .doc, .svg, ppt"  :drop="true" v-model="myFiles"  post-action="/post.method" put-action="/put.method"
                              :multiple="false">
                              <template>
                                <div class="flex align-items-center flex-column justify-content-center upload-note">
                                  <feather-icon class="cursor-pointer" icon="UploadCloudIcon" size="20"/>
                                  <p class="mb-0"><span class="text-primary">Click to upload</span> <span>or drag and drop</span></p>
                                  <small class="mb-0 pt-25">PDF, DOC, XLX, PPT, ZIP or TXT (max. XXmb each)</small>
                                  <!-- <h5>Or drop a file,</h5> -->
                                </div>
                              </template>
                            </file-upload>
                          </div>
  
                          <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                            {{fileError}}
                          </b-badge>
                        </div>
                        <div class="attched-file mt-1" v-if="myFiles && myFiles.length">
                          <ul class="p-0 list-unstyled">
                            <li :class="bytesToMB(uploadedFile.size) > 10 ?'d-flex justify-content-between file-list ':'d-flex justify-content-between file-list not-limited'" v-for="uploadedFile in myFiles" >
                              <div class="d-flex align-items-center main-div">
                                <div class="file-img" v-if="uploadedFile.type.startsWith('image/')">
                                  <img :src="getFileURL(uploadedFile.file)" />
                                </div>
                                <div class="file-img" v-if="uploadedFile.type.startsWith('application/')">
                                  <img :src="require('@/assets/images/icons/file-icons/pdf-icon.svg')" />
                                </div>
                                <div class="file-img" v-if="(uploadedFile.type === 'application/zip' || uploadedFile.name.endsWith('.zip'))">
                                  <img :src="require('@/assets/images/icons/file-icons/zip-icon.svg')" />
                                </div>
                                <div class="file-detail">
                                  <h6 class="mb-0">{{ uploadedFile.name }}</h6>
                                  <small class="file-size" v-if="bytesToMB(uploadedFile.size) > 10">{{bytesToMB(uploadedFile.size)}}MB (File size should not exceed 10 MB)</small>
                                  <small class="file-size" v-else>{{bytesToMB(uploadedFile.size)}}MB</small>
                                </div>
                              </div>
                                <span class="file-trash">
                                  <feather-icon class="cursor-pointer close-btn" icon="Trash2Icon" size="20"
                                    @click="deleteFile(uploadedFile.file, 0)" />
                                </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="d-flex align-items-center w-100 attach-footer pt-1" v-if="myFiles && myFiles[0]">
                          <b-button
                            variant="outline-secondary"
                            class="cancel-btn w-100" pill
                          >
                            Cancel
                          </b-button>
                          <b-button
                            variant="primary"
                            class="okay-btn w-100"
                            pill
                          >
                          Attach files
                          </b-button>
                      </div>
                    </b-modal>
                    <!-- End Attacement popup -->
                    <div class="right-post  d-flex align-items-center gap-16">
                      <div class="select-channel d-flex align-items-center">
                        <span>Post to: </span>
                        <!-- <v-select v-model="typeChannel" :reduce="(val) => val.value" :options="communityChannels"
                          class="w-auto create-options border-0 p-0" placeholder="Select A Channel" :clearable="false" /> -->
                        <b-dropdown v-if="filterByType!='event' && filterByType!='poll'" :text="getLabel(typeChannel,communityChannels)" class="filter-dropdown" variant="outline-transparent">
                          <b-dropdown-item @click="typeChannel = channel.value" v-for="channel in communityChannels">{{ channel.label }}</b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div class="select-post">
                        <!-- Post now button -->
                        <div class="d-flex align-items-center">
                          <b-button variant="outline-secondary" pill v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" class="mr-1 text-default">Post Now</b-button>
                          <div class="demo-inline-spacing justify-content-start">
                            <b-button-group class="mr-0">
                              <b-dropdown
                                v-if="!post_form_data.hashid || post_form_data.is_draft == 'true'" 
                                variant="outline-secondary"
                                :text="post_form_data.is_scheduled ? 'Schedule Post' : 'Publish'"
                                @click="formSubmitted()"
                                split
                                :disabled="postSubmittedClicked"
                                class="publish-btn"
                              >
                                <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Publish Now</b-dropdown-item>
                                <b-dropdown-item v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop
                                  >Schedule</b-dropdown-item
                                >
                                <b-dropdown-item @click="formSubmitted(true)" 
                                :disabled="postSubmittedClicked"
                                  >Save as Draft</b-dropdown-item
                                >
                              </b-dropdown>
                              <b-dropdown
                                v-if="post_form_data.hashid && post_form_data.is_draft == 'false'"
                                variant="outline-primary"
                                :text="post_form_data.is_scheduled && post_form_data.is_published == 'false' ? 'Update Schedule Post' : 'Update'"
                                @click="formUpdate()"
                                split
                                :disabled="postSubmittedClicked"
                              >
                                
                                <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Post Now</b-dropdown-item>
                                <b-dropdown-item v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop
                                  >Schedule</b-dropdown-item
                                >
                                <b-dropdown-item @click="formUpdate(true)" 
                                :disabled="postSubmittedClicked"
                                  >Save as Draft</b-dropdown-item
                                >
                              </b-dropdown>
                            </b-button-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Write with ai -->
                  <div class="bottom-post rewrite-ai-footer" v-if=" !page2 && !writeai && rewrite && rewrite.body_comparitions.length">
                    <ul class="m-0 p-0 list-unstyled">
                      <li class="d-flex align-items-center" @click="AcceptBody(rewrite.body_comparitions[0].old,rewrite.body_comparitions[0].new)"><feather-icon size="16" class="" icon="CheckIcon"/><span>Accept</span></li>
                      <li class="d-flex align-items-center" @click="rewrite = null" ><feather-icon size="16" class="" icon="XIcon"/><span>Discard</span></li>
                      <li class="d-flex align-items-center" v-if="isHTML(rewrite.body_comparitions[0].old)" @click="AskChatGPT(post_form_data,'rewrite_again')" ><feather-icon size="16" class="cursor-pointer" icon="RefreshCcwIcon"/><span variant="default">Regenerate</span></li>
                      <li class="d-flex align-items-center" v-else @click="AskChatGPT(rewrite.body_comparitions[0].old,'rewrite_again')" ><feather-icon size="16" class="cursor-pointer" icon="RefreshCcwIcon"/><span variant="default">Regenerate</span></li>
                    </ul>
                  </div>
                  <!-- End Write with ai -->
              <!-- End Quote Post -->

              <!-- Back To Editing  -->
              <div class="back-editform community-preview" v-if="page2">
                <b-card no-body class="my-1 border-0 max-body">
                  <b-card-body class="px-0 max-body">
                    <div class="d-flex justify-content-between align-items-center post-header">
                      <div class="d-flex align-items-center">
                        <!-- avatar -->
                        <b-avatar :src="userData.avatar" target="_blank" :href="`/profile/${userData.hashid}`" style="background: transparent" size="40" class="mr-75" />
                        <!--/ avatar -->
                        <div class="profile-user-info">
                          <h6 class="mb-0">
                            {{ userData.name }}
                          </h6>
                          <small>
                            {{ userData.designation }}
                          </small>
                        </div>
                      </div>
                      <div class="d-none align-items-center">
                        <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                          no-caret>
                          <template v-slot:button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16"
                              class="text-body align-middle text-muted" />
                          </template>
                          <b-dropdown-item class="btn-sm p-0 text-muted">
                            <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                            <span class="">Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item class="btn-sm p-0 text-muted">
                            <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                            <span class="">Copy</span>
                          </b-dropdown-item>
                          <b-dropdown-item class="btn-sm p-0">
                            <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                            <span class="">Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                    <!-- Text Post -->
        <div class="post-body mb-1" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'text-post'">
          <div  class="" style="position: relative">
            <div>
              <b-card-text >
                <h3 class="card-header pb-0 mt-lg-0 px-0">{{ post_form_data.title }}</h3>
              </b-card-text>
              <b-card-text v-html="post_form_data.body"></b-card-text>
            </div>
          </div>
        </div>
        <!-- End Text Post -->

        <!-- Photo Post -->
        <div class="post-body mb-1" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'photo-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" v-if="myFiles && myFiles[0] && previewUrl">
                <b-img fluid rounded :src="previewUrl" class="post-img" />
              </div>
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" v-if="!myFiles[0] && post_form_data.attachments && post_form_data.attachments[0]">
                <b-img fluid rounded :src="post_form_data.attachments[0].link" class="post-img" />
              </div>
              <b-card-text v-html="post_form_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Photo Post -->
        <!-- Video Post -->
        <div class="post-body mb-1" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'video-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" v-if="myFiles && myFiles[0] && previewUrl" >
                <b-embed type="iframe" aspect="16by9" :src="previewUrl" allowfullscreen/>
              </div>
              <div class="mt-1 mb-1 d-flex" v-if="!myFiles[0] && post_form_data.attachments && post_form_data.attachments[0]">
                <b-embed type="iframe" aspect="16by9" :src="post_form_data.attachments[0].link" allowfullscreen/>
              </div>
              <b-card-text v-html="post_form_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Video Post -->

        <!-- Quote Post -->
        <div class="post-body mb-1" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'quote-post'">
          <div  class="quote-post" style="position: relative ">
            <div>
              <b-card-text>
                "{{ post_form_data.body }}"
              </b-card-text>
              <p class="author-name my-1 pt-1" v-if="post_form_data.other_details.author"><strong>Author Name : {{post_form_data.other_details.author}}</strong></span></p>
            </div>
          </div>
        </div>
        <!-- End Quote Post -->
                  </b-card-body>
                </b-card>
                <div class="attched-file" v-if="post_form_data.other_details.post_type == 'text-post'">
                  <ul class="p-0">
                    <li v-for="file, key in myFiles" :value="key" @click="openFile(file)">
                      <div class="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g>
                            <path
                              d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                              stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>{{ file.file.name.slice(0, 20) + file.file.name.slice(file.file.name.lastIndexOf('.')) }}</span>
                        <span class="close d-flex align-items-center justify-content-center">
                          <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                            @click.stop="deleteFile(file.file, key)" />
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="bottom-post d-flex align-items-center justify-content-between" v-if="page2">
                  <ul class="list-unstyled d-flex align-items-center m-0 create-icon">
                    <!-- <li>
                      <feather-icon icon="SmileIcon" size="22" class="" stroke-width="1.5" @click="EmojiPicker" v-b-tooltip.hover.v-default title="Emoji"/>
                    </li>
                    <li >
                      <feather-icon icon="ImageIcon" size="22" class="" stroke-width="1.5" @click="ImageUpload" v-b-tooltip.hover.v-default title="Upload Image"/>
                    </li>
                    <li v-b-modal.upload-video >
                      <feather-icon icon="FilmIcon" size="20" class="" stroke-width="1.5" v-b-tooltip.hover.v-default title="Upload Video"/>
                    </li>
                    <li>
                      <file-upload ref="upload" v-model="myFiles" post-action="/post.method" put-action="/put.method"
                        :multiple="true" class="attach-upload">
                        <template>
                          <feather-icon icon="PaperclipIcon" size="20" class=""/>
                        </template>
                      </file-upload>
                    </li>
                    <li v-b-toggle.add-event-sidebar @click="ClosePostForm()" >
                      <feather-icon icon="CalendarIcon" size="20" class="" stroke-width="1.5" v-b-tooltip.hover.v-default title="New Event"/>
                    </li>
                    <li v-b-modal.add-poll-sidebar @click="ClosePostForm()" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="1.5" v-b-tooltip.hover.v-default title="New Poll">
                        <path d="M17.7148 3.73438C17.3279 3.73438 16.9568 3.88809 16.6832 4.1617C16.4096 4.43531 16.2559 4.80641 16.2559 5.19336V19.7832C16.2559 20.1701 16.4096 20.5412 16.6832 20.8149C16.9568 21.0885 17.3279 21.2422 17.7148 21.2422H19.1738C19.5608 21.2422 19.9319 21.0885 20.2055 20.8149C20.4791 20.5412 20.6328 20.1701 20.6328 19.7832V5.19336C20.6328 4.80641 20.4791 4.43531 20.2055 4.1617C19.9319 3.88809 19.5608 3.73438 19.1738 3.73438H17.7148ZM9.69043 11.5156C9.69043 11.1287 9.84414 10.7576 10.1178 10.484C10.3914 10.2104 10.7625 10.0566 11.1494 10.0566H12.6084C12.9953 10.0566 13.3664 10.2104 13.6401 10.484C13.9137 10.7576 14.0674 11.1287 14.0674 11.5156V19.7832C14.0674 20.1701 13.9137 20.5412 13.6401 20.8149C13.3664 21.0885 12.9953 21.2422 12.6084 21.2422H11.1494C10.7625 21.2422 10.3914 21.0885 10.1178 20.8149C9.84414 20.5412 9.69043 20.1701 9.69043 19.7832V11.5156ZM3.125 7.13867C3.125 6.75173 3.27871 6.38063 3.55233 6.10701C3.82594 5.8334 4.19704 5.67969 4.58398 5.67969H6.04297C6.42992 5.67969 6.80101 5.8334 7.07463 6.10701C7.34824 6.38063 7.50195 6.75173 7.50195 7.13867V19.7832C7.50195 20.1701 7.34824 20.5412 7.07463 20.8149C6.80101 21.0885 6.42992 21.2422 6.04297 21.2422H4.58398C4.19704 21.2422 3.82594 21.0885 3.55233 20.8149C3.27871 20.5412 3.125 20.1701 3.125 19.7832V7.13867Z" stroke="#667085" stroke-width="1.5"/>
                      </svg>
                    </li> -->
                  </ul>
                  <div class="right-post  d-flex align-items-center gap-16">
                      <div class="select-channel d-flex align-items-center">
                        <span>Post to: </span>
                        <!-- <v-select v-model="typeChannel" :reduce="(val) => val.value" :options="communityChannels"
                          class="w-auto create-options border-0 p-0" placeholder="Select A Channel" :clearable="false" /> -->
                        <b-dropdown v-if="filterByType!='event' && filterByType!='poll'" :text="getLabel(typeChannel,communityChannels)" class="filter-dropdown" variant="outline-transparent">
                          <b-dropdown-item @click="typeChannel = channel.value" v-for="channel in communityChannels">{{ channel.label }}</b-dropdown-item>
                        </b-dropdown>
                      </div>  
                    <div class="select-post d-flex align-items-center justify-content-end">
                      <div class="demo-inline-spacing justify-content-start">
                        <b-button-group class="mr-0">
                          <b-dropdown
                            v-if="!post_form_data.hashid || post_form_data.is_draft == 'true'" 
                            variant="outline-primary"
                            :text="post_form_data.is_scheduled ? 'Schedule Post' : 'Publish'"
                            @click="formSubmitted()"
                            split
                            :disabled="postSubmittedClicked"
                          >
                            <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Publish Now</b-dropdown-item>
                            <b-dropdown-item v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.is_scheduled != true  " v-b-modal.schedule-timepop
                              >Schedule</b-dropdown-item
                            >
                            <b-dropdown-item @click="formSubmitted(true)" 
                            :disabled="postSubmittedClicked"
                              >Save as Draft</b-dropdown-item
                            >
                          </b-dropdown>
                          <b-dropdown
                            v-if="post_form_data.hashid && post_form_data.is_draft == 'false'"
                            variant="outline-primary"
                            :text="post_form_data.is_scheduled && post_form_data.is_published == 'false' ? 'Update Schedule Post' : 'Update'"
                            @click="formUpdate()"
                            split
                            :disabled="postSubmittedClicked"
                          >
                            <b-dropdown-item @click="formUpdate()" :disabled="postSubmittedClicked">{{
                            post_form_data.is_scheduled == true  && post_form_data.is_published == 'false' ? 'Update Schedule Post' : 'Update' }}</b-dropdown-item>
                            <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Publish Now</b-dropdown-item>
                            <b-dropdown-item v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.is_scheduled != true  " v-b-modal.schedule-timepop
                              >Schedule</b-dropdown-item
                            >
                            <b-dropdown-item @click="formUpdate(true)" 
                            :disabled="postSubmittedClicked"
                              >Save as Draft</b-dropdown-item
                            >
                          </b-dropdown>
                        </b-button-group>
                      </div>

                    </div>
                  </div>
                </div>
              <!-- End Back To Editing -->
                 <!-- Write With AI -->
              <div class="ai-chat px-2" v-if="writeai == true">
                <div class="main-chat mt-1" >
                  <div class="chats position-relative my-75" v-if="AIChat" v-for="message,key in AIChat">
                    <div class="chat-group d-flex align-start flex-row-reverse mb-6 right-chat" v-if="message.chat_owner == 'User'">
                      <div class="d-flex align-items-center message-edit" @click="message.edit_mode = true; message.chat_content_edit = message.chat_content;" >
                        <feather-icon icon="Edit2Icon" size="18" class="text-body" stroke="#344054" />
                        <p class="mb-0" v-html="message.chat_content.replace(/\n/g, '<br>')"></p>
                      </div>
                    </div>
                    <div class="chat-group d-flex align-start mb-6 left-chat" v-if="message.chat_owner == 'AI'">
                      <div class="d-flex align-items-start">
                        <b-img :src="require('@/assets/images/icons/ai-magic.svg')" size="22" class="mr-25"/>
                        <div class="reply-text">
                          <!-- <p v-html="message.chat_content"></p>
                          <b-card-text v-html="message.chat_content"></b-card-text> -->
                          <div v-if="!message.isChatResponce || message.isChatResponce != true" class="reply-ans" v-html="getHTML(message.chat_content)"></div>
                          <div v-if="message.isChatResponce || message.isChatResponce == true" class="reply-ans" v-html="message.chat_content"></div>
                           <div class="d-flex align-items-center gap-10 my-1" v-if="!message.isChatResponce || message.isChatResponce != true">
                            <b-button variant="outline-secondary" v-b-tooltip.hover.v-default title="Use This AI Response" @click="AcceptAIChat(message.chat_content)" pill class="use-this cursor-pointer">Use This</b-button>
                            <div class="refresh-content cursor-pointer" v-b-tooltip.hover.v-default title="Regenerate" >
                              <svg xmlns="http://www.w3.org/2000/svg" @click="regenerate('Regenerate Again,Rephase it',null,true)" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <g clip-path="url(#clip0_13236_43872)">
                                  <path d="M14.0573 7.78561C13.8943 6.61242 13.35 5.52538 12.5084 4.69194C11.6668 3.8585 10.5745 3.32489 9.39974 3.17331C8.22502 3.02174 7.03304 3.2606 6.00743 3.85311C4.98181 4.44562 4.17946 5.3589 3.72396 6.45228M3.39062 3.78561V6.45228H6.05729" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M3.39062 9.12109C3.55366 10.2943 4.09791 11.3813 4.93953 12.2148C5.78115 13.0482 6.87345 13.5818 8.04818 13.7334C9.2229 13.885 10.4149 13.6461 11.4405 13.0536C12.4661 12.4611 13.2685 11.5478 13.724 10.4544M14.0573 13.1211V10.4544H11.3906" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_13236_43872">
                                    <rect width="16" height="16" fill="white" transform="translate(0.726562 0.453125)"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="chat-group d-flex align-start flex-column mb-6 left-chat" v-if="message.edit_mode == true " :id="'edit-message-'+key">
                      <!-- Add More Details -->
                       <div class="add-more my-1">
                        <div class="autoarea">
                          <b-form-textarea placeholder="Add More Details" v-model="message.chat_content_edit" class="auto-resize" @input="autoResize"   @keyup.enter="message.chat_content_edit = handleEnterChatAI($event,'edit-prompt',message.chat_content_edit)"/>
                        </div>
                        <div class="d-flex align-items-center justify-content-end mt-1 gap-10">
                          <b-button variant="outline-secondary" pill class="cancel-btn" @click="message.edit_mode = false">
                            Cancel
                          </b-button>
                          <b-button variant="primary" pill class="update-btn " @click="regenerate(message.chat_content_edit,key)">
                            Update
                          </b-button>
                        </div>
                       </div>
                    </div>                    
                  </div>

                  <b-skeleton-wrapper v-if="isAIWriting" :loading="true" class="skeleton-loader">
                    <template #loading>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                    </template>
                  </b-skeleton-wrapper>
                </div>
              </div>
              <div class="text-bottom bottom-post border-0 d-flex align-items-center justify-content-between" v-if="writeai == true">
                <div class="d-flex flex-column ai-genfooter w-100">
                    <textarea id="aitextarea" class="w-100 form-control auto-resize" @keyup.enter="handleEnterChatAI($event,'new-prompt',promptText)"  @input="autoResize" placeholder="What do you want to write about today?" v-model="promptText" >
                    </textarea>
                    <div :class="(promptText.length > 0) ? 'send-btn-active':'send-btn'" @click="regenerate(promptText)">
                      <div class="ai-send">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                          <g clip-path="url(#clip0_13236_45328)">
                            <path d="M5.37927 9.51136H16.652" stroke="white" stroke-width="1.08696" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.6532 9.51255L4.09946 15.4051C4.02792 15.4317 3.95026 15.4372 3.8757 15.4209C3.80114 15.4046 3.73281 15.3673 3.67885 15.3133C3.62489 15.2594 3.58755 15.191 3.57128 15.1165C3.55501 15.0419 3.56049 14.9642 3.58707 14.8927L5.38045 9.51255L3.58707 4.13238C3.56049 4.06085 3.55501 3.98318 3.57128 3.90862C3.58755 3.83406 3.62489 3.76574 3.67885 3.71177C3.73281 3.65781 3.80114 3.62047 3.8757 3.6042C3.95026 3.58793 4.02792 3.59341 4.09946 3.61999L16.6532 9.51255Z" stroke="white" stroke-width="1.08696" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_13236_45328">
                              <rect width="17.3913" height="17.3913" fill="white" transform="translate(0.875 0.125)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                </div>
              </div>
              <!-- End Write With AI -->
            </b-modal>
        </b-card>
        <div class="d-flex align-items-center justify-content-between post-filter my-1">
          <hr class="w-100"/>
          <div>
            <b-dropdown v-if="filterByType=='event'" :text="typeFilter" class="filter-dropdown" variant="outline-transparent">
              <b-dropdown-item  v-for="option in typeOptions" @click="typeFilter = option.value">{{ option.label }}</b-dropdown-item>
            </b-dropdown>
            <b-dropdown v-if="filterByType!='event' && filterByType!='poll'" :text="postListFilter" class="filter-dropdown" variant="outline-transparent">
              <b-dropdown-item @click="postListFilter = postfilter.value" v-for="postfilter in postListOptions">{{ postfilter.label }}</b-dropdown-item>
            </b-dropdown>
            <!-- <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="typeFilter" :options="typeOptions"
                class="w-100 p-0 border-0 custom-filter ml-50" :reduce="(val) => val.value" :clearable="false" :searchable="false" label="label" /> -->
          </div>
        </div>
        <div class="post-list">
          <!-- <div class="row justify-content-end event-select" v-show="filterByType == 'event'">
            <div class="col-lg-3">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="typeFilter" :options="typeOptions"
                class="w-100" :reduce="(val) => val.value" placeholder="Select type.." :clearable="false" />
            </div>
          </div> -->
          <div v-show="filterByType != 'event' && filterByType != 'poll'" ref="div-scroll" v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="isBusy" infinite-scroll-distance="10">
            <!-- Start Pinned Post -->
            <div class="post-card pinned-post mb-1" v-if="pinnedPosts.length && pinnedPosts[0].length > 1">
                <b-carousel id="post_pin" :interval="4000" :controls="pinnedPosts.length > 1">
                  <b-carousel-slide v-for="(chunk, ind) in pinnedPosts">
                    <template #img>
                      <ul class="d-flex align-items-center m-0 p-0 list-unstyled pinned-lists">
                        <li :key="'post-' + index" v-for="(data, index) in chunk">
                          <b-card no-body 
                            :id="'post-card-' + data.hashid" :class="data.user.hashid == userData.hashid ? 'post-card mainuser' : 'post-card'">
                            <!-- @click="viewPost(data)" -->
                            <b-card-body :id="data.hashid" @click="viewPost(data)" v-observe-visibility="{
                              callback: (isVisible, entry) =>
                                visibilityChanged(isVisible, entry, data),
                              once: true,
                              throttle: 0,
                              throttleOptions: {
                                leading: 'visible',
                              },
                              intersection: {
                                threshold: 0,
                              },
                            }">
                              <div class="d-flex justify-content-between align-items-center post-header pb-75">
                                <div class="profile-user-info">
                                    <small class="d-block">
                                      {{ data.user.name }}
                                    </small>
                                  </div>
                                  <div class="pin-more">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="default-pin">
                                      <path d="M10.0772 2.14176L10.1399 2.19709L13.8065 5.86376C13.9189 5.97674 13.9871 6.12624 13.9988 6.28517C14.0104 6.44411 13.9647 6.60196 13.87 6.73012C13.7753 6.85828 13.6378 6.94827 13.4825 6.9838C13.3271 7.01932 13.1642 6.99802 13.0232 6.92376L10.9085 9.03776L9.95921 11.5691C9.9342 11.6359 9.89864 11.6982 9.85388 11.7538L9.80721 11.8071L8.80721 12.8071C8.69233 12.9218 8.53955 12.9906 8.37754 13.0007C8.21552 13.0107 8.0554 12.9613 7.92721 12.8618L7.86388 12.8064L6.00188 10.9451L3.47321 13.4731C3.35324 13.5927 3.19225 13.6621 3.02295 13.6672C2.85365 13.6724 2.68873 13.6129 2.56169 13.5009C2.43465 13.3889 2.35501 13.2327 2.33894 13.0641C2.32288 12.8955 2.3716 12.7271 2.47521 12.5931L2.53054 12.5304L5.05854 10.0018L3.19721 8.13975C3.08243 8.02496 3.01347 7.87223 3.00328 7.71021C2.9931 7.54819 3.04238 7.38802 3.14188 7.25976L3.19721 7.19709L4.19721 6.19709C4.24751 6.14661 4.30558 6.10452 4.36921 6.07242L4.43454 6.04442L6.96521 5.09442L9.07921 2.98109C9.00712 2.84631 8.98354 2.69085 9.0124 2.54076C9.04127 2.39066 9.12084 2.25504 9.23778 2.15662C9.35471 2.0582 9.50192 2.00294 9.65474 2.00011C9.80756 1.99729 9.95671 2.04772 10.0772 2.14176Z" fill="#101828"/>
                                    </svg>
                                    <b-dropdown class="blog-dropdown hover-state" variant="link" size="sm" toggle-class="text-decoration-none"
                                      no-caret 
                                      v-if="data.is_published == 'true' && $can('Zircly Admin') && data.is_pinned == 'true'" @click="unpinPost(data)">
                                      <template v-slot:button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body text-muted"/>
                                      </template>
                                      <b-dropdown-item class="btn-sm p-0 text-muted">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16" fill="none" class="default-pin mr-25">
                                          <path d="M10.0772 2.14176L10.1399 2.19709L13.8065 5.86376C13.9189 5.97674 13.9871 6.12624 13.9988 6.28517C14.0104 6.44411 13.9647 6.60196 13.87 6.73012C13.7753 6.85828 13.6378 6.94827 13.4825 6.9838C13.3271 7.01932 13.1642 6.99802 13.0232 6.92376L10.9085 9.03776L9.95921 11.5691C9.9342 11.6359 9.89864 11.6982 9.85388 11.7538L9.80721 11.8071L8.80721 12.8071C8.69233 12.9218 8.53955 12.9906 8.37754 13.0007C8.21552 13.0107 8.0554 12.9613 7.92721 12.8618L7.86388 12.8064L6.00188 10.9451L3.47321 13.4731C3.35324 13.5927 3.19225 13.6621 3.02295 13.6672C2.85365 13.6724 2.68873 13.6129 2.56169 13.5009C2.43465 13.3889 2.35501 13.2327 2.33894 13.0641C2.32288 12.8955 2.3716 12.7271 2.47521 12.5931L2.53054 12.5304L5.05854 10.0018L3.19721 8.13975C3.08243 8.02496 3.01347 7.87223 3.00328 7.71021C2.9931 7.54819 3.04238 7.38802 3.14188 7.25976L3.19721 7.19709L4.19721 6.19709C4.24751 6.14661 4.30558 6.10452 4.36921 6.07242L4.43454 6.04442L6.96521 5.09442L9.07921 2.98109C9.00712 2.84631 8.98354 2.69085 9.0124 2.54076C9.04127 2.39066 9.12084 2.25504 9.23778 2.15662C9.35471 2.0582 9.50192 2.00294 9.65474 2.00011C9.80756 1.99729 9.95671 2.04772 10.0772 2.14176Z" fill="#101828"/>
                                        </svg>
                                        <span @click="unpinPost(data)" class="">Unpin for everyone</span>
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </div>
                              </div>
                              <!-- Text Post -->
                              <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'text-post'">
                                <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                                  <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                                    " style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;">
                                    <b-card-text>
                                      <h6 class="card-header">{{ data.title }}</h6>
                                    </b-card-text>
                                  </div>
                                </div>
                              </div>
                              <!-- End Text Post -->
                              <!-- Photo Post -->
                              <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'photo-post'">
                                <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                                  <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                                    " style="max-height: 10em; overflow: hidden; text-overflow: ellipsis;">
                                    <b-card-text v-html="data.snippetbody"></b-card-text>
                                  </div>
                                </div>
                              </div>
                              <!-- End Photo Post -->
                              <!-- Video Post -->
                              <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'video-post'">
                                <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                                  <div :class="data.snippetbody.length > 200 ? 'post-card-summary' : '' " style="max-height: 10em; overflow: hidden; text-overflow: ellipsis;">

                                    <!-- post img -->
                                    <div class="mt-1 mb-1 d-flex" v-if="data.attachments && data.attachments[0]" >
                                      <b-embed type="iframe" aspect="16by9" :src="data.attachments[0] && data.attachments[0].link" allowfullscreen  />
                                    </div>
                                    <b-card-text v-html="data.snippetbody"></b-card-text>
                                  </div>
                                </div>
                                <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click.stop="viewPost(data)"
                                  class="font-weight-bolder text-right see-more pb-1">
                                  <u>...see more</u>
                                </p>
                              </div>
                              <!-- End Video Post -->
                              <!-- Quote Post -->
                              <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'quote-post'">
                                <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="quote-post click-view" style="position: relative">
                                  <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''" style="max-height: 10em; overflow: hidden; text-overflow: ellipsis;">
                                    <b-card-text>
                                      "{{ data.snippetbody }}"
                                    </b-card-text>
                                  </div>
                                </div>
                              </div>
                              <!-- End Quote Post -->
                            </b-card-body>
                          </b-card>
                        </li>
                      </ul>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
            </div>
            <!-- End Pinned Post -->
             <!-- Community Post -->
            <b-card :class="data.user.hashid == userData.hashid ? 'post-card mainuser' : 'post-card'" no-body v-for="(data, index) in posts" v-if="(pinnedPosts.length && pinnedPosts[0].length < 2) ||
              data.is_pinned == 'false'
              " :key="'post-' + index" :id="'post-card-' + data.hashid" >
              <!-- @click="viewPost(data)" -->
              <b-card-body :id="data.hashid" @click="viewPost(data)" class="pb-25" v-observe-visibility="{
                callback: (isVisible, entry) =>
                  visibilityChanged(isVisible, entry, data),
                once: true,
                throttle: 0,
                throttleOptions: {
                  leading: 'visible',
                },
                intersection: {
                  threshold: 0,
                },
              }">
              
              <div class="user-reaction d-flex align-items-center" v-if="data.recentReact && data.recentReact.reactType == 'like'">
                  <b-avatar :src="data.recentReact.record.user.avatar" style="background: transparent" size="24" />
                  <h6 v-if="data.recentReact.record.type == 'idea'" class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Liked this idea</h6>
                  <h6 v-else-if="data.recentReact.record.type == 'clap'" class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Appreciated this</h6>
                  <h6 v-else-if="data.recentReact.record.type == 'love'" class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Loved this</h6>
                  <h6 v-else-if="data.recentReact.record.type == 'laugh'" class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Laughed at this</h6>
                  <h6 v-else-if="data.recentReact.record.type == 'celebrate'" class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Celebrated this</h6>
                  <h6 v-else class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Liked this</h6>                
              </div>
              <div class="user-reaction d-flex align-items-center" v-if="data.recentReact && data.recentReact.reactType == 'comment'">
                <b-avatar :src="data.recentReact.record.user.avatar" style="background: transparent" size="24" />
                <h6 class="mb-0"><span>{{data.recentReact.record.user.name}}</span> Commented on this</h6>
              </div>
              <!-- Sumarized Post -->
              <div class="d-flex align-items-center" v-if="data.showSummary == true">
                <b-link class="back-original d-flex align-items-center mb-1" @click="data.showSummary = false">
                  <feather-icon icon="ChevronLeftIcon" size="16" class="cursor-pointer"/>
                  <span class="pl-25">Back to Original</span>
                </b-link> 
               </div>
              <!-- End Sumarized Post -->
                <div class="d-flex justify-content-between align-items-center mb-2 post-header">
                  <div class="d-flex align-items-center">
                    <!-- avatar -->
                    <b-avatar :src="data.user.avatar" target="_blank"  :href="filterByType != 'post' ? `/profile/${data.user.hashid}` : '#'" style="background: transparent" size="40" class="mr-75" />
                    <!--/ avatar -->
                    <div class="profile-user-info mt-25 cursor-pointer">
                      <h6 class="mb-0">                      
                        {{ data.user.name }}
                      </h6>
                      <small>
                        {{ data.user.designation }}
                      </small>
                      <p class="d-block d-md-none"  v-if="data.is_published == 'true' ">
                        {{ data.created_at | formatDate }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-badge class="d-none d-md-flex schedule-badge" v-if="data.is_scheduled == true &&
                      data.is_published == 'false'
                      " pill variant="light-info">Scheduled : {{ data.created_at}} </b-badge>
                    <!-- <b-badge class="mr-50" v-if="data.is_approved == 'false' &&
                      data.is_published == 'true'
                      " pill variant="light-danger">Need Approval</b-badge> -->

                    <b-badge v-if="data.is_draft == 'true'" pill class="ml-50" variant="light-secondary">Draft</b-badge>
                    <b-img v-if="data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin.svg')" size="24"
                      class="m-1 pin-icon" style="background-color: transparent" v-b-tooltip.hover.v-default
                      title="Post is pinned by admin" />
                    <!--Summarized-->
                    <b-button class="rewrite-aibtn mr-1" variant="outline-ai" v-if="data.is_published == 'true' && data.showSummary == false && filterByType != 'post'" @click="summarizeWithAI(data,index)"><b-img  :src="require('@/assets/images/icons/summarize.svg')" size="24"
                      class=""/><span>Summarize</span></b-button>
                    <!--End Summarized-->
                    <p class="d-none d-md-block" v-if="data.is_published == 'true' ">
                      {{ data.created_at | formatDate }}
                    </p>
                    <b-dropdown class="blog-dropdown" variant="link" toggle-class="text-decoration-none p-0"
                      no-caret  v-if="data.is_published == 'true'">
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle text-muted"/>
                      </template>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_pinned == 'false'" @click="pinPost(data)">
                        <b-img :src="require('@/assets/images/icons/pin.svg')" size="20" class="mr-50"
                          style="background-color: transparent" />
                        <span class="">Pin Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_pinned == 'true'" @click="unpinPost(data)">
                        <b-img :src="require('@/assets/images/icons/unpin.svg')" size="20" class="mr-50"
                          style="background-color: transparent" />
                        <span class="">Un-Pin Post</span>
                      </b-dropdown-item>

                      <b-dropdown-item 
                              class="btn-sm p-0 text-muted"  
                              @click="savePost(data, index)"
                            >
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="14" 
                                height="20" 
                                viewBox="0 0 14 20" 
                                fill="none" 
                                :class="data.youSaved ? 'saved-post' : 'profile-icon'"
                              >
                                <path 
                                  d="M13 5V19L7 15L1 19V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H9C10.0609 1 11.0783 1.42143 11.8284 2.17157C12.5786 2.92172 13 3.93913 13 5Z" 
                                  stroke="#344054" 
                                  stroke-width="1.5" 
                                  stroke-linecap="round" 
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span class="ml-50">{{ data.youSaved ? 'Unsave Post' : 'Save Post' }}</span>
                            </b-dropdown-item>


                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_public == false" @click="postPublicURL(data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Generate Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="($can('Zircly Admin') || data.user.hashid == userData.hashid)  && data.is_public == true" @click="postRevokePublicURL(data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/revoke-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Revoke Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="($can('Zircly Admin') || data.user.hashid == userData.hashid)  && data.is_public == true" @click="copyPublicURL(data.public_post.public_post_id)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Copy Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"  v-b-modal.create-custompop @click="resumeEditing(data)" v-if="($can('post_edit') &&
                        data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin')
                        ">
                        <!-- @click="editRecord(data)" -->
                        <feather-icon icon="EditIcon" size="13" class="mr-50" stroke-width="1.5" />
                        <span class="">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                        data.is_approved == 'false' && userData.need_approval == 'true'
                        " @click="approvePost(data)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50" stroke-width="1.5" />
                        <span class="">Approve Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                        data.is_approved == 'true' && userData.need_approval == 'true'
                        " @click="rejectPost(data)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50" stroke-width="1.5" />
                        <span class="">Reject Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-clipboard:copy="shareUrl(data.hashid)" v-clipboard:success="onCopy"
                        v-clipboard:error="onError" class="btn-sm p-0 text-muted">
                        <feather-icon icon="CopyIcon" size="13" class="mr-50" stroke-width="1.5" />
                        <span class="">Copy</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                        data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin')
                        ">
                        <feather-icon icon="TrashIcon" size="13" class="mr-50" stroke-width="1.5" />
                        <span class="">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <!-- Text Post -->
                <div class="d-flex d-md-none mb-1 cursor-pointer"><b-badge v-if="data.is_scheduled == true &&
                      data.is_published == 'false'
                      " pill variant="light-info">Scheduled : {{ data.created_at}} </b-badge>
                      </div>
                <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="post-body mb-1" v-if="data.other_details && data.other_details.post_type == 'text-post'">
                  <div v-if="data.showSummary == false">
                    <div  class="click-view" style="position: relative">
                      <div :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                        ">
                        <b-card-text>
                          <h2 class="card-header pb-1 mt-lg-0">{{ data.title }}</h2>
                        </b-card-text>
                        <b-card-text v-html="data.snippetbody" style="max-height: 10em; overflow: hidden; text-overflow: ellipsis;"></b-card-text>
                      </div>
                    </div>
                    <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click.stop="viewPost(data)"
                      class="font-weight-bolder text-right see-more pb-1">
                      <u>...see more</u>
                    </p>
                  </div>
                  <div v-else>
                    <div  class="click-view" style="position: relative">
                      <div class="mb-1">
                        <b-card-text>
                          <h2 class="card-header pb-1 mt-lg-0">{{ data.title }}</h2>
                        </b-card-text>                        <!-- Skeleton Loader -->
                        <b-skeleton-wrapper v-if="data.showSummary && (!data.summaryData ||data.summaryData == false )" :loading="true" class="skeleton-loader">
                          <template #loading>
                            <b-skeleton width="100%"></b-skeleton>
                            <b-skeleton width="100%"></b-skeleton>
                            <b-skeleton width="70%"></b-skeleton>
                          </template>
                        </b-skeleton-wrapper>
                        <!-- End Skeleton Loader -->

                        <b-card-text v-else v-html="data.summaryData" style=" text-overflow: ellipsis;"></b-card-text>


                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center justify-content-lg-start " v-if="data.collage_img.length == 1">
                    <b-img v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " @click.stop="viewAttachments(data)" fluid rounded class="mb-25"
                      :src="data.collage_img[0].link" style="max-height: 400px; max-width: 100%" />
                  </div>
                  <div v-if="data.collage_img.length == 2" class="mb-1 row mx-0 cursor-pointer two-imagepost"
                    @click.stop="viewAttachments(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
    data.collage_img[0].link +
    ');height:302px;border-radius:3px;background-size: cover; background-repeat: no-repeat;'
    " class="col-6"></div>
                    <div :style="'height:302px;'" class="col-6 mb-0 b-r-0 post-collage">
                      <img v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :src="data.collage_img[1].link" class="card-img h-100 w-100" alt="..." />
                      <!-- <div class="card-img-overlay w-100">
                        <h5 class="card-title text-white"><u>More</u></h5>
                      </div> -->
                    </div>
                  </div>
                  <div v-if="data.collage_img.length >= 3" class="mb-1 row post-collage multi-post" 
                    @click.stop="viewAttachments(data)">
                    <div class="col-6 left-img pr-50">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
                      data.collage_img[0].link +
                      ');min-height:302px; border-radius:5px;background-size: cover; background-repeat: no-repeat;'
                      " class="collage-img"></div>
                      </div>
                    <div class="col-6 collage-img right-img pl-50">
                      <div v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :style="'background-image:url(' +
                          data.collage_img[1].link +
                          ');height:50%;border-radius:5px;background-size: cover;'
                          " class="row"></div>
                      <div class="card bg-dark mb-0 row b-r-0 text-white">
                        <img v-if="data.collage_img[2].type == 'image/png' ||
                          data.collage_img[2].type == 'image/jpeg'
                          " :src="data.collage_img[2].link" class="card-img" alt="..." />
                        <div class="card-img-overlay"
                         @click.stop="viewAttachments(data)"
                         v-if="data.collage_img[0].type == 'image/png' ||
                          data.collage_img[0].type == 'image/jpeg' || data.collage_img[1].type == 'image/jpeg' || data.collage_img[1].type == 'image/png' || data.collage_img[2].type == 'image/jpeg' || data.collage_img[2].type == 'image/png'  "
                         >
                          <h5 class="card-title text-white">
                            <u>+ {{ data.collage_img.length - 2 }} More</u>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <b-row class="mt-3">
                    <b-col  md="6" v-if="data.collage_img.length"  v-for="pdfFile in data.collage_img.slice(0, 2)">
                      <div class="d-flex justify-content-center justify-content-lg-start" >

                        <b-button v-if="pdfFile.type == 'application/pdf'"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-primary"
                          :href="pdfFile.link"
                          target="_blank"
                          class="w-100"
                        >
                        {{pdfFile.name}}
                        </b-button>
        
                        <div @click.stop="viewAttachments(data)">
                          <video 
                            v-if="pdfFile.type == 'video/mp4' || pdfFile.type == 'video/webm'" 
                           controls
                            style="max-height: 100%; max-width: 100%; pointer-events: none;"
                          >
                            <source :src="pdfFile.link" :type="pdfFile.type">
                            Your browser does not support the video tag.
                          </video>
                        </div>
                       
                      </div>
                    </b-col>
                  </b-row> -->

                  <div>
    <b-row>
      <b-col md="6" v-for="(pdfFile, index) in data.collage_img.slice(0,2)" :key="index">
        <div class="d-flex justify-content-center justify-content-lg-start">
          <b-button v-if="pdfFile.type === 'application/pdf'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            :href="pdfFile.link"
            target="_blank"
            class="text-left px-1"
          >
            {{ pdfFile.name }}
          </b-button>

          <div v-if="pdfFile.type === 'video/mp4' || pdfFile.type === 'video/webm'"
           @click.stop="viewAttachments(data)"
          >
            <video 
              controls
              style="max-height: 100%; max-width: 100%; pointer-events: none;"
            >
              <source :src="pdfFile.link" :type="pdfFile.type">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </b-col>

      <!-- +X more label -->
      <!-- <b-col md="6" v-if="data.collage_img.length > 2" style = "cursor: pointer;"  @click="viewAttachments(data)">
        <div class="d-flex justify-content-center justify-content-lg-start align-items-center" v-if="data.collage_img[0].type != 'image/png' ||
                          data.collage_img[0].type != 'image/jpeg' || data.collage_img[1].type != 'image/jpeg' || data.collage_img[1].type != 'image/png' || data.collage_img[2].type != 'image/jpeg' || data.collage_img[2].type != 'image/png'  " >
          <span class="font-weight-bold">+{{ data.collage_img.length - 2 }} more</span>

        </div>
      </b-col> -->
    </b-row>

  </div>
                  
                </div>
                <!-- End Text Post -->

                <!-- Photo Post -->
                <div class="post-body mb-1" v-if="data.other_details && data.other_details.post_type == 'photo-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                      ">

                      <!-- post img -->
                      <div class="mt-1 mb-1 d-flex" v-if="data.attachments && data.attachments[0]" >
                        <b-img fluid rounded :src="data.attachments[0].link" class="post-img" @click.stop="openWindow(data.attachments[0].link)" />
                      </div>
                      <b-card-text v-html="data.snippetbody" style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;" ></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click.stop="viewPost(data)"
                    class="font-weight-bolder text-right see-more pb-1">
                    <u>...see more</u>
                  </p>
                </div>
                <!-- End Photo Post -->
                <!-- Video Post -->
                <div class="post-body mb-1" v-if="data.other_details && data.other_details.post_type == 'video-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div :class="data.snippetbody.length > 200 ? 'post-card-summary' : '' ">

                      <!-- post img -->
                      <div class="mt-1 mb-1 d-flex" v-if="data.attachments.length">
                        <b-embed type="iframe" aspect="16by9" :src="data.attachments[0] && data.attachments[0].link" allowfullscreen />
                      </div>
                      <b-card-text v-html="data.snippetbody" style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;"></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click.stop="viewPost(data)"
                    class="font-weight-bolder text-right see-more pb-1">
                    <u>...see more</u>
                  </p>
                </div>
                <!-- End Video Post -->

                <!-- Quote Post -->
                <div class="post-body mb-1" v-if="data.other_details && data.other_details.post_type == 'quote-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="quote-post mt-1 click-view" style="position: relative">
                    <div  :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''">
                      <b-card-text style="max-height: 10em; overflow: hidden; text-overflow: ellipsis;">
                        "{{ data.snippetbody }}"
                      </b-card-text>
                      <p class="author-name my-1 pt-50" v-if="data.other_details.author"><strong>Author Name : <span>{{data.other_details.author}}</span></strong></p>
                    </div>
                  </div>

                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click.stop="viewPost(data)"
                    class="font-weight-bolder text-right see-more pb-1">
                    <u>...see more</u>
                  </p>
                </div>
                <!-- End Quote Post -->
                <div class="d-flex justify-content-start mb-50 justify-content-between">
                  <div class="d-flex mobile-avatar align-items-center mb-50">
                    <b-avatar-group size="20">
                    <b-avatar
                      v-for="(user, i) in data.likes"
                      v-if="i < 3"
                      :key="'avatar-' + i"
                      v-b-tooltip.hover.top="user.user.hashid == userData.hashid ? 'You' : user.user.name"
                      class="pull-up m-0"
                      :src="user.user.avatar"/>
                      </b-avatar-group>
                <span
                    v-if="data.latest_like && data.likes_count > 1"
                    class="reactions-count ml-50"
                    v-b-modal="'show-likes-' + data.hashid"
                  >
                    {{ data.youLiked ? "You" : data.latest_like.user.name }} and {{ data.likes_count - 1 }} others
                  </span>
                  <span v-if="data.latest_like && data.likes_count <= 1" class="reactions-count ml-50"
                    v-b-modal="'show-likes-' + data.hashid">
                    {{ data.youLiked ?  'Reacted by You' : 'Reacted by ' + data.latest_like.user.name }}
                  </span>
                  </div>
                  <div class="overall-cmt cursor-pointer"  v-if="data.comments_count > 5" v-b-toggle.add-sidebar-view  @click="viewPost(data)">
                    <span>{{ kFormatter(data.comments_count) }}</span> <span>Comments</span>
                  </div>
                  <div class="overall-cmt cursor-pointer"  v-else-if="data.comments_count > 0">
                    <span>{{ kFormatter(data.comments_count) }}</span> <span>Comments</span>
                  </div>
                </div>
                <!-- likes comments  share-->
                <b-row class="m-0 like-button top-cmd" @click.stop v-if="data.is_published == 'true'">
                  <b-col cols="8" class="d-flex justify-content-start mb-0 pl-0 left-cmt">
                    <b-link class="d-flex align-items-center text-muted text-nowrap post-likes">
                      <div class="likes" v-if="!data.youLiked">
                        <svg  @click="likePost(data, index, 'like')" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none"
                          title="Like" class="active-like">
                          <g>
                            <path
                              d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                              stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(data, index, 'like')"  v-b-tooltip.hover.v-default v-b-tooltip.top title="Like">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="26" class="default-icon"></b-img>
                              <b-img :src="require('@/assets/images/icons/like/like.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'clap')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Appreciate">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/appreciate.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'love')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Love">
                            <b-img :src="require('@/assets/images/icons/like/love.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/love.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'celebrate')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Celebrate">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/celebrate.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'idea')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Insightful">
                            <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/insightful.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'laugh')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Laugh">
                            <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/laugh.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                        </div>
                      </div>
                      <div class="likes" v-if="data.youLiked && data.likeType">
                        <div v-if="data.likeType == 'like'" @click="likePost(data, index, 'like')">
                          <b-img :src="require('@/assets/images/icons/like/like.gif')" width="24" title="Like"
                            class="like-select"></b-img>
                        </div>
                        <div v-else-if="data.likeType == 'clap'" @click="likePost(data, index, 'clap')">
                          <b-img :src="require('@/assets/images/icons/like/appreciate.gif')
                            " width="24" title="Appreciate" class=""></b-img>
                        </div>
                        <div v-else-if="data.likeType == 'love'" @click="likePost(data, index, 'love')">
                          <b-img :src="require('@/assets/images/icons/like/love.gif')
                            " width="24" title="Love" class=""></b-img>
                        </div>
                        <div v-else-if="data.likeType == 'celebrate'" @click="likePost(data, index, 'celebrate')">
                          <b-img :src="require('@/assets/images/icons/like/celebrate.gif')
                            " width="24" title="Celebrate" class=""></b-img>
                        </div>
                        <div v-else-if="data.likeType == 'idea'" @click="likePost(data, index, 'idea')">
                          <b-img :src="require('@/assets/images/icons/like/insightful.gif')
                            " width="24" title="Insightful" class="active-idea"></b-img>
                        </div>
                        <div v-else-if="data.likeType == 'laugh'" @click="likePost(data, index, 'laugh')">
                          <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="24" title="Laugh"
                            class=""></b-img>
                        </div>
                        <div v-else @click="likePost(data, index, 'like')">
                          <b-img :src="require('@/assets/images/icons/like/like.gif')" width="24" title="Like"
                            class=""></b-img>
                        </div>
                        
                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(data, index, 'like')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Like">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="26" class="default-icon"></b-img>
                              <b-img :src="require('@/assets/images/icons/like/like.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'clap')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Appreciate">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                              " width="26" class="default-icon"></b-img>
                              <b-img :src="require('@/assets/images/icons/like/appreciate.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'love')"  v-b-tooltip.hover.v-default v-b-tooltip.top title="Love">
                            <b-img :src="require('@/assets/images/icons/like/love.svg')
                              " width="26" class="default-icon"></b-img>
                              <b-img :src="require('@/assets/images/icons/like/love.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'celebrate')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Celebrate">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/celebrate.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'idea')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Insightful">
                            <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/insightful.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'laugh')"  v-b-tooltip.hover.v-default v-b-tooltip.top title="Laugh">
                            <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                              " width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/laugh.gif')
                              " width="26" class="hover-icon"></b-img>
                          </div>
                        </div>
                      </div>
                      <span class="pl-25 change-reaction" v-if="data.likeType"
                            :style="{
                              color: data.likeType === 'like' ? '#B54708' :
                                              data.likeType == 'idea' ? '#0E7090' :
                                              data.likeType == 'love' ? '#D92D20' :
                                              data.likeType == 'clap' ? '#B54708' :
                                              data.likeType == 'laugh' ? '#B54708' :
                                              data.likeType == 'celebrate' ? '#B54708' : ''
                            }">
                            {{ data.likeType == 'clap' ? 'Appreciate' :
                              data.likeType == 'idea' ? 'Insightful' :
                              data.likeType }}
                          </span>

                      <span class="pl-25 change-reaction" v-else @click="likePost(data, index, 'like')">Like</span>
                      <!-- <span class="text-primary ml-25" v-if="data.likes_count" v-b-modal="'show-likes-' + data.hashid">{{
                        kFormatter(data.likes_count) }}</span> -->
                    </b-link>
                    <b-link class="text-nowrap d-flex align-items-center ml-2" @click="openCommentBox(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" class="profile-icon mr-25">
                      <g clip-path="url(#clip0_12254_35843)">
                        <path d="M17.2222 4C17.9766 4 18.7001 4.29748 19.2335 4.82699C19.767 5.35651 20.0667 6.07468 20.0667 6.82353V14.3529C20.0667 15.1018 19.767 15.82 19.2335 16.3495C18.7001 16.879 17.9766 17.1765 17.2222 17.1765H12.4815L7.74074 20V17.1765H5.84444C5.09005 17.1765 4.36656 16.879 3.83312 16.3495C3.29968 15.82 3 15.1018 3 14.3529V6.82353C3 6.07468 3.29968 5.35651 3.83312 4.82699C4.36656 4.29748 5.09005 4 5.84444 4H17.2222Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_12254_35843">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Comment</span>
                    <!-- <span class="text-muted">{{ kFormatter(data.comments_count) }}</span> -->
                  </b-link>
                  </b-col>
                  <b-modal #default="{ hide }" :id="'show-likes-' + data.hashid" hide-footer hide-header modal-class="modal-likes" class="reactions-popup" @show="getPostReactions(data.hashid)" centered>
                    <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="hide" />
                    <!-- Static New Design -->
                    <b-tabs v-if="postLikes && postLikes.all && postLikes.all.length" class="overall-reaction">
                      <b-tab id="user-reactions">
                          <template #title>
                            <span class="d-flex align-items-center"><span>All</span> <span class="count">({{postLikes.all.length}})</span></span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="like in postLikes.all">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img v-if="like.type == 'like'" :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'clap'" :src="require('@/assets/images/icons/like/appreciate.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'love'" :src="require('@/assets/images/icons/like/love.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'celebrate'" :src="require('@/assets/images/icons/like/celebrate.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'idea'" :src="require('@/assets/images/icons/like/insightful.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'laugh'" :src="require('@/assets/images/icons/like/laugh.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ like.user.name }}
                                    </h6>
                                    <small>
                                      {{ like.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="like-reaction" v-if="postLikes.like && postLikes.like.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/like.gif')
                                " width="20"></b-img>
                              <span class="count">{{postLikes.like.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="like in postLikes.like">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ like.user.name }}
                                    </h6>
                                    <small>
                                      {{ like.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="appriceate-reaction" v-if="postLikes.clap && postLikes.clap.length" >
                        <template #title>
                          <span class="d-flex align-items-center">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.gif')
                              " width="20"></b-img>
                            <span class="count">{{postLikes.clap.length}}</span>
                          </span>
                        </template>
                        <b-card-text>
                          <ul class="list-unstyled">
                            <li v-for="clap in postLikes.clap">
                              <div class="d-flex align-items-center">
                                <div class="position-relative note-reaction">
                                  <b-avatar  target="_blank" :href="`/profile/${clap.user.hashid}`" :src="clap.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                  <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="7" class="small-icon"></b-img>
                                </div>
                                <div class="profile-user-info">
                                  <h6 class="mb-0">
                                    {{ clap.user.name }}
                                  </h6>
                                  <small>
                                    {{ clap.user.designation }}
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-tab>
                      <b-tab id="laugh-reaction" v-if="postLikes.laugh && postLikes.laugh.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                                " width="20"></b-img>
                              <span class="count">{{postLikes.laugh.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="laugh in postLikes.laugh">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${laugh.user.hashid}`" :src="laugh.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ laugh.user.name }}
                                    </h6>
                                    <small>
                                      {{ laugh.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="love-reaction" v-if="postLikes.love && postLikes.love.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/love.gif')
                                " width="20"></b-img>
                              <span class="count">{{postLikes.love.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="love in postLikes.love">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${love.user.hashid}`" :src="love.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/love.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ love.user.name }}
                                    </h6>
                                    <small>
                                      {{ love.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="celebrate-reaction" v-if="postLikes.celebrate && postLikes.celebrate.length" >
                        <template #title>
                          <span class="d-flex align-items-center">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.gif')
                              " width="20"></b-img>
                            <span class="count">{{postLikes.celebrate.length}}</span>
                          </span>
                        </template>
                        <b-card-text>
                          <ul class="list-unstyled">
                            <li v-for="celebrate in postLikes.celebrate">
                              <div class="d-flex align-items-center">
                                <div class="position-relative note-reaction">
                                  <b-avatar  target="_blank" :href="`/profile/${celebrate.user.hashid}`" :src="celebrate.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                  <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="7" class="small-icon"></b-img>
                                </div>
                                <div class="profile-user-info">
                                  <h6 class="mb-0">
                                    {{ celebrate.user.name }}
                                  </h6>
                                  <small>
                                    {{ celebrate.user.designation }}
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-tab>
                      <b-tab id="insightful-reaction" v-if="postLikes.idea && postLikes.idea.length">
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/insightful.gif')
                                " width="20"></b-img>
                              <span class="count">{{postLikes.idea.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="idea in postLikes.idea">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${idea.user.hashid}`" :src="idea.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ idea.user.name }}
                                    </h6>
                                    <small>
                                      {{ idea.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                    </b-tabs>
                     <!-- End Static New Design -->
                    <b-card-text v-else-if="postLikes && postLikes.length == 0">
                      <div class="item error text-center my-2 empty-data">
                        <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                          style="background-color: transparent" />
                        <h4> OH NO!</h4>
                        <p class="d-block mb-1">No Records Found!</p>
                      </div>
                    </b-card-text>
                    <b-card-text v-else-if="postLikes == null">
                      <div class="d-flex align-items-center justify-content-center flex-column gap-12 my-2">
                        <!-- <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                          style="background-color: transparent" /> -->
                          <b-spinner
                          variant="outline-primary"
                          class=""
                          label="Spinning"
                        ></b-spinner>
                        <h4> Loading </h4>
                      </div>
                    </b-card-text>
                  </b-modal>
                  <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0 right-icons pr-0">
                 <b-modal :id="'show-visits-' + data.hashid" hide-footer modal-class="show-visits" scrollable class="text-danger" :hide-header-close="false" centered >
                  <template #modal-header="{ close }">
                    <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="$bvModal.hide('show-visits-' + data.hashid)"/>
                  </template>
                  <b-tabs>
                    <!-- Seen Tab -->
                    <b-tab :title="'Seen (' + (postViews ? postViews.length : 0) + ')' " active>
                      <b-list-group v-if="postViews && postViews.length">
                        <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid" class="d-flex align-items-center px-0">
                          <b-avatar :href="`/profile/${user.hashid}`" :src="user.avatar" size="34" class="mr-75" />
                          <div class="profile-user-info cursor-pointer">
                            <h6 class="mb-0">                      
                              {{ user.name }}
                            </h6>
                            <small>
                              {{ user.designation }}
                            </small>
                          </div>
                        </b-list-group-item>
                      </b-list-group>

                      <b-card-text v-else-if="isBusy" class="skeleton-loader">
                        <!-- <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50" />
                          <h4>Loading</h4>
                        </div> -->
                        <ul class="m-0 p-0 list-unstyled">
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </b-card-text>

                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-tab>

                    <!-- Unseen Tab -->
                    <b-tab :title="'Unseen (' + (postNotVisited ? postNotVisited.length : 0) + ')'">
                      <b-list-group v-if="postNotVisited && postNotVisited.length">
                        <b-list-group-item v-for="user in postNotVisited" :key="'user-' + user.hashid" class="d-flex align-items-center px-0">
                          <b-avatar :href="`/profile/${user.hashid}`" :src="user.avatar" size="34" class="mr-75" />
                          <div class="profile-user-info">
                            <h6 class="mb-0">                      
                              {{ user.name }}
                            </h6>
                            <small>
                              {{ user.designation }}
                            </small>
                          </div>
                        </b-list-group-item>
                      </b-list-group>

                      <b-card-text v-else-if="isBusy" class="skeleton-loader">
                        <!-- <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50" />
                          <h4>Loading</h4>
                        </div> -->
                        <ul class="m-0 p-0 list-unstyled">
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </b-card-text>

                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                  </b-modal>


                   <feather-icon icon="TrendingUpIcon" size="20" class="profile-icon mr-50 cursor-pointer" stroke-width="1.5" v-if="data.visit_count_total > 0" @click="showVisits(data.hashid)" v-b-tooltip.hover.v-default title="Views"/>
                    <span class="cursor-pointer" v-if="data.visit_count_total > 0" @click="showVisits(data.hashid)" >{{data.visit_count_total}}</span>
                    <!-- <b-link class="d-flex align-items-center" @click="savePost(data, index)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none" :class="data.youSaved ? 'saved-post' : 'profile-icon'" v-b-tooltip.hover.v-default
                      :title="data.youSaved ? 'Remove from Saved' : 'Save Post'">
                        <path d="M13 5V19L7 15L1 19V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H9C10.0609 1 11.0783 1.42143 11.8284 2.17157C12.5786 2.92172 13 3.93913 13 5Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </b-link> -->
                  </b-col>
                </b-row>
                <b-row class="mt-50 like-button top-cmd footer-option" @click.stop v-else>
                  <b-col sm="12" class="d-flex justify-content-end my-1 align-items-center">
                    <div v-if="data.is_scheduled == false" class="d-flex align-items-center justify-content-end">
                      <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      " >Delete</b-button>
                      <b-button variant="primary" class="edit-btn opacity-100 ml-1" pill v-b-modal.create-custompop @click="resumeEditing(data)" v-if="($can('post_edit') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      " >Edit</b-button>
                    </div>
                    <div v-if="data.is_scheduled == true" class="d-flex align-items-center justify-content-end">
                      <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Delete</b-button>
                      <b-button variant="outline-secondary" pill class="edit-btn ml-1" v-b-modal.create-custompop @click="resumeEditing(data)" v-if="($can('post_edit') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      " >Edit</b-button>
                      <b-button variant="primary" class="post-btn opacity-100 ml-1" pill v-b-modal.create-custompop @click="post_form_data = data;postNow()" v-if="($can('post_edit') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      ">Post Now</b-button>
                    </div>
                  </b-col>
                </b-row>
                <!--/ likes comments  share-->
              </b-card-body>
              <b-card-footer class="p-0 border-0 mb-1" v-if="data.is_published == 'true'">
                <div class="comment-details">
                  <comments :post="data" :index="index" :employeeListOptions.sync="employeeListOptions"   @view-post="viewPost"  
                  :show-comment-box="showCommentBox" 
                  :top-three="topThree"
                  :current-post-index="currentPostIndex" 
                   showComment="latest" />

                  <input type="text" v-show="false" v-model="userSearch" />
                  <div id="editor-container"></div>
                  <div class="list-group" style="max-height: 200px; overflow-y: scroll">
                    <div class="item fruit list-group-item list-group-item-action br-0" v-for="user in filteredList()"
                      :key="user.code">
                      <p class="mb-0" @click="addTag(user.label, 'comment-field-' + index)">
                        {{ user.label }}
                      </p>
                    </div>
                    <div class="item error text-center my-2 empty-data" v-if="userSearch && !filteredList().length">
                      <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="150" class="mr-50"
                        style="background-color: transparent" />
                      <h4> OH NO!</h4>
                      <p class="d-block mb-1">No results found!</p>
                    </div>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
            <!-- Loader -->
            <div class="text-center mb-2 d-flex align-items-center justify-content-center">
                <div v-if="isPostLoading" class="text-center w-100 skeleton-loader">
                  <!-- <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                    style="background-color: transparent" />
                  <h4> Loading </h4>
                  <p class="d-block mb-1">Please Wait</p> -->
                  <b-card class="w-100">
                    <div class="d-flex justify-content-between align-items-center mb-2 post-header w-100">
                    <div class="d-flex align-items-center w-100">
                      <!-- avatar -->
                      <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                      <!--/ avatar -->
                      <div class="w-100 mt-1">
                        <h6 class="mb-0">                      
                          <b-skeleton  width="50%"></b-skeleton>
                        </h6>
                        <small>
                          <b-skeleton  width="50%"></b-skeleton>
                        </small>
                      </div>
                    </div>
                    <div class="d-flex w-100 align-items-center justify-content-end gap-10">
                      <b-skeleton type="button"></b-skeleton>
                      <b-skeleton  width="30%" class="m-0"></b-skeleton>
                    </div>
                  </div>
                  <div class="body-content">
                    <div class="title">
                      <b-skeleton width="100%"></b-skeleton>
                    </div>
                    <div class="descriptiom">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mt-3">
                      <b-skeleton width="30%"></b-skeleton>
                      <b-skeleton width="20%"></b-skeleton>
                    </div>
                    <b-skeleton type="input" class="my-2"></b-skeleton>
                  </div>
                  </b-card>
                </div>
                <div v-else>
                  <div v-if="posts.length == 0" class="text-center my-5 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="100" class="mr-50"
                      style="background-color: transparent" />
                    <h4> OH NO!</h4>
                    <p class="d-block mb-1">No results found!</p>
                  </div>
                  <div v-if="posts.length > 0 && reachedLastPost" class="text-center my-5 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="100" class="mr-50"
                      style="background-color: transparent" />
                    <h4> Reached Last Post!</h4>
                    <p class="d-block mb-1">No more Posts to Show!</p>
                  </div>
                </div>
              </div>
          </div>
          <EventsList v-if="filterByType == 'event'" />
          <PollsList v-if="filterByType == 'poll'" />
        </div>
      </div>

      <div class="col-lg-4 col-xl-4 right-data" v-if="!$route.params.id">
        <div class="celebration-info">
          <!-- <div class="col-lg-4 col-xl-4 right-data" v-if="!$route.params.id">
            <div class="celebration-info" v-if="this.celebrations && this.celebrations.length > 0"> -->
          <b-card class="p-0 shadow-none" v-if="celebrations && celebrations.length > 0 && filterByType !='post'">
              <div class="d-flex align-items-center justify-content-between side-header">
                <h4 class="mb-0">Anniversaries & Birthdays</h4>
                <b-link href=""><feather-icon icon="ChevronRightIcon" size="18" class="mr-25 cursor-pointer" v-b-tooltip.hover.v-default
                v-b-tooltip.top title="View all" stroke="#667085" @click="AddFilter('post')" /></b-link>
              </div>
              <CelebShort :recent-celebrations="celebrations"  @view-post="viewPost"  />
            </b-card>
        </div>
        <div class="eventpoll-highlight">
          <div class="event-data" v-if="filterByType != 'event' && $can('event_show')">       
            <b-card class="p-0 shadow-none">
              <div class="d-flex align-items-center justify-content-between side-header">
                <h4 class="mb-0 cursor-pointer" @click="AddFilter('event')">Upcoming Events</h4>
                <feather-icon icon="ChevronRightIcon" size="18" class="mr-25 cursor-pointer" v-b-tooltip.hover.v-default
                v-b-tooltip.top title="View all" stroke="#667085" @click="AddFilter('event')" />
              </div>
              <EventsShort :recent-events="recentEvents" />
            </b-card>
          </div>
          <div class="poll-data" v-if="filterByType != 'poll'"> 
            <b-card>
              <h4 class="mb-1 cursor-pointer" @click="AddFilter('poll')">Recent Polls</h4>
              <PollsShort :recent-polls="recentPolls" />
            </b-card>
          </div>
        </div>
        <!-- events and polls goes here -->
        <Timeline v-if="userData.company_twitter" :id="userData.company_twitter" source-type="profile" :options="{
          tweetLimit: '5',
          theme: $store.state.appConfig.layout.skin,
        }" class="mb-2 tweets">
        </Timeline>
        <YouTubeWidget v-if="userData.company_youtube == 'true'" :apiKey="userData.company_youtube_apikey"
          :channelId="userData.company_youtube_channel_id" :resultsPerRequest="3"></YouTubeWidget>
      </div>

      <div v-if="$route.params.id" class="col-lg-4 col-xl-4">
        <b-overlay :show="isLoading" rounded="sm" :variant="skin">
          <profile-about :about-data="profileData" />
        </b-overlay>
        <b-overlay :show="isLoading" rounded="sm" :variant="skin">
          <profile-upcoming-events v-if="profileData.events && profileData.events.length" :events="profileData.events" />
        </b-overlay>
        <b-overlay v-if="$route.params.id && userData.application_mode == 'company'" :show="isLoading" rounded="sm" :variant="skin">
          <profile-suggested-pages v-if="profileData.teamMates && profileData.teamMates.length" :pages-data="profileData.teamMates" />
        </b-overlay>
      </div>

    </div>
    <!-- End Post Listing -->
    <!-- Modals/Componenets -->
    <EventAdd :item-id.sync="postId" :sidebar-event-add-active.sync="sidebarEventAddActive"
      :employeeListOptions.sync="employeeListOptions" :customFields.sync="customFields" />
    <PostView :item-id.sync="postId" :sidebar-view-active.sync="sidebarViewActive"
      :employeeListOptions.sync="employeeListOptions" :itemData.sync="postData"  @triggerParentRefresh="getPosts()" @triggerEditPost="OpenEdit" @triggerPostNow="OpenPostNow"/>
    <PollAdd :item-id.sync="postId" :sidebar-poll-add-active.sync="sidebarPollAddActive"
      :employeeListOptions.sync="employeeListOptions" />
    
      <b-modal v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value"
      :id="'unseen-' + channel.value" modal-class="modal-likes" scrollable size="lg"
      :title="'Unseen ' + channel.label + ' Posts'" ok-title="Mark all as Read" ok-only @ok="markAsRead(channel.value)"
      class="text-danger" centered>
      <b-list-group v-if="unseenBadge['unseen_' + channel.value] &&
        unseenBadge['unseen_' + channel.value].length
        ">
        <b-list-group-item :key="post.hashid" v-for="post in unseenBadge['unseen_' + channel.value]">
          <b-row>
            <b-col md="1" sm="2" class="unseen-col">
              <b-avatar :src="post.avatar" style="background: transparent" size="24" />
            </b-col>
            <b-col md="7" sm="8" class="unseen-col"> "{{ post.title }}" </b-col>
            <b-col cols="3" class="d-none d-md-block">
              -by {{ post.author }}
            </b-col>
            <b-col cols="1">
              <b-link class="text-body" @click="OpenPost(post)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="mr-25" v-b-tooltip.hover.v-default
                  v-b-tooltip.left title="Open Post" />
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="unseenBadge['unseen_' + channel.value] &&
        unseenBadge['unseen_' + channel.value].length == 0
        ">
        <div class="item error text-center my-2 empty-data">
          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
            style="background-color: transparent" />
          <h4> OH NO!</h4>
          <p class="d-block mb-1">No Records Found!</p>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal id="unseen-feeds" modal-class="modal-likes" scrollable size="lg" title="Unseen Feeds"
      class="text-danger" ok-title="Mark all as Read" ok-only @ok="markAsRead('feeds')" centered>
      <b-list-group v-if="unseenBadge.unseen_feeds && unseenBadge.unseen_feeds.length">
        <b-list-group-item v-for="post in unseenBadge.unseen_feeds" :key="post.hashid">
          <b-row>
            <b-col md="1" sm="2" class="unseen-col">
              <b-avatar :src="post.avatar" style="background: transparent" size="24" />
            </b-col>
            <b-col md="7" sm="8" class="unseen-col"> "{{ post.title }}" </b-col>
            <b-col cols="3" class="d-none d-md-block">
              -by {{ post.author }}
            </b-col>
            <b-col cols="1">
              <b-link class="text-body" @click="OpenPost(post)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="mr-25" v-b-tooltip.hover.v-default
                  v-b-tooltip.left title="Open Post" />
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="unseenBadge.unseen_feeds && unseenBadge.unseen_feeds.length == 0
        ">
        <div class="item error text-center my-2 empty-data">
          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
            style="background-color: transparent" />
          <h4> OH NO!</h4>
          <p class="d-block mb-1">No Records Found!</p>
        </div>
      </b-card-text>
    </b-modal>

    <!-- channel descriptions modal -->
    <b-modal id="channel-descriptions-modal" ok-only ok-title="Accept" modal-class="modal-primary" centered
      size="lg" title="Channel Descriptions" hide-footer>
      <b-tabs class="mb-1">
        <b-tab v-if="communityChannels.length" v-for="channel, index in communityChannels" :title="channel.label"
          :active="index == 0">
          <b-card-text v-if="channel.description" class="ml-1">
            {{ channel.description }}
          </b-card-text>
          <b-card-text v-else class="ml-1">
            This channel has no description.
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- END Modals/Componenets -->
    <b-modal id="scheduled-postview" size="lg" title="schedule-postpop" hide-footer :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" header-class="sticky-top" centered>
      <template #modal-header="{ close }">
        <div class="">
          <h5 class="schedule-header mb-0 d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" @click="closeScheduledPopUP">
            <g clip-path="url(#clip0_9678_29091)">
            <path d="M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_9678_29091">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            <span>Scheduled & Draft Posts </span>
          </h5>
        </div>
        <div class="next-expand d-flex align-items-center">
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent;" v-b-tooltip.hover.v-default
            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')" width="26px" height="26x" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent;" v-b-tooltip.hover.v-default
            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  width="26px" height="26px"/>
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="closeScheduledPopUP" />
      </template>
      <div class="schedule-list">
        <ul class="m-0 p-0">
          <li class="list-unstyled mb-1" v-for="post in nonPublishedPosts" v-b-modal.create-custompop
          @click="post_form_data = post;closeScheduledPopUP();" v-if="($can('post_edit') &&
            post.user.hashid == userData.hashid) ||
            $can('Zircly Admin')
            ">
            <div class="d-flex align-items-center justify-content-between mb-50">
              <h6 class="d-flex align-items-center mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 16" fill="none" v-if="post.is_scheduled">
                <path d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C14.9979 6.14413 14.2597 4.36489 12.9474 3.05259C11.6351 1.7403 9.85587 1.00212 8 1ZM2.01 8.197L2.679 8.42L3.5 9.6515V10.293C3.50003 10.4256 3.55273 10.5528 3.6465 10.6465L5 12V13.1885C4.11895 12.6786 3.38218 11.9529 2.85909 11.0797C2.33599 10.2064 2.04379 9.21438 2.01 8.197ZM8 14C7.56745 13.9991 7.13626 13.9513 6.714 13.8575L7 13L7.9025 10.744C7.93258 10.6687 7.94387 10.5872 7.93543 10.5065C7.92698 10.4258 7.89903 10.3485 7.854 10.281L7.1485 9.2225C7.10283 9.15404 7.04096 9.09792 6.96839 9.0591C6.89583 9.02029 6.8148 8.99999 6.7325 9H4.2675L3.6435 8.0635L4.707 7H5.5V8H6.5V6.633L8.434 3.248L7.566 2.752L7.1385 3.5H5.7675L5.225 2.6855C5.95392 2.30085 6.755 2.07235 7.57715 2.01459C8.3993 1.95682 9.22447 2.07106 10 2.35V4C10 4.13261 10.0527 4.25979 10.1464 4.35355C10.2402 4.44732 10.3674 4.5 10.5 4.5H11.2325C11.3148 4.50001 11.3958 4.47971 11.4684 4.4409C11.541 4.40208 11.6028 4.34596 11.6485 4.2775L12.087 3.6195C12.6581 4.15213 13.12 4.79085 13.447 5.5H11.41C11.2945 5.50007 11.1825 5.54016 11.0932 5.61344C11.0038 5.68673 10.9426 5.78869 10.92 5.902L10.5585 8.137C10.5414 8.24222 10.5584 8.35016 10.6069 8.44506C10.6555 8.53995 10.7331 8.61684 10.8285 8.6645L12.5 9.5L12.8425 11.528C12.2875 12.2928 11.5595 12.9154 10.7179 13.3451C9.87626 13.7747 8.94494 13.9991 8 14Z" fill="#6E6B7B"/>
                </svg>
                <small v-if="post.is_scheduled" ><span>Posting </span> <span>{{ post.created_at | formatDate }}</span> at <span>{{post.scheduled_time}}</span> </small> <b-badge pill variant="light-info"  >Draft</b-badge>
              </h6>
              <div class="right-action d-flex align-items-center gap-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-defult
                title="Schedule" v-if="!post.is_published || post.is_published == 'false'" v-b-modal.schedule-timepop>
                  <g clip-path="url(#clip0_9674_28132)">
                    <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_9674_28132">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" @click.stop.prevent="confirmDeleteRecord(post)" v-if="($can('post_delete') &&
                post.user.hashid == userData.hashid) ||
                $can('Zircly Admin')
                ">
                  <g clip-path="url(#clip0_9674_28137)">
                    <path d="M4 7H20" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 11V17" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 11V17" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_9674_28137">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="body-content">
              <h5>{{post.title}}</h5>
              <p class="mb-0" v-html="post.snippetbody"></p>
            </div>
          </li>
        </ul>
      </div>
    </b-modal>
    <!-- Scedule Time -->
    <b-modal id="schedule-timepop" size="lg" title="schedule-time" hide-footer :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" centered>
      <template #modal-header="{ close }">
        <div class="">
          <h5 class="schedule-header mb-0">
            <span>Scheduled Posts</span>
          </h5>
        </div>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click.prevent.stop="confirmScheduleCloseButton" />
      </template>
      <b-row :class="optionsSelected== 0 ? 'schedule-pick' : 'schedule-pick deactive'">
        <!-- Select custom date time -->
      <!-- <b-row class="schedule-pick deactive"> -->
        <!-- End Select custom date time -->
        <b-col sm="12" lg="7">
          <!-- <flatPickr v-model="post_form_data.scheduled_time"/> -->
          <div class="custom-calender">
            <div class="container-calendar">
                <h3 id="monthAndYear"></h3>
                <div class="button-container-calendar d-flex align-items-center justify-content-between">
                    <div class="month-year">
                      <!-- <select v-model="selectedMonth">
                        <option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
                      </select> -->
                      <b-badge variant="outline-secondary" class="text-secondary">
                        {{months[selectedMonth]}}
                      </b-badge>
                      <b-badge variant="outline-secondary" class="text-secondary">
                        {{selectedYear}}
                      </b-badge>
                      <!-- <select v-model="selectedYear">
                        <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
                      </select> -->
                      <!-- <div class="select-year">
                        <input type="text" id="year">
                      </div> -->
                    </div>
                    <div class="d-flex align-items-center year-arrow">
                      <button id="previous" @click="previous()" class="btn-arrow">
                        <b-img :src="require('@/assets/images/icons/dashboard/right-arrow.svg')" width="22"/>
                      </button>
                      <button id="next" @click="next()" class="btn-arrow">
                        <b-img :src="require('@/assets/images/icons/dashboard/left-arrow.svg')" width="34"/>
                      </button>
                    </div>
                </div>
                <table id="calendar" class="table-calendar">
                  <thead>
                    <tr>
                      <th v-for="day in days" :key="day">{{ day }}</th>
                    </tr>
                  </thead>
                  <tbody id="calendar-body">
                    <tr v-for="(week, weekIndex) in calendarDays" :key="weekIndex">
                      <td v-for="(day, dayIndex) in week" :key="dayIndex" :class="dayClass(day)" @click="scheduleDateChange(day)">
                        <span>{{ day }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
        </b-col>
        <b-col sm="12" lg="5" class="select-scheduletype">
          <div class="pick-timedate" v-if="optionsSelected == 0">
            <b-row>
              <b-col>
                <b-form-group class="mb-0">
                    <v-select v-model="post_form_data.scheduled_timezone"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="timezones" :clearable="false"
                              label="name" :reduce="(option) => option.name" :filter-by="myTimezoneFilter"
                              :searchable="true" input-id="timezone"
                              placeholder="Indian Standard Time">
                              <template slot="option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>

                              <template slot="selected-option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
                            </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col sm="12" md="6">
                <div class="selected-date">
                  <input type="text" id="datepicker" v-model="post_form_data.scheduled_date">
                  <!-- <span style="color:red">select a valid date</span> -->
                </div>
              </b-col>
              <b-col sm="12" md="6">
                <div class="selected-timer">
                  <div class="select-timer d-flex align-items-center">
                      <b-dropdown :text="post_form_data.scheduled_hour" class="select-hours custom-multiselect">
                        <b-dropdown-item v-for="hour in hours" @click="post_form_data.scheduled_hour = hour">{{hour}}</b-dropdown-item>
                      </b-dropdown>
                      <span>:</span>
                      <b-dropdown :text="post_form_data.scheduled_minute" class="select-mins" >
                        <b-dropdown-item v-for="minute in minutes" @click="post_form_data.scheduled_minute = minute">{{minute}}</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  <span class="center-line"> | </span>
                  <b-dropdown :text="post_form_data.scheduled_meridiem"  class="select-hours">
                    <b-dropdown-item @click="post_form_data.scheduled_meridiem = 'am'">AM</b-dropdown-item>
                    <b-dropdown-item @click="post_form_data.scheduled_meridiem = 'pm'">PM</b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </div>
          <hr />
          <div class="pre-scedule">
            <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
              <b-form-radio-group id="btn-radios-3" v-model="post_form_data.scheduled_time"
              :options="schedule_options" @change="optionsSelectedEvent() " :aria-describedby="ariaDescribedby" name="radio-btn-stacked"
              buttons button-variant="outline" class="w-100 select-period" stacked></b-form-radio-group>
            </b-form-group>
          </div>
          <u v-if="optionsSelected == 1" @click="optionsSelected = 0; post_form_data.scheduled_time = defaultDateTime" class="pick-timedate"><label>Pick Date & Time</label></u>
          <b-button pill variant="primary" class="unselect" @click="post_form_data.is_scheduled = true;formSubmitted()">Schedule</b-button>
        </b-col>
      </b-row>
    </b-modal>


    <b-modal v-model="isPopupVisible" id="modal-imagecarousel" centered >
  <template v-if="popupImages && popupImages.length > 0">
    <b-carousel
      id="carousel-interval"
      :interval="0"
      :controls="popupImages.length > 1 ? true : false"
    >
      <b-carousel-slide
        v-for="(media, index) in popupImages"
        :key="index"
      >
        <template #img>
          <div v-if="media && media.type.startsWith('image/')">
            <img :src="media.link" class="d-block w-100" alt="Image slide">
          </div>
          <div v-else-if="media && media.type.startsWith('video/')">
            <video 
              controls
              class="d-block w-100"
              style="max-width: 100%;"
            >
              <source :src="media.link" :type="media.type">
              Your browser does not support the video tag.
            </video>
          </div>
            <!-- <div v-else-if="media && media.type === 'application/pdf'">
          <iframe
            :src="media.link"
            class="d-block w-100"
            style="height: 500px;"
            frameborder="0"
          >
            Your browser does not support PDFs. <a :href="media.link">Download the PDF</a>.
          </iframe>
        </div> -->
  
        </template>
      </b-carousel-slide>
    </b-carousel>
  </template>

  <template #modal-footer class="p-0 m-0 border-0">
    <div class="w-100">
      <b-button
        variant="white"
        size="sm"
        class="float-right"          
        v-b-toggle.add-sidebar-view
        @click="isPopupVisible = false"
      >
        View Full Post
      </b-button>
    </div>
  </template>
</b-modal>


  </div>
</template>
  
<script>
import { BEmbed, BRow, BCol, BOverlay, BButton, BImg, BTabs, BTab, BCardText,  BDropdown, BDropdownItem, BSkeleton } from "bootstrap-vue";
import { $themeConfig } from '@themeConfig'
import bomb from "@/libs/bomb/bomb";
import moment from "moment-timezone";
import Omoment from "moment";
import { Timeline } from "vue-tweet-embed";
import store from "@/store";

import PostAdd from "./PostAdd.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import $ from "jquery";
Vue.use(VueCookies);
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);
import axios from "@axios";
import { kFormatter } from "@core/utils/filter";
import EventsList from "@/views/feed/events/EventsList.vue";
import PollsList from "@/views/feed/polls/PollsList.vue";
import { bus } from "@/main.js";
import CelebShort from "@/views/feed/CelebShort.vue";
import EventsShort from "@/views/feed/events/EventsShort.vue";
import PollsShort from "@/views/feed/polls/PollsShort.vue";
import Replies from "./Replies.vue";
import Comments from "./Comments.vue";
import PostView from "./PostView.vue";

import PollAdd from "@/views/feed/polls/PollAdd.vue";

import QuillInput from "@/components/QuillInput.vue";
import EventAdd from "@/views/feed/events/EventAdd.vue";
import { onMounted, ref, watch, onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
/* eslint-disable global-require */
const VueUploadComponent = require('vue-upload-component')
const { appMaxpopImg, appMinpopImg } = $themeConfig.app
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
let timezones = require("/src/libs/timezone.json");
import flatPickr from "vue-flatpickr-component";
import VEmojiPicker from "v-emoji-picker";
import packEmoji from "v-emoji-picker/data/emojis.js";
import { required } from "@validations";
import ProfileSuggestedPages from "./ProfileSuggestedPages.vue";
import ProfileAbout from "./ProfileAbout.vue";
import ProfileUpcomingEvents from "./ProfileUpcomingEvents.vue";
import { useRefHistory } from "@vueuse/core";
Vue.component('file-upload', VueUploadComponent)
export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BTabs,
    BCardText,
    BTab,
    BButton,
    BImg,
    Timeline,
    PostAdd,
    EventsList,
    PollsList,
    CelebShort,
    EventsShort,
    PollsShort,
    Replies,
    Comments,
    QuillInput,
    EventAdd,
    PostView,
    BDropdown,
    BDropdownItem,
    PollAdd,
    FileUpload: VueUploadComponent,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BEmbed,
    VEmojiPicker,
    ProfileSuggestedPages,
    ProfileAbout,
    ProfileUpcomingEvents,
    BSkeleton,
  },

  data() {
    return {
      carouselInterval: 3000,
      showCommentBox: false, 
      topThree: true,
      currentPostIndex: 0,
      currentThreadId: 0,
      newThreadName:'',
      isReWriting:false,
      postFormTabIndex:0,
      isAIWriting : false,
      loading: true,
      loadingTime: 1000,
      maxLoadingTime: 10,
      AIChat:[],
      writeai:false,
      rewrite:null,
      old_title:null,
      new_title:null,
      tempImgTags:[],
      lastBoxClicked:'Post Body',
      videoURL:null,
      isSpinning: false,
      today: new Date(),
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      optionsSelected:0,
      isDropdown2Visible: false,
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      hours : Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')),
      minutes : Array.from({ length: 12 }, (_, i) => String(i*5).padStart(2, '0')),
      defaultDateTime:moment().add(1, "days").format("YYYY-MM-DD"),
      createYear : [],
      watchEnabled: true,
      nonPublishedPosts:[],
      boxOne: '',
      postType:"text-post",
      toolbarState: {
      textPostToolbar: true,
      imagePostToolbar: false,
      videoPostToolbar: false,
      },

      dialogHidden: true,
      appMaxpopImg,
      appMinpopImg,
      coverImg: require("@/assets/images/profile/user-uploads/timeline.svg"),
      userData: this.$cookies.get("userData"),
      options: [
        {
          text: "Energetic",
          value: "5",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/cute.svg"),
          gifimg: require("@/assets/images/moods/energetic.gif"),
        },
        {
          text: "Focused",
          value: "4",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/shy.svg"),
          gifimg: require("@/assets/images/moods/focused.gif"),
        },
        {
          text: "Neutral",
          value: "3",
          color: "btn-outline-primary",
          icon: "PauseIcon",
          img: require("@/assets/images/moods/neutral.svg"),
          gifimg: require("@/assets/images/moods/neutral.gif"),
        },
        {
          text: "Stressed",
          value: "2",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/thinking.svg"),
          gifimg: require("@/assets/images/moods/stressed.gif"),
        },
        {
          text: "Frustrated",
          value: "1",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/angry.svg"),
          gifimg: require("@/assets/images/moods/frustrated.gif"),
        },
      ],
      AIThreads:{
        today : [],
        yesterday : [],
        past_30_days : [],
      },
      swipeData: {
        type: "",
        user_feeling: 6,
      },
      recentSwipes: null,
      mood_options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
      profileData: {
        header: {
          avatar: "",
          username: "",
          designation: "",
          coverImg: require("@/assets/images/profile/user-uploads/timeline.svg"),
        },
        userAbout: {
          employee: "",
          email: "",
          joined: "",
        },
        twitterFeeds: [
          {
            imgUrl: require("@/assets/images/avatars/5-small.png"),
            title: "Gertrude Stevens",
            id: "tiana59 ",
            tags: "#design #fasion",
            desc: "I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.",
            favorite: false,
          },
          {
            imgUrl: require("@/assets/images/avatars/12-small.png"),
            title: "Lura Jones",
            id: "tiana59 ",
            tags: "#vuejs #code #coffeez",
            desc: "Halvah I love powder jelly I love cheesecake cotton candy. 😍",
            favorite: true,
          },
          {
            imgUrl: require("@/assets/images/avatars/1-small.png"),
            title: "Norman Gross",
            id: "tiana59 ",
            tags: "#sketch #uiux #figma",
            desc: "Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.",
            favorite: false,
          },
        ],
      },
      filters:{
        typeFilter: "Upcoming",
        postFilter: "Top",
      },
      employeeListOptions: [],
      customFields: {},
      communityChannels: [
        { label: "Community", value: "community" },
      ],
      unseenBadge: {
        community: 0,
        feeds: 0,
        events: 0,
        polls: 0,
        feedback: 0,
      },
      isPopupVisible: false,
      popupImages: [],
      myFiles: [],
      promptText:'',
      Selected: '',
      skin: store.state.appConfig.layout.skin,
      posts: [],
      filterByType: "community",
      comment_id: null,
      comment_post_index: null,
      reply_to: null,
      skin: store.state.appConfig.layout.skin,
      sidebarViewActive: false,
      sidebarEditActive: false,
      sidebarEventAddActive: false,
      sidebarPollAddActive: false,
      postId: null,
      postData: null,
      userData: this.$cookies.get("userData"),
      search: "",
      userSearch: "",
      userSearchActive: false,
      inputId: null,
      isBusy: true,
      isPostLoading: true,
      reachedLastPost: false,
      tempFlag: false,
      lastPage: 1,
      nextPage: 1,
      currentPage: 1,
      postLikes: null,
      searchQuery: "",
      searchQueryEvent: "",
      searchQueryPoll: "",
      editComments: [],
      onGoingProgram: {},
      typeFilter: "Upcoming",
      postListFilter: "Recent",
      typeChannel: "community",
      fullScreen: false,
      postListOptions: [
        { value: "Top", label: "Top" },
        { value: "Recent", label: "Recent" },
        { value: "Unread", label: "Unread" },
      ],
      typeOptions: [
        { value: "Upcoming", label: "Upcoming" },
        { value: "Past", label: "Past" },
      ],
      timeOptions: [
        { value: "Limited", label: "Limited" },
        { value: "UnLimited", label: "Unlimited" },
      ],
      postTypeOptions: [
        { value: "text-post", label: "New Text Post" },
        { value: "video-post", label: "New Video Post" },
        { value: "photo-post", label: "New Photo Post" },
        { value: "quote-post", label: "New Quote Post" },
      ],
      page2: false,
      originalOption: null,
      QuoteSourceTypes: [
        { value: "Book", label: "Book" },
        { value: "Blog/Article", label: "Blog/Article" },
        { value: "Video", label: "Video" },
        { value: "Podcast", label: "Podcast" },
      ],
      unseenBadge: {
        community: 0,
        feeds: 0,
        events: 0,
        polls: 0,
        feedback: 0,
      },
      postViews: [],
      postNotVisited:[],
      datasuggest: {
        data: [
          {
            developers: [
              {
                name: "guna",
                time: "",
                img: "",
              },
            ],
            designers: [
              {
                name: "guna",
                time: "",
                img: "",
              },
            ],
          },
        ],
      },
      filteredOptions: [],
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "Type 'e'",
      },
      limit: 10,
      profileData: {
        header: [],
        userAbout: [],
        teamMates: [],
        events: [],
      },
      isLoading: false,
      postsData: [],
      recentPolls: [],
      recentEvents: [],
      celebrations:[],
      pollData: {
        title: "",
        description: "",
        options: ["", ""],
        allow_multiple: false,
        voter_identity_type: "identified",
        limited_time: false,
      },
      post_form_data: {
        title: "",
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        body: "",
        scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
        scheduled_hour:"08",
        scheduled_minute:"00",
        scheduled_meridiem:"AM",
        scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
        scheduled_timezone: this.getUserTimezone(),
        is_scheduled: false,
        other_details:{
          post_type:'text-post',
          author:''
        }
      },
      isEdited: false,
      postSubmittedClicked: false,
      schedule_options: [],
      schedule_newoptions: [
        {
          text:
            "Today at " +
            "5 PM",
            value: "5",
        },
        {
          text:
            "Tomorrow at " +
            "9 AM",
            value: "9",
        },
        {
          text:
            "Next Monday at " + "9 AM",
          value: "9:00",
        },
      ],
      timezones: timezones,
      myTimezoneFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.offset.toLowerCase().indexOf(temp) > -1
        );
      },
      remote_file: 'https://fileinfo.com/img/ss/xl/url_181.png',

      previewUrl: null,
      isImage: false,
      fileError:null,
    };
  },
  watch: {

    loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      },
    myFiles(value){
      var temp = [];
      var tempArray = [];
      for (let index = 0; index < value.length; index++) {
        const element = value[index];

        if(!temp.includes(element.file.name)){
          temp.push(element.file.name);
          tempArray.push(element);
        }
      }
      if(tempArray.length != value.length){
        this.myFiles = tempArray;
      }
    },
    filterByType(val) {     
      bus.$emit("search_on",val);
      if (val != 'event' && val != 'poll') {
        this.getPosts();
        if (val != 'post') {
          this.typeChannel = val;
        }
      }
    },

    'post_form_data.title': {
      handler(newValue, oldValue) {
        this.checkForChanges();
      },
      deep: true 
    },
    'post_form_data.body': {
      handler(newValue, oldValue) {
        this.checkForChanges();
      },
      deep: true
    },
    'post_form_data.scheduled_date': {
      handler(newValue, oldValue) {
        if(this.isValidDate(newValue)){
        var date = moment(newValue);
        var month = date.format('M')-1;
        var day   = date.format('D');
        var year  = date.format('YYYY');
        this.selectedMonth = month;
        this.selectedYear = year;

      }
      },
      deep: true
    },
    'post_form_data.scheduled_timezone': {
      handler(newValue, oldValue) {
        this.schedule_options_update();
      },
      deep: true
    },
    
    
    post_form_data:{
      
      handler(newValue, oldValue) {
        if (this.watchEnabled) {
          // this.saveDraft();
          if(!newValue.is_published || newValue.is_published == 'false'){
            
            this.startSaveDraftTimer();
          }
        }
      },
      deep: true // Watch for changes in nested properties
    },

    postType(newVal) {
      this.updateToolbarState();
    },

    'post_form_data.other_details.post_type' : function(newValue, oldValue) {
      // This function will be called whenever myData.key1 changes
      this.originalOption = oldValue;
    },

    typeFilter(val) {
      bus.$emit("loadEvents", { type: val });
    },
    postListFilter(val) {
      localStorage.setItem('postListFilter',val);
      this.getPosts();
    },
    '$route.params.id' : function(val){
      this.refreshListData();
    },
    '$route.query': function(val){
      const urlParams = new URLSearchParams(window.location.search);
      const filter = urlParams.get('filter');

      if(filter){
        if(filter == 'draft-posts'){
          this.getPosts('my-draft-posts');
        }
        if(filter == 'scheduled-posts'){
          this.getPosts('my-scheduled-posts');
        }
        if(filter == 'saved-posts'){
          this.getPosts('my-saved-posts');
        }
      }else{
        if(urlParams.get('q')){
          this.searchQuery = urlParams.get('q');
        }else{
          this.searchQuery = "";
        }
        this.refreshListData();
      }
      this.getPosts('non-published-posts',true);
    }
    // '$route.query' : function(val){
    //   console.log(this.$route);
    //   if(this.$route.query.filter == 'draft-posts'){
    //     this.getPosts('my-draft-posts');
    //   }
    //   if(this.$route.query.filter == 'scheduled-posts'){
    //     this.getPosts('my-scheduled-posts');
    //   }
    //   if(this.$route.query.filter == 'saved-posts'){
    //     this.getPosts('my-saved-posts');
    //   }
    // }
  },
  beforeCreate() {
    axios.get("communityCollection").then((response) => {
      this.recentPolls = response.data.data.recentPolls;
      this.recentEvents = response.data.data.recentEvents;
    });
    axios.get("celebration/getCelebration").then((response) => {
      this.celebrations = response.data.data;
    });
  },
  created() {
    this.$_loadingTimeInterval = null;
    bus.$emit("search_on",'post');
    if (!this.userData) {
      this.$router.push({ name: "auth-login" });
    }
    // get id from url    
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.$http
        .get(`/user/${id}`)
        .then((res) => {
          this.profileData = res.data.data;
        });
    } else {
      this.profileData = this.userData;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const filter = urlParams.get('filter');
    const type = urlParams.get('type');
    const search = urlParams.get('q');

    if(filter){
      if(filter == 'draft-posts'){
        this.getPosts('my-draft-posts');
      }
      if(filter == 'scheduled-posts'){
        this.getPosts('my-scheduled-posts');
      }
      if(filter == 'saved-posts'){
        this.getPosts('my-saved-posts');
      }
    }

      if(search){
        this.searchQuery = search
        this.getPosts()
      }
      else{
        this.getPosts();
      }
    this.getPosts('non-published-posts',true);
    this.fetchEmployeeList();
    this.getCustomFields();
    this.fetchChannelList();
    this.getAIHistoryThreads();
    if(type || search){
      
    this.filterByType = type
    }
    else{
      
    this.filterByType = this.communityChannels[0].value;
    }

    bus.$on("loadPosts", (params = "") => {
      this.searchQuery = params;
      this.posts = [];
      this.getPosts();
    });

    bus.$on('AddFilter', this.AddFilter);
    
    this.getOnGoingProgram();
    if (this.$route.params.id) {
      this.fetch_user_data(this.$route.params.id);
    }
    bus.$on("view-post", (data) => {
      this.viewPost(data);
        });

    bus.$on('user-swipe',(swiped)=>{
      if(swiped){
        this.userData = this.$cookies.get("userData")
      }
    })
  },
  computed: {
    yearRange() {
      return Array.from({ length: 81 }, (_, i) => 1970 + i);
    },
    calendarDays() {
      const days = [];
      const firstDay = new Date(this.selectedYear, this.selectedMonth).getDay();
      const daysInMonth = this.getDaysInMonth(this.selectedMonth, this.selectedYear);
      let date = 1;

      for (let i = 0; i < 6; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < firstDay) {
            week.push("");
          } else if (date > daysInMonth) {
            week.push("");
          } else {
            week.push(date);
            date++;
          }
        }
        days.push(week);
      }
      return days;
    },
    emojisNative() {
      return packEmoji;
    },
    pinnedPosts() {
      if (this.posts && this.posts.length) {
        var pinPosts = [];
        this.posts.forEach((post) => {
          if (post.is_pinned == "true") {
            pinPosts.push(post);
          }
        });
        const chunkSize = 2;
        var data = [];
        for (let i = 0; i < pinPosts.length; i += chunkSize) {
          const chunk = pinPosts.slice(i, i + chunkSize);
          data.push(chunk);
        }
        return data;
      }
      return [];
    },

  },
  methods: {
    confirmDeleteThread(thread){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete this AI thread '"+thread.thread_name+"'",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
            this.deleteThread(thread);
        }
      });
    },
    deleteThread(thread){
      axios.delete("/deleteThread/"+thread.hashid)
        .then((res) => {
          if(res.data.success){

            this.getAIHistoryThreads();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thread Deleted",
                icon: "BellIcon",
                variant: "danger",
                text: "Selected Thread Deleted Successfully",
              },
            });
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: Delete Failed",
                icon: "BellIcon",
                variant: "danger",
                text: "Unable to delete the selected",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    renameThread(thread,name){
      axios.post("/renameThread",{'threadId':thread.hashid,'newName' : name})
        .then((res) => {
          if(res.data.success){
            this.newThreadName = '';
            thread.editMode = false;
            thread.thread_name = name;
            if(this.currentThreadId == thread.hashid){
              this.currentThreadId = 0;
              this.AIChat = [];
            }
            this.getAIHistoryThreads();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Helper function to check if a date is today
    isToday(date) {
        const today = new Date();
        return date.getDate() === today.getDate() &&
              date.getMonth() === today.getMonth() &&
              date.getFullYear() === today.getFullYear();
    },

    // Helper function to check if a date is yesterday
    isYesterday(date) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return date.getDate() === yesterday.getDate() &&
              date.getMonth() === yesterday.getMonth() &&
              date.getFullYear() === yesterday.getFullYear();
    },

    // Helper function to check if a date is within the last 30 days
    isWithinLast30Days(date) {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        return date >= thirtyDaysAgo && date <= today;
    },
    getAIHistoryThreads(){
      axios.get("/getUserThreads")
        .then((res) => {

          this.AIThreads = {
                today : [],
                yesterday : [],
                past_30_days : [],
              }
          // Loop through data and categorize it
          res.data.data.forEach(item => {
              const updatedAt = new Date(item.updated_at);
              if (this.isToday(updatedAt)) {
                this.AIThreads.today.push(item);
              } else if (this.isYesterday(updatedAt)) {
                this.AIThreads.yesterday.push(item);
              } else  {
                this.AIThreads.past_30_days.push(item);
              }
          });
          console.log(this.AIThreads);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAIThreadMessages(thread){
      axios.get("/threadChatData/"+thread.hashid)
        .then((res) => {
          this.AIChat = res.data.data;
          this.currentThreadId = thread.hashid;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resumeEditing(data){
      this.post_form_data = data;
      this.rewrite = false;
      this.page2 = false;
      this.writeai = false;
      this.postFormTabIndex = 0;
      this.old_title = null;
      this.new_title = null;
    },
    handleEnterChatAI(event,type,text) {
      event.preventDefault(); // Prevent the default behavior (if needed)
      if (event.key === 'Enter') {
        if (!event.ctrlKey && !event.shiftKey) {
          // If only Enter is pressed, trigger submit
          if(type == 'new-prompt'){
            this.regenerate(text);
          }else{
            return text
          }
        }
      }
    },
    autoResize(reset = null) {
      var textareas =  document.getElementsByClassName('auto-resize');

      // Loop through the elements and perform some action
      for (var i = 0; i < textareas.length; i++) {
        var textarea = textareas[i];
        if (textarea) {
          // alert(textarea.scrollHeight);
          textarea.style.height = 'auto'; // Reset height to auto
          textarea.style.height = textarea.scrollHeight + 'px'; 
          // Set height based on content

          // If content is empty, reset to default height
          if (reset == true) {
            textarea.style.height = '90px';
          }
        }
        
      }
    },
    getLabel(value,options){
      for (let index = 0; index < options.length; index++) {
        const element = options[index];
        if(element.value == value){
          return element.label;
        }
        
      }
      return value;
    },
    getHTML(chat_content){
      return '<h3><b>'+chat_content.title+'</b></h3><br>'+chat_content.body;
    },
    summarizeWithAI(post_data,index){
      this.posts[index].showSummary = true;
      if(this.posts[index].summaryData == false){
      axios.post("/summerizeChatGPT", {'post_id':post_data.hashid, 'body':post_data.body})
        .then((res) => {
          this.posts[index].summaryData = res.data.response;
        })
        .catch((err) => {
          this.posts[index].showSummary = false;
          console.log(err);
          console.log(err.toString());
        });
      }
    },
    openCommentBox(index){
      if (index < 3) {
        if(this.currentPostIndex === index){
        this.topThree = !this.topThree;
        }
        else{
          this.currentPostIndex = index;
          this.topThree = false;
        }
      } else {
        if (this.currentPostIndex === index) {
          this.showCommentBox = !this.showCommentBox;
        } else {
          this.currentPostIndex = index;
          this.showCommentBox = true;
        }
      }
    },
    bytesToMB(bytes) {
      const mb = bytes / (1024 * 1024); // 1 MB = 1024 KB = 1024 * 1024 Bytes
      return mb.toFixed(3); // Returns the result rounded to 2 decimal places
    },
    getFileURL(file){
      return URL.createObjectURL(file);
    },
    clearLoadingTimeInterval() {
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        this.loading = true
        this.loadingTime = 0
      },
    RejectTitle(){
      this.old_title = null;
      this.new_title = null;
    },
    AcceptNewTitle(title){
      this.watchEnabled = false;
      this.old_title = null;
      this.new_title = null;
      this.post_form_data.title = title;
      this.watchEnabled = true;
    },
    AcceptAIChat(data,type){
      this.watchEnabled = false;
      this.post_form_data.title = data.title;
      this.post_form_data.body = data.body;
      this.watchEnabled = true;
      this.postFormTabIndex = 0;
      this.page2 = false;
      this.writeai = false;
    },
    isHTML(str) {
      const htmlPattern = /<\/?[a-z][\s\S]*>/i;
      return htmlPattern.test(str);
    },
    AcceptBody(old_data,new_data){
      if(this.isHTML(old_data)){
        this.post_form_data.body = new_data;
        this.rewrite = null;
      } 
      else{
        this.$refs.PostEditor.replaceTextInRange(old_data,new_data);
      }
    },
    extractImages(htmlString) {
      
        //         // Array to store extracted image tags
        //       const imageTags = [];

        // // Create a temporary DOM element to manipulate the HTML
        // const parser = new DOMParser();
        // const doc = parser.parseFromString(htmlString, 'text/html');

        // // Select all image tags in the HTML
        // const images = doc.querySelectorAll('img');

        // // Loop through each image and push it to the array
        // images.forEach(img => {
        //   imageTags.push(img.outerHTML);
        //   img.remove(); // Remove the image from the DOM
        // });

        // // Get the HTML string back without the image tags
        // const cleanedHTML = doc.body.innerHTML;

        // // Return the array of image tags and the cleaned HTML
        // return { imageTags, cleanedHTML };
      
      // Create a dictionary to store image tags and their generated IDs
    const imageDict = {};

      // Regular expression to match image tags
      const imgRegex = /<img [^>]*src=["'][^"']*["'][^>]*>/g;

      // Extract all image tags from the HTML string
      const imageTags = htmlString.match(imgRegex);

      if (imageTags) {
        imageTags.forEach((imgTag, index) => {
          const imageId = `img_${index + 1}_${new Date().getTime()}`;
          imageDict[imageId] = imgTag;
          htmlString = htmlString.replace(
            imgTag,
            `IMAGE::${imageId}::`
          );
        });
      }
      return {
        updatedHtml: htmlString,
        imageDict: imageDict,
      };
    },
    AskChatGPT(source_data,type){
      if(type != 'write'){
        this.isReWriting = true;
      }
      if (typeof source_data === 'string') {
        var content_data = source_data;
      } else {
        var content_data = Object.assign({}, source_data);
      }
      if(content_data && (content_data.body || content_data.title)){
        var result  = this.extractImages(content_data.body);
        if(result && result.imageDict != {}){
          content_data.body  = result.updatedHtml;
          var data = {
            data : content_data,
            type : type
          };
        }else{
          var data = {
            data : content_data,
            type : type
          };
        }
      }else{
        var data = {
          prompt : content_data,
          type : type
        };
      }
      
      axios.post("/askChatGPT", data)
        .then((res) => {
          console.log(res.data);
          if(type == 'rewrite_title'){
            this.new_title = res.data.titleData.new_title;
            return ;
          }
          if(content_data && (content_data.body || content_data.title)){
            if(content_data.body){
              this.rewrite = {
                body_comparitions : [
                  {
                    old : this.post_form_data.body,
                    new : res.data.response,
                    isInline : false,
                  }
                ]
              }
            }
            
            if(res.data.titleData){
                this.old_title = content_data.title;
                this.new_title = res.data.titleData.new_title;
            }
          }
          else{
            const escapedString = source_data.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim();

            // Create a regular expression to find the target string
            const regex = new RegExp(escapedString, 'gi'); // 'g' for global, 'i' for case-insensitive

            // Replace the found string with <strong> (to make it bold)
            var previous = this.post_form_data.body.replace(regex, (match) => `<div class="old-content-data">${match}</div><br><div class="new-content-data mt-1">`+res.data.response+`</div>`);
            var new_data = this.post_form_data.body.replace(regex, (match) => res.data.response);
            this.rewrite = {
              body_comparitions : [
                {
                  old : previous,
                  new : new_data,
                  isInline : true,
                }
              ]
            }
          }  
          if(result && result.updatedHtml.search('IMAGE::') >= 0){
            const placeholderRegex = /IMAGE::(img_\d+_\d+)::/g;
            const updatedHtml = this.rewrite.body_comparitions[0].new.replace(placeholderRegex, (match, imageId) => {
              console.log(match);
              // Check if the image ID exists in the image dictionary
              return "<br>"+result.imageDict[imageId]+"<br>" || match; // If the image ID is found, replace; otherwise, keep the placeholder
            });
            this.rewrite.body_comparitions[0].new = updatedHtml;
          }

          this.isReWriting = false;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.toString());
        });
    },
    rewriteWithAI(){
      let selectedText = this.$refs.PostEditor.getSelectedText();
      if(selectedText != ''){
        this.AskChatGPT(selectedText,'rewrite');
      }else{
        this.AskChatGPT(this.post_form_data,'rewrite');
      }
    },
    regenerate(text,key = null,regenerated = null){
      text = text.trim();
      if(text.length > 0 ){
        if(regenerated == null){
          if(key == null){
            this.AIChat.push(
              {
                chat_owner:'User',
                chat_content:text,
                edit_mode:false
              }
            );
          }else{
            this.AIChat[key] = {
                chat_owner:'User',
                chat_content:text,
                edit_mode:false
              };
          }
        }
      
        this.promptText = '';
        this.isAIWriting = true;        
        this.autoResize(true);

        axios.post("/askAssistant", {'prompt' : text,'threadId' : this.currentThreadId})
        .then((res) => {
          var response = res.data.response;
          if(response.chat_response && response.chat_response != ''){
            this.AIChat.push(
              {
                chat_owner:'AI',
                chat_content:response.chat_response,
                edit_mode:false,
                prompt : text,
                isChatResponce :  true,
              }
            );
            if(key){
              this.AIChat.splice(key + 1);
            }
          }
          if(response.prompt_result && response.prompt_result.title && response.prompt_result.body){
            this.AIChat.push(
              {
                chat_owner:'AI',
                chat_content:response.prompt_result,
                edit_mode:false,
                prompt : text,
              }
            );
            if(key){
              this.AIChat.splice(key + 1);
            }
          }
          this.isAIWriting = false;
          this.getAIHistoryThreads();
        })
        .catch((err) => {
            console.log(err);
            console.log(err.toString());
          }); 
        
      }
    },
    switchTo(type){
      this.filters.postFilter = type;
    },
    resetFilters(){
      this.filters = {
        postFilter: this.filters.postFilter,
      };
    },
    ClosePostForm(){
      this.$bvModal.hide('create-custompop');
    },
    ImageUpload() {
      // Trigger the image upload button in the toolbar
      this.$refs.PostEditor.triggerImageUpload();
    },
    VideoEmbed() {
      // Trigger the video embed button in the toolbar
      this.$refs.PostEditor.triggerVideoEmbedPopUP();
    },
    embedURL(){
      this.$refs.PostEditor.embedVideoURL(this.videoURL);
    },
    EmojiPicker() {
      if(this.lastBoxClicked == "Post Title"){
        this.toogleDialogEmoji();
      }else{
        this.$refs.PostEditor.triggerEmojiPicker();
      }
    },
    viewAttachments(data){
      this.popupImages = data.collage_img;
      this.postId = data.hashid;
      this.postData = data;
      this.isPopupVisible = true;
    },
    isPastNoonInTimeZone(timezone) {
      // Get the current date and time in the specified timezone
      const currentTime = new Date().toLocaleString("en-US", { timeZone: timezone });

      // Parse the local date string back to a Date object
      const currentDateTime = new Date(currentTime);

      // Get the hours of the current time
      const hours = currentDateTime.getHours();
      // Check if the current time is past 12 PM (noon)
      return hours >= 13;
    },
    schedule_options_update() {
      if(this.isPastNoonInTimeZone(this.post_form_data.scheduled_timezone)){
        this.schedule_options =  [
          {
            text:
              "Tomorrow at 8 AM",
            value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).add(1, "days").format("YYYY-MM-DD ") + "08:00",
          },
          {
            text:
              "Tomorrow Afternoon 1 PM",
            value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).add(1, "days").format("YYYY-MM-DD ") + "13:00",
          },
          {
            text:
              "Next Monday at 8 AM",
            value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).day(8).format("YYYY-MM-DD ") + "08:00",
          },
        ];
      }else{
        this.schedule_options =  [
          {
            text:
              "This Afternoon 1 PM",
            value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).format("YYYY-MM-DD ") + "13:00",
          },
          {
            text:
              "Tomorrow at 8 AM",
            value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).add(1, "days").format("YYYY-MM-DD ") + "08:00",
          },
          
          {
            text:
              "Next Monday at 8 AM",
            value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).day(8).format("YYYY-MM-DD ") + "08:00",
          },
        ];
      }
    },
    optionsSelectedEvent(){
      this.optionsSelected = 1;
      const result = this.extractTimeComponents(this.post_form_data.scheduled_time);
      this.post_form_data.scheduled_hour = ""+result.hour;
      this.post_form_data.scheduled_minute = ""+result.minute;
      this.post_form_data.scheduled_meridiem =""+result.meridiem;
      this.post_form_data.scheduled_date=moment(this.post_form_data.scheduled_time).format("YYYY-MM-DD");
    },
    extractTimeComponents(datetime) {
      // Create a Date object from the given datetime string
      const date = new Date(datetime);

      // Extract the hour and minute
      let hours = date.getHours();
      const minutes = date.getMinutes();

      // Determine the meridiem (AM/PM)
      const meridiem = hours >= 12 ? 'PM' : 'AM';

      // Convert hour from 24-hour format to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      // Pad minutes with leading zeros if necessary
      const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;

      return {
          hour: hours,
          minute: paddedMinutes,
          meridiem: meridiem
      };
    },
    scheduleDateChange(day){
      if (!day) return '';
      var formedDate = this.selectedYear+"-"+(this.selectedMonth+1)+"-"+day
      const result = this.isCurrentOrFutureDate(formedDate, this.post_form_data.scheduled_timezone);
      if (!result){
        // this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: "Can't Schedule with Past Date",
        //         icon: "BellIcon",
        //         variant: "danger",
        //         text: "You can only Schedule with Current or Future Date",
        //       },
        //     });

        this.$toast({
              component: ToastificationContent,
              props: {
                title: "Can't Schedule with Past Date",
                icon: 'BellIcon',
                variant: 'danger',
                text: "You can only Schedule with Current or Future Date",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
      }else{
        this.optionsSelected = 0;
        this.post_form_data.scheduled_time = '';
        this.post_form_data.scheduled_date = this.selectedYear+"-"+(this.selectedMonth+1)+"-"+day;
      }
    },
    next() {
      if (this.selectedMonth === 11) {
        this.selectedYear++;
        this.selectedMonth = 0;
      } else {
        this.selectedMonth++;
      }
    },
    previous() {
      if (this.selectedMonth === 0) {
        this.selectedYear--;
        this.selectedMonth = 11;
      } else {
        this.selectedMonth--;
      }
    },
    jump() {
      // This method is triggered when year or month changes
      alert('handle changes');
    },
    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    isCurrentOrFutureDate(dateString, timezone) {
      // Get the current date in the given timezone
      const currentDate = new Date();
      const currentLocaleDate = new Intl.DateTimeFormat('en-US', { timeZone: timezone }).format(currentDate);
      const currentLocaleDateTime = new Date(currentLocaleDate);

      // Parse the given date in the given timezone
      const givenDate = new Date(dateString);
      const givenLocaleDate = new Intl.DateTimeFormat('en-US', { timeZone: timezone }).format(givenDate);
      const givenLocaleDateTime = new Date(givenLocaleDate);

      // Compare the given date with the current date
      return givenLocaleDateTime >= currentLocaleDateTime;
    },
    dayClass(day) {
      if (!day) return '';
      const selectDate = new Date(this.post_form_data.scheduled_date);
      return (day == selectDate.getDate() &&
        this.selectedMonth == selectDate.getMonth() &&
        this.selectedYear == selectDate.getFullYear())
        ? 'date-picker selected'
        : 'date-picker';
    },
    startSaveDraftTimer() {
      // Clear previous timer
      clearTimeout(this.saveDraftTimer);
      // Start a new timer to save draft after 5 seconds
      this.saveDraftTimer = setTimeout(() => {
        this.saveDraft();
      }, 4000);
    },
    // saveDraft() {
    //   // Call Vuex action or any other logic to save draft
    //   console.log('Draft saved:', this.post_form_data);
    // },
    // wait(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // },
    saveDraft(){
      if(this.post_form_data.body){
        // await this.wait(5000); // Wait for 5 seconds
        this.formSubmitted(true,true);
        this.isSpinning = true;
        setTimeout(() => {
          this.isSpinning = false;
        }, 1500);
      }
    },
    isMoreThanOneYear(dateString) {
      // Parse the input date string to a Date object
      const inputDate = new Date(dateString);
      
      // Get the current date
      const currentDate = new Date();
      
      // Calculate the date one year After from today
      const oneYearAfter = new Date();
      oneYearAfter.setFullYear(currentDate.getFullYear() +1);
      console.log(oneYearAfter);
      // Compare the input date with the date one year after
      return inputDate > oneYearAfter;
    },
    postNow(){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to publish this post now",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.post_form_data.is_scheduled = false;
            this.formSubmitted();
        }
      });
    },
    updateToolbarState(value) {
      if (value === 'text-post') {
        this.toolbarState = {
          textPostToolbar: true,
          imagePostToolbar: false,
          videoPostToolbar: false,
        };
      }
    },

    checkForChanges() {
      if(this.post_form_data.title && this.post_form_data.body){
        const title = this.post_form_data.title.trim();
        const body = this.post_form_data.body.trim();
        this.isEdited = title !== '' || body !== '';
      }
    },
    OpenEdit(data){
      this.resumeEditing(data);
      this.$bvModal.show("create-custompop")
    },
    OpenPostNow(data){
      this.post_form_data = data;
      this.$bvModal.show("create-custompop");
      this.postNow();
    },

    handleTypeChange(value) {

     this.updateToolbarState(value)

    if(this.isEdited){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to change the post type , it will be reset the form data",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.post_form_data = {
            title: '',
            channel_type: this.post_form_data.channel_type,
            post_anonymously: false,
            feedback_type: "",
            body: "",
            scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
            scheduled_timezone: this.getUserTimezone(),
            scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
            scheduled_hour:"08",
            scheduled_minute:"00",
            scheduled_meridiem:"AM",
                is_scheduled: false,
            other_details:{
              post_type:value,
              author:''
            }
          };
          this.myFiles = [];
          this.page2 = false;
          this.writeai = false;
        }else{
          this.post_form_data.other_details.post_type = this.originalOption;
        }
      });
    }
    },

    confirmTypeChange() {
  
      // Proceed with changing the dropdown value
      this.showConfirmation = false;
      // Perform any other necessary actions
    },
    cancelTypeChange() {
      // Revert to the original option
      this.post_form_data.other_details.post_type = this.originalOption;
      this.showConfirmation = false;
    },
    closeScheduledPopUP(){
      this.$bvModal.hide("scheduled-postview");
    },
    confirmScheduleCloseButton() {
      this.$bvModal.hide("schedule-timepop");
    },
    confirmCloseButton() {
    
    if(this.isEdited ){
      if(!this.post_form_data.hashid && (this.post_form_data.title || this.post_form_data.body)){
        this.$swal({
          title: "Are you sure?",
          text: "Do you want to save your current post as a draft?",
          showCancelButton: true,
          cancelButtonText: "Don't Save",
          confirmButtonText: "Save as Draft",
          customClass: {
            confirmButton: "btn yes-btn primary",
            cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value != true) {

            this.$bvModal.hide("create-custompop");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"08",
              scheduled_minute:"00",
              scheduled_meridiem:"AM",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
            this.writeai = false;
          }else{
            this.saveDraft();
            this.$bvModal.hide("create-custompop");
          }
        });
      }else{
        this.$bvModal.hide("create-custompop");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"08",
              scheduled_minute:"00",
              scheduled_meridiem:"AM",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
            this.writeai = false;
      }
    }
    else{
      this.$bvModal.hide("create-custompop");
    }
    },
    updateChannel(channel) {
      this.$refs.postadd.changeChannel(channel);
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          this.customFields = res.data;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Something went wrong",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: error.toString(),
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          
          this.employeeListOptions = res.data.data;
          this.$cookies.set("employeeListOptions", this.employeeListOptions);
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    checkUser() {
      if (!this.$cookies.get("userData")) {
        this.$router.push("/login");
      }
    },
    fetchChannelList() {

      let data = {};
      data.url = "list/community-channels";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.communityChannels = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
           
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    checkin() {
      this.swipeData.type = "In";
      this.swipeData.browser_agent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      this.swipeData.device_type = deviceType;
      axios.post("/swipe/add", this.swipeData).then((response) => {
        this.userData.last_swipe_type = "In";
        this.$cookies.set(
          "userData",
          this.userData,
          60 * process.env.VUE_APP_LOGIN_TIMEOUT
        );
        this.$nextTick(() => {
          this.$bvModal.hide("modal-1");
        });
        this.$toast.success(response.data.message);
      });
    },
    checkout() {
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';

      axios
        .post("/swipe/add", {
          type: "Out",
          user_feeling: this.swipeData.user_feeling,
          browser_agent: navigator.userAgent,
          device_type: deviceType,
        })
        .then((response) => {
          this.userData.last_swipe_type = "Out";
          this.$cookies.set(
            "userData",
            this.userData,
            60 * process.env.VUE_APP_LOGIN_TIMEOUT
          );
          this.$nextTick(() => {
            this.$bvModal.hide("modal-2");
          });
          this.$toast.success(response.data.message);
        });
    },
    resetModal() {
      this.swipeData.type = "";
      this.swipeData.user_feeling = 6;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      // bvModalEvent.preventDefault();
      // Trigger submit handler
      this.checkin();
    },
    getRecentSwipes() {
      axios.get("/swipes").then((response) => {
        if (response.data.success) {
          this.recentSwipes = response.data.data;
        } else {
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "can't get recent swipes",
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          // this.$toast.error(response.data.message);
        }
      });
    },
    visibilityChanged(isVisible, entry, customArgument) {
      if (isVisible) {
        this.isVisible = isVisible;
        this.getPostData(entry.target.id);
      }
    },
    getPostData(itemId) {
      if (!itemId) {
        return true;
      }

      let data = {};
      data.url = "/posts/" + itemId;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.post_data = res.data.data;
          this.isBusy = false;
          if (res.data.channels) {
            this.unseenBadge = res.data.channels;
          }
          for (let index = 0; index < this.posts.length; index++) {
            var post = this.posts[index];
            if (post.hashid == this.post_data.hashid) {
              this.posts[index] = this.post_data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOnGoingProgram() {

      let data = {};
      data.url = "on-going-secret-santa";
      this.$store.dispatch("app/get", data).then((response) => {
        if (response.data.success) {
          this.onGoingProgram = response.data.data;
        }
      });
    },
    shareUrl(hash_id) {
      return window.location.origin + "?post_hash_id=" + hash_id;
    },
    onCopy: function (e) {
      // this.$toast.success("You just copied URL to the clipboard");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `You just copied URL to the clipboard`,
          icon:'CheckIcon',
          variant : 'success',
          hideClose: true,

        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,

      }) 
    },
    onError: function (e) {
      // this.$toast.error("Failed to copy the URL to the clipboard");
      this.$toast({
		component: ToastificationContent,
		props: {
		  title: `Failed to copy the URL to the clipboard`,
		  icon:'BellIcon',
		  variant : 'danger',
		  hideClose: true,
		  
		},
	      },
	      {
		timeout : 3000,
		position : 'bottom-center',
		toastClassName:"error-info",
		hideProgressBar : true,
	      })
      // console.log(e);
    },
    OpenPost(post) {
      return window.open("?post_hash_id=" + post.id, "_blank");
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    refreshListData() {
      if (this.filterByType == "event") {
        bus.$emit("loadEvents","");
      } else if (this.filterByType == "poll") {
        bus.$emit("loadPolls", "");
      } else {
        this.getPosts();
      }
    },
    loadMore() {
      if (this.currentPage < this.lastPage && this.tempFlag == false) {
        this.tempFlag = true;
        const urlParams = new URLSearchParams(window.location.search);
        const filterType = urlParams.get('filter');
        var filter = null;
        if(filterType){
          if(filterType == 'draft-posts'){
            filter = 'my-draft-posts';
          }
          if(filterType == 'scheduled-posts'){
            filter = 'my-scheduled-posts';
          }
          if(filterType == 'saved-posts'){
            filter = 'my-saved-posts';
          }
        }
        this.isPostLoading = true;
        let params = {};
        
        params = {
          page: this.nextPage,
          channel_type: this.filterByType,
          q: this.searchQuery,
          createdBy: this.$route.params.id,
          filter:filter
        };
        this.$http
          // .get(`/posts?page=${this.nextPage}&channel_type=${this.filterByType}`)
          .get(`/posts`, { params: params })
          .then((res) => {
            this.posts = this.posts.concat(res.data.data);

            const ids = this.posts.map((o) => o.hashid);
            const filtered = this.posts.filter(
              ({ hashid }, index) => !ids.includes(hashid, index + 1)
            );
            this.posts = filtered;

            if (res.data.meta.current_page < res.data.meta.last_page) {
              this.nextPage = res.data.meta.current_page + 1;
            }
            this.lastPage = res.data.meta.last_page;
            this.currentPage = res.data.meta.current_page;
            this.isPostLoading = true;
            if(res.data.meta.total > 0 && this.lastPage  == this.currentPage){
              this.isPostLoading = false;
              this.reachedLastPost = true;
            }
            else if(res.data.meta.total == 0){
              this.isPostLoading = false;
              this.reachedLastPost = false;
            }
            else{
              this.isPostLoading = false;
              this.reachedLastPost = false;
            }
            this.tempFlag = false;
          })
          .catch((error) => {
            this.isPostLoading = false;
          });
      }

    },
    AddFilter(type) {
      this.filterByType = type;
      var nonChannels = ['poll', 'event', 'post']
      if (!nonChannels.includes(type)) {
        this.$emit('updateChannel', type);
    }
    },
    editComment(comment, post_id, index) {

      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Comment adding Failed",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Please enter the comments',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.updatePostArray(res.data.data, index);
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Comment adding Failed',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: values[0][0],
                    },
                  }, {
                    timeout: 2000, 
                    position: 'bottom-center',
                    toastClassName:"radius-8 error-info ",
                    hideProgressBar : true,
                  });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Comment adding Failed',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: "Oops! Something Went Wrong",
                    },
                  }, {
                    timeout: 2000, 
                    position: 'bottom-center',
                    toastClassName:"radius-8 error-info ",
                    hideProgressBar : true,
                  });
          }
        });
      return true;
    },
    getPosts(filter = null,notForList = false){

      this.isPostLoading = true;
      let data = {};
      data.url = "posts";
      const urlParams = new URLSearchParams(window.location.search);
      const filterType = urlParams.get('filter');
      const employeeId = urlParams.get('employee_hash_id');
      if(filterType){
        if(filterType == 'draft-posts'){
          filter = 'my-draft-posts';
        }
        if(filterType == 'scheduled-posts'){
          filter = 'my-scheduled-posts';
        }
        if(filterType == 'saved-posts'){
          filter = 'my-saved-posts';
        }
      }

      data.params = {
        channel_type: this.filterByType,
        q: this.searchQuery,
        createdBy: this.$route.params.id ? this.$route.params.id : employeeId ,
        filter:filter,
        listType:this.postListFilter
      };
      this.$store.dispatch("app/get", data).then((res) => {
        if(notForList == false){
          this.posts = res.data.data;
          this.isPostLoading = false;
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.currentPage = res.data.meta.current_page;
          this.unseenBadge = res.data.unseen_post_count;
          if(res.data.meta.total > 0 && this.lastPage  == this.currentPage){
              this.isPostLoading = false;
              this.reachedLastPost = true;
            }
            else if(res.data.meta.total == 0){
              this.isPostLoading = false;
              this.reachedLastPost = false;
            }else{
              this.isPostLoading = true;
              this.reachedLastPost = false;

            }

          bus.$emit('postsLoaded', this.posts);
        }else{
          if(filter && filter == 'non-published-posts'){
            this.nonPublishedPosts = res.data.data;
          }
        }
      });
    },
    filteredList() {
      if (!this.userSearch.length) {
        return [];
      }
      return this.employeeListOptions.filter((user) =>
        user.label.toLowerCase().includes(this.userSearch.toLowerCase())
      );
    },

    fetchChannelList() {

      let data = {};
      data.url = "list/community-channels";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.communityChannels = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch channel List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    getPostReactions(postID) {
      this.postLikes = null;
      this.$http.get(`/posts/${postID}/likes`).then((res) => {
        const types = ['like', 'clap', 'love', 'celebrate', 'idea', 'laugh'];
        // Initialize an object with empty arrays for each type
        const groupedReactions = types.reduce((acc, type) => {
            acc[type] = [];
            return acc;
        }, {});

        // Populate the groupedReactions object based on the type in each reaction
        res.data.data.forEach(reaction => {
            if (groupedReactions.hasOwnProperty(reaction.type)) {
                groupedReactions[reaction.type].push(reaction);
            }
        });
        groupedReactions['all'] = res.data.data;
        this.postLikes = groupedReactions;

        
      });
    },
    append(emoji) {
      var data = document.getElementById(this.inputId).value;
      var lastTag = data.substring(data.length - 4, data.length);
      if (lastTag == "</p>") {
        data = data.substring(0, data.length - 4) + emoji + lastTag;
        document.getElementById(this.inputId).value = data;
      } else {
        document.getElementById(this.inputId).value += emoji;
      }
    },
    setInputId(inputId) {
      this.inputId = inputId;
    },
    
    kFormatter,

  viewPost(postData) {

  this.postId = postData.hashid;
  
  this.postData = postData;
  this.isPopupVisible = false;
},

    addReply(data, index) {

      this.comment_id = data.hashid;
      this.reply_to = data.user.name;
      this.comment_post_index = index;
      document.getElementById("comment-field-" + index).focus();
    },
    removeReply() {

      this.comment_id = null;
      this.reply_to = null;
    },
    like(comment_id, index) {

      let data = {};
      data.url = "comments/" + comment_id + "/like";
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.updatePostArray(res.data.data, index);
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "liking failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'liking failed',
                icon: 'BellIcon',
                variant: 'danger',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"error-info ",
              hideProgressBar : true,
            });
          console.error(err);
        });
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          this.updatePostArray(res.data.data, index);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });
             
        });
    },
    addComment(data, index) {
      const comment = document.getElementById(`comment-field-${index}`).value;

      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: this.comment_id,
      };
      this.$http
        .post(`/posts/${data.hashid}/comments`, params)
        .then((res) => {
          this.reply_to = null;
          this.comment_id = null;
          this.updatePostArray(res.data.data, index);
          document.getElementById(`comment-field-${index}`).value = "";
          $(".ql-editor").html("");
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    likePost(data, index, type) {
      if (data.youLiked && data.likeType == type) {
        this.$http.delete(`/posts/${data.hashid}/like`).then((res) => {
          this.updatePostArray(res.data.data, index);
        });
      } else {
        this.$http.post(`/posts/${data.hashid}/like/${type}`).then((res) => {
          this.updatePostArray(res.data.data, index);
        });
      }
    },
    savePost(data, index) {
      if (data.youSaved) {
        this.$http.delete(`/saved-models/${data.hashid}/unsave`).then((res) => {
          this.updatePostArray(res.data.data, index);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post removed from saved posts",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Post removed from saved posts`,
              icon:'XCircleIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })

        });
      } else {
        this.$http.post(`/saved-models/${data.hashid}/save`).then((res) => {
          this.updatePostArray(res.data.data, index);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post added to saved posts",
          //     icon: "CheckCircleIcon",
          //     variant: "primary",
          //   },
          // });
          this.$toast({
        component: ToastificationContent,
        props: {
          title: `Post added to saved posts`,
          icon:'CheckIcon',
          variant : 'success',
          hideClose: true,

        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,

      }) 
        });
      }
    },
    updatePostArray(data, index) {
      this.$set(this.posts, index, data);
    },
    confirmDeleteRecord(data) {
      this.$swal({
        // title: "Are you sure?",
        // text: `You want to delete this post ${data.title }.`,
        text: `Deleting this Post can not be undone. Are you sure you want to continue?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    //AI History Delete Popup
    confirmDeleteHistory(data) {
      this.$swal({
        title: "Delete Conversation?",
        // text: `You want to delete this post ${data.title }.`,
        html: '<p class="text-left mb-50 mt-1">You are about to delete "<b>Empowering Teams Through Clear Strategic Alignment</b>"</p><div class="text-left">This action can not be undone.</div>',  
        showCancelButton: true,
        cancelButtonText: "Don't Delete",
        confirmButtonText: "Delete Conversation",
        customClass: {
          confirmButton: "btn btn-outline-secondary cancel-btn light-primarybtn",
          cancelButton: "btn new-confirmbtn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },

    deleteRecord(id) {
      let data = {};
      data.url = "posts/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
          const element = document.getElementById("post-card-" + id);
            if (element) {
              document.getElementById("post-card-" + id).remove();
            }
            
            this.$bvModal.hide("create-custompop");
            this.$bvModal.hide("schedule-timepop");
            bus.$emit("loadPosts");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"08",
              scheduled_minute:"00",
              scheduled_meridiem:"AM",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
            this.writeai = false;
            this.postSubmittedClicked = false;
            this.getPosts('non-published-posts',true);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Post Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Post Deleted Successfully.",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Post Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Post Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          // this.refetchData();
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Post Deletion failed."`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
          console.error(err);
        });
    },
    onSelected(option) {
      this.selected = option.item;
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.data[0].developers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredDesigner = this.datasuggest.data[0].designers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredData = filteredDevelopers.concat(filteredDesigner);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },


  postPublicURL(data) {
    let payLoad = {
      post_id: data.hashid,
    };
  this.$http
  .post(`/posts/post_public_url`, payLoad)
  .then((res) => {

    this.boxOne = '';
    const modalContent = this.$createElement('div', [
  this.$createElement('p', 'Ready to share? Copy the link to this post. Your organization and personal details will remain confidential.'),
  this.$createElement('div', { style: 'border: 1px solid #E6E6E6;display:flex; border-radius: 48px; align-items: center; padding: 3px 3px 3px 15px; margin-bottom: 20px;' }, [
  this.$createElement('strong', `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`),,
    this.$createElement('button', {
      class: 'btn btn-primary copy-btn',
      on: {
        click: () => {
          const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
          navigator.clipboard.writeText(copyText); // Copy text to clipboard
          this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Success',
          //     icon: 'BellIcon',
          //     variant: 'success',
          //     text: 'Link copied to clipboard!',
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: "Link copied to clipboard!",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        },
      },
    }, 'Copy Link'),
  ]),
]);

    this.$bvModal.msgBoxConfirm(
      modalContent,
  {
    title: "Generate Public URL",
    cancelVariant: 'outline-secondary',
    centered: true, // Center the popup
    buttonSize: 'md',      
    hideHeaderClose: false,
    hideFooter: true,
    modalClass: 'public-modal',
    footerClass: 'd-none mb-1',
    hide: true 
  }
).then((value) => {
      // if (value) {
      //   // Perform copy action here
      //   const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
      //   navigator.clipboard.writeText(copyText); // Copy text to clipboard
      //   this.getPosts();
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Success',
      //       icon: 'BellIcon',
      //       variant: 'success',
      //       text: 'link copied to clipboard!',
      //     },
      //   });
      // }
    });
  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: err.response.data.error,
      //     icon: 'BellIcon',
      //     variant: 'danger',
      //     text: err.toString(),
      //   },
      // });
      this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.error,
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
       
    }
  });

},
postRevokePublicURL(data){
  this.$bvModal.msgBoxConfirm(
    'you want to revoke the public link for this post? By confirming, the link will be revoked from all places you shared it.',
    {
      title: 'Are you sure ? ',
      size: 'md',
      okVariant: 'danger',
      okTitle: 'revoke',
      cancelTitle: 'cancel',
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      hideFooter: true,
      centered: true,
      headerClass: 'bg-transparent',
      modalClass: 'public-modal',
      footerClass: 'modal-footer',
    }
  ).then((value) => {
      if (value) {
        this.$http
   .delete(`/posts/revoke_public_url/${data.hashid}`)
   .then((res) => {
    // this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Success',
    //         icon: 'BellIcon',
    //         variant: 'success',
    //         text: res.data.message,
    //       },
    //     });
    this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
      this.getPosts();

  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: err.response.data.error,
      //     icon: 'BellIcon',
      //     variant: 'danger',
      //     text: err.toString(),
      //   },
      // });

      this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.error,
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
       
    }
  });
      }
    });
 
},
copyPublicURL(data){
  const copyText = `${window.location.href.split('?')[0]}public-post/${data}`;
        navigator.clipboard.writeText(copyText); // Copy text to clipboard
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Success',
        //     icon: 'BellIcon',
        //     variant: 'success',
        //     text: 'link copied to clipboard!',
        //   },
        // });

        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'link copied to clipboard!',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
},
  pinPost(data) {
      axios.get("/pin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Pinned",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Pinned',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to pin post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to pin post',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },

    unpinPost(data) {
      axios.get("/unpin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Un-Pined",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Un-Pined',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to unpin post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to unpin post',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },
    getMosReactions(likes) {
      var reactions = {
        like: 0,
        love: 0,
        celebrate: 0,
        clap: 0,
        laugh: 0,
        idea: 0,
      };

      likes.forEach((like) => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter((key) => reactions[key] !== 0);

      return top3;
    },
    approvePost(data) {
      axios.get("post/approve/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Approved",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Approved',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to Approve post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Approve post',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },

    rejectPost(data) {
      axios.get("post/reject/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Reject",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Reject',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to Reject post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Reject post',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },
    markAsRead(type) {
      axios.get("/markAsRead/" + type).then((response) => {
        if (response.data.success) {
          this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Posts marked as read",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Posts marked as read',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to mark posts as read",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to mark posts as read',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },
    fetch_user_data(userId) {

      this.isLoading = true;
      let formData = {};
      formData.url = "profile/" + userId;
      this.$store
        .dispatch("app/get", formData)
        .then((res) => {
          this.profileData.header = res.data.data.header;
          this.profileData.userAbout = res.data.data.userAbout;
          this.profileData.teamMates = res.data.data.teamMates;
          this.profileData.events = res.data.data.events;
          this.profileData = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee data failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee data failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    addOption() {
      this.pollData.options.push("");
    },
    removeOption(index) {
      this.pollData.options.splice(index, 1);
    },
    deleteFile(file, key) {
      this.myFiles.splice(key, 1);
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "File Removed",
      //     icon: "BellIcon",
      //     variant: "success",
      //     text: file.name + " File Removed from the List",
      //   },
      // });

      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'File Removed',
                icon: 'BellIcon',
                variant: 'success',
                text: file.name + " File Removed from the List",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
    },
    getTittleCase(string) {
      if(!string){
        return;
      }
      var final = string.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

      return final;
    },
    getScheduledTimeFormated(date, hour, minute, meridiem) {
      // Parse the input date string to a Date object
      const parsedDate = new Date(date);
      var final = moment(date).format("YYYY-MM-DD")
      // Convert hour to 24-hour format
      let adjustedHour = parseInt(hour, 10);
      if (meridiem.toLowerCase() === 'pm' && adjustedHour !== 12) {
          adjustedHour += 12;
      } else if (meridiem.toLowerCase() === 'am' && adjustedHour === 12) {
          adjustedHour = 0;
      }

      // Set the parsed date with the given hour and minute
      parsedDate.setHours(adjustedHour, parseInt(minute, 10), 0, 0);

      // Format the date to YYYY-MM-DD HH:mm
      const formattedDate = parsedDate.toISOString().slice(0, 10); // Get YYYY-MM-DD
      const formattedTime = parsedDate.toTimeString().slice(0, 5); // Get HH:mm
      
      return `${final} ${formattedTime}`;
    },
    isPast(dateTimeStr, timeZone) {
      // Create the date-time object from the given date-time string
      const givenDateTime = new Date(dateTimeStr);

      // Get the current date-time in the specified timezone
      const now = new Date();
      const currentDateTimeStr = now.toLocaleString('en-US', { timeZone, hour12: false });
      const [date, time] = currentDateTimeStr.split(', ');
      const [month, day, year] = date.split('/');
      const [hour, minute, second] = time.split(':');

      // Create the current date-time object in the specified timezone
      const currentDateTime = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`);

      // Compare the given date-time with the current date-time
      return givenDateTime < currentDateTime;
    },
    isValidDate(dateString) {
      try {
        const valid = moment(dateString).isValid();
        return valid;
      } catch (error) {
        return false;
      }
    },
    Validate(){
      var invalid = false;
      if(this.post_form_data.other_details.post_type == 'text-post'){
        if(!this.post_form_data.body){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Dcscription is required",
          //     icon: "ErrorIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Post Dcscription is required`,
              icon:'ErrorIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
          invalid = true;
        }
      }
      if(this.post_form_data.other_details.post_type == 'photo-post' || this.post_form_data.other_details.post_type == 'video-post'){
        if(!this.myFiles.length && this.post_form_data.attachments && this.post_form_data.attachments.length == 0){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "File is required",
          //     icon: "ErrorIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `File is required`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          invalid = true;
        }
      }
      if(this.post_form_data.other_details.post_type == 'quote-post'){
        if(!this.post_form_data.body){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Quote is required",
          //     icon: "ErrorIcon",
          //     variant: "danger",
          //   },
          // });
                this.$toast({
          component: ToastificationContent,
          props: {
            title: `Quote is required`,
            icon:'ErrorIcon',
            variant : 'danger',
            hideClose: true,
            
          },
              },
              {
          timeout : 3000,
          position : 'bottom-center',
          toastClassName:"error-info",
          hideProgressBar : true,
              })
          invalid = true;
        }
      }
      if((!this.post_form_data.is_published || this.post_form_data.is_published == 'false') && (this.post_form_data.is_scheduled == 'true' || this.post_form_data.is_scheduled === true)){
        if(this.isValidDate(this.post_form_data.scheduled_date)){
          var dateTime = this.getScheduledTimeFormated(this.post_form_data.scheduled_date,this.post_form_data.scheduled_hour,this.post_form_data.scheduled_minute,this.post_form_data.scheduled_meridiem);
          if(this.isPast(dateTime,this.post_form_data.scheduled_timezone)){
            // this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: "The given date-time is in the past.",
            //       icon: "ErrorIcon",
            //       variant: "danger",
            //     },
            //   });

                    this.$toast({
            component: ToastificationContent,
            props: {
              title: `The given date-time is in the past.`,
              icon:'ErrorIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
              invalid = true;
          }
          if(this.isMoreThanOneYear(this.post_form_data.scheduled_date)){
            // this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: "You cannot schedule the post more than one year in advance.",
            //       icon: "ErrorIcon",
            //       variant: "danger",
            //     },
            //   });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `You cannot schedule the post more than one year in advance.`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
              invalid = true;
          }
        }
        if(!this.isValidDate(this.post_form_data.scheduled_date)){
          // this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: "The given date is invalid.",
          //       icon: "ErrorIcon",
          //       variant: "danger",
          //     },
          //   });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `The given date is invalid.`,
              icon:'ErrorIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
            invalid = true;
        }
      }

      return invalid;
    },
    formSubmitted(draft = false,auto = false) {
      var inValid = this.Validate();
      if(inValid && inValid == true){
        return;
      }
      if(this.isValidDate(this.post_form_data.scheduled_date) && this.post_form_data.scheduled_date != moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD")){
        this.post_form_data.scheduled_date = moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD");
      }
      if(draft == false){
        this.postSubmittedClicked = true;
      }
      const formData = new FormData();

      // return;
      // const files = this.$refs.pond.getFiles();
      // const keys = Object.keys(this.$refs.pond.getFiles());

      for (let i = 0; i < this.myFiles.length; i += 1) {
        formData.append(`files[${i}]`, this.myFiles[i].file);
      }

      // const files = self.$refs.pond.files;
      // const keys = Object.keys(self.$refs.pond.files);
      // for (let i = 0; i < keys.length; i += 1) {
      //   formData.append(`files[${i}]`, files[i]);
      // }
      
      
      
      formData.append("channel_type", this.typeChannel);
      formData.append("feedback_type", this.post_form_data.feedback_type);
      formData.append("body", this.post_form_data.body);
      if(this.optionsSelected == 0){
        formData.append("scheduled_time", this.getScheduledTimeFormated(this.post_form_data.scheduled_date,this.post_form_data.scheduled_hour,this.post_form_data.scheduled_minute,this.post_form_data.scheduled_meridiem));
      }else{
        formData.append("scheduled_time", this.post_form_data.scheduled_time);
      
      }
      formData.append("scheduled_timezone", this.post_form_data.scheduled_timezone);
      formData.append("is_scheduled", this.post_form_data.is_scheduled);
      formData.append("files", this.myFiles);
      formData.append("author", this.post_form_data.other_details.author);
      formData.append("post_type", this.post_form_data.other_details.post_type);
      if(this.post_form_data.other_details.post_type == 'photo-post' || this.post_form_data.other_details.post_type == 'video-post'){
        if(this.myFiles.length == 0 && (!this.post_form_data.attachments || this.post_form_data.attachments.length == 0)){
          var type = (this.post_form_data.other_details.post_type == 'photo-post')?'Photo':'Video';
          // this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: "File Missing",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: "Please attach the "+type+" file.",
          //     },
          //   });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'File Missing',
                icon: 'BellIcon',
                variant: 'danger',
                // text: "Please attach the "+type+" file.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          this.postSubmittedClicked = false;
          return;
        }
      }
      if(this.post_form_data.hashid){
          formData.append("hashid", this.post_form_data.hashid);
        }
      if (draft) {
        if(this.post_form_data.is_scheduled && this.post_form_data.is_draft != 'true'){
          formData.append("is_draft", false);
          formData.append("is_scheduled", true);
          formData.append("is_published", false);
        }else{
          formData.append("is_draft", true);
          formData.append("is_published", false);
        }
      } else if (this.post_form_data.is_scheduled) {
        formData.append("is_scheduled", true);
        formData.append("is_published", false);
      } else {
        formData.append("is_draft", false);
        formData.append("is_scheduled", false);
        formData.append("is_published", true);
      }
      if(this.post_form_data.is_scheduled && !this.post_form_data.scheduled_timezone){
        
      formData.append("scheduled_timezone", this.getUserTimezone());
      }

      if(!this.post_form_data.title){
        if(!draft && (this.post_form_data.is_scheduled ||  this.post_form_data.is_published)){
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Please enter a title",
          //     icon: "ErrorIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Please enter a title`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          return ;
        }
        formData.append("title", "(--No Title--)");
      }else{
        formData.append("title", this.post_form_data.title);
      }

      let data = {};
      data.url = "posts";
      this.post_form_data.files = this.myFiles;
      data.params = formData;
      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          if(!auto){
            this.isBusy = false;
            this.$bvModal.hide("create-custompop");
            this.$bvModal.hide("schedule-timepop");
            bus.$emit("loadPosts");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"08",
              scheduled_minute:"00",
              scheduled_meridiem:"AM",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
            this.writeai = false;
            this.postSubmittedClicked = false;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Post Added",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            bomb.realistic();
          }else{
            this.postSubmittedClicked = false;
            this.watchEnabled = false;
            //this.post_form_data = res.data.data;
            if(!this.post_form_data.hashid || this.post_form_data.hashid == null ){
              // Update json1 values with json2 values, excluding the specified key
              for (let key in res.data.data) {
                  if (res.data.data.hasOwnProperty(key) && key !== 'body') {
                    this.post_form_data[key] = res.data.data[key];
                  }
              }
            }
            setTimeout(() => {
              this.watchEnabled = true;
            }, 0);
            this.getPosts('non-published-posts',true);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Changes Saved",
            //     icon: "BellIcon",
            //     variant: "primary",
            //     text: "Post Auto Saved as Draft",
            //   },
            // });
          }       
        })
        .catch((error) => {
          this.isBusy = false;
          this.postSubmittedClicked = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Post Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            
        });
    },

    openWindow(link) {
      window.open(link);
    },
    formUpdate(draft = false) {
      var inValid = this.Validate();
      if(inValid && inValid == true){
        return;
      }
      if(this.isValidDate(this.post_form_data.scheduled_date) && this.post_form_data.scheduled_date != moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD")){
        this.post_form_data.scheduled_date = moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD");
      }
      this.postSubmittedClicked = true;
      const formData = new FormData(document.getElementById("post-form"));
      formData.append("channel_type", this.post_form_data.channel_type);
      formData.append("title", this.post_form_data.title);
      formData.append("feedback_type", this.post_form_data.feedback_type);
      formData.append("body", this.post_form_data.body);;
      formData.append("is_scheduled", this.post_form_data.is_scheduled);
      if(this.post_form_data.is_scheduled == true){
        formData.append("scheduled_time", this.getScheduledTimeFormated(this.post_form_data.scheduled_date,this.post_form_data.scheduled_hour,this.post_form_data.scheduled_minute,this.post_form_data.scheduled_meridiem));
        formData.append("scheduled_timezone", this.post_form_data.scheduled_timezone);
      }
      formData.append("files", this.myFiles);
      formData.append("author", this.post_form_data.other_details.author);
      formData.append("post_type", this.post_form_data.other_details.post_type);
      formData.append("_method","PATCH");

      formData.delete("files[]");
      for (let i = 0; i < this.myFiles.length; i += 1) {
        formData.append(`files[${i}]`, this.myFiles[i].file);
      }

      if(this.post_form_data.is_scheduled && !this.post_form_data.scheduled_timezone){
        
        formData.append("scheduled_timezone", this.getUserTimezone());
        }
      if (draft == true) {
        formData.append("is_draft", true);
        formData.append("is_published", false);
      } else if (this.post_form_data.is_scheduled == true && draft == true) {
        formData.append("is_scheduled", true);
        formData.append("is_draft", true);
        formData.append("is_published", false);
      } else {
        formData.append("is_draft", false);
        formData.append("is_published", true);
      }
      if (!this.post_form_data.is_scheduled) {
        this.$swal({
          title: "Would you like to send update emails to Users?",
          // text: `You want to delete.`,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Send",
          cancelButtonText: "Don't send",
          customClass: {
            confirmButton: "btn yes-btn",
            cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            formData.append("sendNotifications", true);
            this.updatePostAPI(formData);
          } else {
            formData.append("sendNotifications", false);
            this.updatePostAPI(formData);
          }
        });
      } else {
        formData.append("sendNotifications", false);
        this.updatePostAPI(formData);
      }
    },
    updatePostAPI(formData){
      const self = this;
      let data = {};
      data.url = "posts/" + this.post_form_data.hashid;
      this.post_form_data._method = "PATCH";
      data.params = formData;
      this.isBusy = true;
      this.$store
      .dispatch("app/update", data)
      .then((res) => {
        this.isBusy = false;
        this.$bvModal.hide("create-custompop");
        bus.$emit("loadPosts");
        this.post_form_data = {
          title: '',
          channel_type: "community",
          post_anonymously: false,
          feedback_type: "",
          body: "",
          scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
          scheduled_timezone: this.getUserTimezone(),
          scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
          scheduled_hour:"08",
          scheduled_minute:"00",
          scheduled_meridiem:"AM",
          is_scheduled: false,
          other_details:{
            post_type:'text-post',
            author:''
          }
        };
        this.myFiles = [];
        this.page2 = false;
        this.writeai = false;
        this.postSubmittedClicked = false;
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Post Updated",
        //     icon: "BellIcon",
        //     variant: "success",
        //     text: res.data.message,
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        bomb.realistic();
      })
      .catch((error) => {
        this.isBusy = false;
        this.postSubmittedClicked = false;
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Oops! Post Updating Failed",
        //     icon: "BellIcon",
        //     variant: "danger",
        //     text: error,
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
      });
    },
    confirmDeleteFile(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeAttachment(uuid);
        }
      });
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "posts/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          if(res.data.data){
            this.watchEnabled = false;
            this.post_form_data = res.data.data;
            setTimeout(() => {
              this.watchEnabled = true;
            }, 0);
          }
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deleted",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: "Attachment Deleted Successfully.",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Attachment Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Attachment Deletion failed.`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
        });
    },
    getUserTimezone() {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
      return timezone;
    },
    async checkAccess(url, type) {
      try {
        const response = await fetch(url);
        if (type == 'photo') {
          validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
        } else if (type == 'video') {
          validTypes = [
            'video/mp4',
            'video/quicktime',
            'video/avi',
            'video/mov',
            'video/mkv',
            'video/wmv',
            'video/flv',
            'video/webm',
            'video/3gpp',
            'video/mpeg',
            'video/ts',
            'video/vob',
            'video/rm',
            'video/rmvb',
            'video/ogv',
            'video/m4v',
            'video/m2ts',
            'video/asf',
            'video/mxf'
          ];
        }

        // Check if the response status is OK (2xx status codes)
        if (response.ok) {
          const contentType = response.headers.get('Content-Type');

          // Check if the Content-Type indicates a valid file type (e.g., image, application/pdf, etc.)
          if (validTypes.includes(contentType)) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Valid File URL",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "File is Accessable and Valid Type",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Valid File URL',
                icon: 'BellIcon',
                variant: 'success',
                text:  "File is Accessable and Valid Type",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            console.error('Invalid Content-Type: ' + contentType);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Invalid File URL",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Invalid Content-Type , it should be " + type,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invalid File URL',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Invalid Content-Type , it should be " + type,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        }
        else {
          console.error('Request failed with status: ' + response.status);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Invalid File URL",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: "Request failed with status: " + response.status,
          //   },
          // });
           
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invalid File URL',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Request failed with status: " + response.status,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    showVisits(postId) {

    this.postViews = null;
    this.postNotVisited = null;
    var modal = "show-visits-" + postId;
    this.$bvModal.show(modal);
    let data = {};
    data.url = "/post-visits/" + postId;
    this.isBusy = true;
    this.$store
      .dispatch("app/get", data)
      .then((res) => {
        
        this.postViews = res.data.data;
        this.postNotVisited = res.data.not_visited_users
        this.isBusy = false;
      })
      .catch((error) => {
        this.$bvModal.hide(modal);
      });
    },
    getSrc(type) {
      if(this.myFiles && this.myFiles[0] ){
        if(this.myFiles[0].type.includes(type)){
          this.fileError = null;
          // Create a URL for the file object
          this.previewUrl = window.URL.createObjectURL(this.myFiles[0].file);
        }else{
          this.myFiles = [];
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Invalid File Type",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: "Please Upload "+type+ " type file",
          //   },
            
          // });        
          
          this.fileError = "Please Upload "+type+ " type file";  
        }
      }
    },
    openFile(file){
      
      this.openWindow(window.URL.createObjectURL(file.file));
    },
    onSelectEmoji(dataEmoji) {
      // this.data_local.title = this.data_local.title + dataEmoji.emoji;
      const input = document.getElementById('title');
      const cursorPosition = input.selectionStart;
      const currentValue = input.value;
      const smiley = dataEmoji.emoji;  // You can replace this with any smiley you want

      const newValue = currentValue.slice(0, cursorPosition) + smiley + currentValue.slice(cursorPosition);
      input.value = newValue;
      this.post_form_data.title = newValue;

      // Set the cursor position after the inserted smiley
      input.selectionStart = cursorPosition + 2;  // 2 is the length of the smiley
      input.selectionEnd = cursorPosition + 2;
      this.toogleDialogEmoji();
    },
    toogleDialogEmoji($event = null) {
      if ($event) {
        $event.preventDefault();
      }
      this.dialogHidden = !this.dialogHidden;
    },
    generate_year_range(start, end) {
      var years = "";
      for (var year = start; year <= end; year++) {
          years += year;
      }
      return years;
    },

  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.componentId === 'main-aihistory') {
          this.isDropdown2Visible = true;
        }
        });
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        if(bvEvent.componentId === 'history-rename') {
          this.isDropdown2Visible = false;
        }
        if(this.isDropdown2Visible) {
          bvEvent.preventDefault()
        }
        });
    this.autoResize(); // Ensure initial resizing if there is any pre-filled content
    this.startLoading();
    //Body class added
    document.body.classList.add('community-page')
    this.schedule_options_update();
    axios.get("/user").then((response) => {
      const userData = response.data.data;
      this.$cookies.set(
        "userData",
        userData,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      );
    });
    // Add setTimeout to call `delayedFunction` after 30 seconds
   
    if (this.userData.previous_day_attendance_status.NO &&
      (!this.$cookies.get("lastday_status_shown") ||
        this.$cookies.get("lastday_status_shown") != 1)
    ) {
      // this.$toast(
      //   {
      //     component: ToastificationContent,
      //     props: {
      //       title:
      //         "You have not fulfilled min hours required for " +
      //         this.userData.previous_day_attendance_status.No,
      //       icon: "AlertTriangleIcon",
      //       variant: "warning",
      //       text: "Regularization Required for last working day, Please Apply for Regularization for last working day",
      //     },
      //   },
      //   {
      //     timeout: 6000,
      //   }
      // );

      this.$toast({
              component: ToastificationContent,
              props: {
                title:  "You have not fulfilled min hours required for " +
                        this.userData.previous_day_attendance_status.No,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: "Regularization Required for last working day, Please Apply for Regularization for last working day",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

      this.$cookies.set("lastday_status_shown", 1, 60 * process.env.VUE_APP_LOGIN_TIMEOUT);
    }
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > 1800) {
        $(".eventpoll-highlight").addClass("active-sticky");
        $(".celebration-info").hide();
      } else {
        $("eventpoll-highlight").removeClass("active-sticky");
        $(".celebration-info").show();
      }
    });
    /* Navbar Header hide when page scroll */
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 45) {
        document.getElementById("navbar").style.display = "none";
        $('#app').addClass('nav-active');
      } else {
        document.getElementById("navbar").style.display = "initial";
        $('#app').removeClass('nav-active')
      }
    }
    /* Page scroll Community Nav Sticky */
    $(document).ready(function() {
       if($("#user-profile") && $(".profile-header")){
         var stickyTop = $('.profile-header').offset().top;
         $(window).scroll(function() {
           var windowTop = $(window).scrollTop();
           if (stickyTop < windowTop && $("#user-profile").height() + $("#user-profile").offset().top - $(".profile-header").height() > windowTop) {
             $('.profile-header').css('position', 'sticky');
             $('.profile-header').addClass('sticky-nav');
           } else {
             $('.profile-header').css('position', 'relative');
             $('.profile-header').removeClass('sticky-nav');
           }
         });
         $(".click-view").click(function () {
           $("body").toggleClass("hidden");
         });

       }
     });

    if (this.$route.query.post_hash_id) {
      
      this.postId = this.$route.query.post_hash_id;
    }

    const savedFilter = localStorage.getItem('postListFilter');
    if (savedFilter) {
      this.postListFilter = savedFilter;
    }
  },
  destroyed () {
  document.body.classList.remove('community-page')
  },
};
</script>
  
<style lang="scss" >
@import "@core/scss/vue/pages/page-profile.scss";
</style>
  