<template>
  <b-card>
    <h5> Upcoming Events</h5>

    <!-- user suggestion  -->
    <div
      v-for="(data, index) in events"
      :key="data.avatar"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <div class="d-flex mb-1 justify-content-between">
        <div class="pr-1 mr-1 border-right text-center">
          <p class="text-uppercase mb-0">
            {{ data.start_date_n_time | getMonth }}
          </p>
          <h2>
            {{ data.start_date_n_time | getDate }}
          </h2>
        </div>

        <div class="w-100">
          <b-card-title class="">{{ data.name }}</b-card-title>
          <b-card-sub-title>
            Organized by {{ data.user.name | str_limit(30) }}
          </b-card-sub-title>
        </div>
      </div>
    </div>
    <!--/ user suggestion  -->
  </b-card>
</template>

<script>
import { BCard, BAvatar, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
