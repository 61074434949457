<template>
  <b-sidebar
    id="add-sidebar"
    ref="sidebar"
    bg-variant="white"
    :sidebar-class="fullScreen ? 'modal-full-screen' : 'modal-responsive-width'"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:sidebarActive', val)"
    width="60%"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link
        v-if="!fullScreen"
        class="text-primary floating-close"
        @click="confirmCloseButton"
      >
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>

      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 py-6 border-bottom sticky-top"
      >
        <h3 v-if="!data_local.hashid" class="mb-0 font-weight-bold blog-title">
          New Post
        </h3>
        <h3 v-else class="mb-0 font-weight-bold">Edit Post</h3>
        <div class="d-flex align-items-center">
          <!-- <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
          >
            Cancel
          </b-button> -->
          <b-link
            v-if="fullScreen"
            class="btn btn-icon rounded-circle btn-outline-danger mr-1 p-50 close-icon"
            @click="hide"
          >
            <feather-icon icon="XIcon" size="18" class="" />
          </b-link>

          <b-link
            class="btn btn-icon rounded-circle btn-outline-secondary mr-1 p-50 fullscreen-icon"
            @click="fullScreen = !fullScreen"
          >
            <feather-icon
              :icon="!fullScreen ? 'Maximize2Icon' : 'Minimize2Icon'"
              size="18"
              class=""
            />
          </b-link>

          <b-button-group>
            <b-dropdown
              v-if="!data_local.hashid"
              variant="primary"
              :text="data_local.is_scheduled ? 'Schedule Post' : 'Publish'"
              @click="formSubmitted()"
              split
              :disabled="postSubmittedClicked"
            >
              <b-dropdown-item @click="formSubmitted(true)" 
              :disabled="postSubmittedClicked"
                >Save as Draft</b-dropdown-item
              >
            </b-dropdown>
          </b-button-group>
        </div>
      </div>

      <!-- BODY -->
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="post-form" class="p-2">
          <b-row>
            <b-col :md="fullScreen ? '6' : '12'">
              <b-form-group>
                <label class="required-l" for="channel_type">
                  Please choose Channel Type
                </label>
                <v-select
                  v-model="data_local.channel_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customFields.channel_type_select"
                  :clearable="false"
                  :searchable="false"
                  :reduce="(val) => val.value"
                  label="label"
                  input-id="channel_type"
                  name="channel_type"
                  placeholder="Select Channel Type"
                />
              </b-form-group>
            </b-col>
            <b-col
              :md="fullScreen ? '6' : '12'"
              v-if="data_local.channel_type == 'feedback_box'"
            >
              <b-form-group>
                <label class="required-l" for="feedback_type">
                  Feedback Type
                </label>
                <v-select
                  v-model="data_local.feedback_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customFields.feedback_type_select"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="feedback_type"
                  name="feedback_type"
                  placeholder="Select Feedback Type"
                />
              </b-form-group>
            </b-col>
            <b-col
              :md="fullScreen ? '6' : '12'"
              v-if="data_local.channel_type == 'feedback_box'"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="data_local.post_anonymously"
                  name="post_anonymously"
                  class="no-select"
                >
                  Post Anonymously
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              :md="fullScreen ? '6' : '12'"
              v-if="data_local.hashid && $can('Zircly Admin')"
            >
              <b-form-group>
                <label class="required-l" for="status"> Status </label>
                <v-select
                  v-model="data_local.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="status_option"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="status"
                  name="status"
                  placeholder="Select Status"
                />
              </b-form-group>
            </b-col>

            <b-col :md="fullScreen ? '6' : '12'">
              <b-form-group class="position-relative custom-title">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <label class="required-l" for="title">Title</label>
                  <b-form-input
                    id="title"
                    v-model="data_local.title"
                    name="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <div :id="`toolbar-title`" class="ql-snow ql-toolbar emoji-icon">
                    <span class="ql-formats">
                      <button @click="toogleDialogEmoji($event)">
                        <b-img
                          style="width: 1.5rem"
                          :src="require('@/assets/images/pages/smile-svg.svg')"
                          alt="logo"
                        />
                      </button>
                    </span>
                  </div>
                  <VEmojiPicker
                    :pack="emojisNative"
                    labelSearch="Search"
                    @select="onSelectEmoji"
                    class="emoji-picker"
                    :hidden="dialogHidden"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="body"> Body </label>
                <QuillInput
                  :value.sync="data_local.body"
                  :toolbar="true"
                  className="post-editor"
                  :mentionList="employeeListOptions"
                />
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="false" class="">
              <label
                class="custom-file-upload"
                for="custom-input"
                v-b-tooltip.hover.top="'Add Attachment'"
              >
                <input
                  type="file"
                  id="custom-input"
                  ref="pond"
                  name="files"
                  multiple
                  accept=".gif,.jpg,.jpeg,.png,.doc,.docx,video/*,application/pdf"
                  :style="{ display: 'none' }"
                />
                <feather-icon
                  size="20"
                  class="btn-link cursor-pointer"
                  icon="PaperclipIcon"
                />
              </label>
            </b-col>

            <b-col md="12" class="">
              <b-form-group label="Attachments" label-for="files">
                <file-pond
                  ref="pond"
                  name="files[]"
                  max-file-size="50MB"
                  allow-paste="false"
                  max-files="10"
                  allow-multiple="true"
                  v-bind:files="myFiles"
                  image-preview-height="120px"
                  accepted-file-types="image/*,video/*,application/pdf"
                  file-validate-type-label-expected-types="Expects a PDF, Images, Video file"
                />
                <small class="text-warning flex items-center">
                  File size should be less than 50MB and allowed formats are
                </small>
                <p>( .mp4,.pdf, image)</p>
              </b-form-group>
              <div
                class="border-top pt-1"
                v-if="
                  data_local.attachments && data_local.attachments.length > 0
                "
              >
                <b class="text-secondary">Uploaded Attachments</b>

                <div class="d-flex flex-column">
                  <div
                    v-for="(file, index) in data_local.attachments"
                    :key="index"
                    class="d-flex align-items-center justify-content-between my-1"
                  >
                    <img
                      v-if="file.type.includes('image')"
                      :src="file.link"
                      class="img-fluid"
                      width="100px"
                      height="100px"
                      alt="Attachment"
                    />
                    <span
                      v-else
                      class="text-primary"
                      v-b-tooltip.hover.v-default
                      :title="file.name"
                    >
                      {{ file.name | str_limit(40) }}
                    </span>
                    <div>
                      <feather-icon
                        v-b-tooltip.hover.top="'View Attachment'"
                        size="20"
                        class="btn-link cursor-pointer ml-1"
                        icon="EyeIcon"
                        @click.stop.prevent="openWindow(file.link)"
                      />

                      <feather-icon
                        v-b-tooltip.hover.top="'Remove Attachment'"
                        size="20"
                        class="btn-link cursor-pointer ml-1 text-danger"
                        icon="TrashIcon"
                        @click.stop.prevent="removeAttachment(file.uuid)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <b-form-checkbox
                  v-model="data_local.is_scheduled"
                  :checked="data_local.is_scheduled"
                  switch
                >
                  Schedule Post
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="data_local.is_scheduled" md="6">
              <validation-provider
                #default="{ errors }"
                name="Timezone"
                rules="required"
              >
                <b-form-group>
                  <label
                    >Timezone
                    <button
                      v-show="
                        !data_local.scheduled_timezone.includes(
                          getUserTimezone()
                        )
                      "
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="
                        data_local.scheduled_timezone = [
                          ...data_local.scheduled_timezone,
                          getUserTimezone(),
                        ]
                      "
                    >
                      My Timezone({{ getUserTimezone() }})
                    </button>
                  </label>
                  <v-select
                    v-model="data_local.scheduled_timezone"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="timezones"
                    :clearable="false"
                    label="name"
                    :reduce="(option) => option.name"
                    :filter-by="myTimezoneFilter"
                    :searchable="true"
                    input-id="timezone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Timezone.."
                  >
                    <template slot="option" slot-scope="option">
                      <span
                        :class="['flag-icon', `flag icon-${option.name}`]"
                      ></span>
                      {{ option.name }} ({{ option.offset }})
                    </template>

                    <template slot="selected-option" slot-scope="option">
                      <span
                        :class="['flag-icon', `flag icon-${option.name}`]"
                      ></span>
                      {{ option.name }} ({{ option.offset }})
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="data_local.is_scheduled" md="6">
              <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-3"
                  v-model="data_local.scheduled_time"
                  :options="schedule_options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-btn-stacked"
                  buttons
                  button-variant="primary"
                  class="w-100"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col v-if="data_local.is_scheduled" md="6">
              <b-form-group>
                <label class="1required-l" for="scheduled_time"
                  >Pick date & time</label
                >
                <flat-pickr
                  id="scheduled_time"
                  v-model="data_local.scheduled_time"
                  class="form-control"
                  :config="{
                    minDate: 'today',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    altFormat: 'M j, h:iK',
                    altInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  // BButton,
  BButtonGroup,
  BDropdown,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  VBTooltip,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import VEmojiPicker from "v-emoji-picker";
import packEmoji from "v-emoji-picker/data/emojis.js";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import QuillInput from "@/components/QuillInput.vue";
import { bus } from "@/main.js";
import $ from "jquery";
import bomb from "@/libs/bomb/bomb";
let timezones = require("/src/libs/timezone.json");

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    // BButton,
    BButtonGroup,
    BDropdown,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
    VEmojiPicker
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarActive",
    event: "update:sidebarActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    employeeListOptions: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
    customFields: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      postSubmittedClicked:false,
      isBusy: false,
      fullScreen: false,
      userDataLocal: this.$cookies.get("userData"),
      data_local: {
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        body: "",
        title: "",
        scheduled_time: "",
        scheduled_timezone: this.getUserTimezone(),
        is_scheduled: false,
      },
      defaultForm: {
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        body: "",
        title: "",
        scheduled_time: "",
        scheduled_timezone: this.getUserTimezone(),
        is_scheduled: false,
      },
      support_categories: [],
      priority_option: [],
      status_option: [],
      dialogHidden: true,
      myFiles: [],
      channel_types: [],
      scheduleOptions: [],
      ontab:'community',
      schedule_options: [
        {
          text:
            "Tomorrow Morning " +
            moment().add(1, "days").format("MMM Do ") +
            "08:00 AM",
          value: moment().add(1, "days").format("YYYY-MM-DD ") + "08:00",
        },
        {
          text: "This afternoon " + moment().format("MMM Do ") + "01:00 PM",
          value: moment().format("YYYY-MM-DD ") + "13:00",
        },
        {
          text:
            "Monday Morning " + moment().day(8).format("MMM Do ") + "08:00 AM",
          value: moment().day(8).format("YYYY-MM-DD ") + "08:00",
        },
      ],
      timezones: timezones,
      myTimezoneFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.offset.toLowerCase().indexOf(temp) > -1
        );
      },
    };
  },
  created(){
    console.log(this.customFields);
  },
  computed: {
    emojisNative() {
      return packEmoji;
    },
    hasChanged() {
      return Object.keys(this.data_local).some((field) => {
        if (Array.isArray(this.data_local[field])) {
          return (
            JSON.stringify(this.data_local[field]) !=
            JSON.stringify(this.defaultForm[field])
          );
        } else {
          return this.data_local[field] != this.defaultForm[field];
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarActive(val) {
      this.resetFormData();
      // commented to avoid losing data on close.
      this.data_local = {
        channel_type: this.ontab,
        post_anonymously: false,
        feedback_type: "",
        body: "",
        scheduled_time: "",
        scheduled_timezone: this.getUserTimezone(),
        title: "",
        is_scheduled: false,
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
  },
  mounted() {
    this.getScheduleOptions();
  },
  methods: {
    onSelectEmoji(dataEmoji) {
      // this.data_local.title = this.data_local.title + dataEmoji.emoji;
      const input = document.getElementById('title');
      const cursorPosition = input.selectionStart;
      const currentValue = input.value;
      const smiley = dataEmoji.emoji;  // You can replace this with any smiley you want

      const newValue = currentValue.slice(0, cursorPosition) + smiley + currentValue.slice(cursorPosition);
      input.value = newValue;
      this.data_local.title = newValue;

      // Set the cursor position after the inserted smiley
      input.selectionStart = cursorPosition + 2;  // 2 is the length of the smiley
      input.selectionEnd = cursorPosition + 2;
      this.toogleDialogEmoji();
    },
    toogleDialogEmoji($event = null) {
      if($event){
        $event.preventDefault();
      }
      this.dialogHidden = !this.dialogHidden;
    },
    changeChannel(channel){
      this.ontab = channel;
    },
    getScheduleOptions() {
      var array = [];

      // Get the current date and time
      var currentDate = moment();

      // Create the first object for tomorrow morning
      var tomorrowMorning = moment().add(1, "day").set({ hour: 8, minute: 0 });

      // Check if the time has passed
      array.push({
        text: "Tomorrow Morning " + formatDate(tomorrowMorning),
        value: formatDateTime(tomorrowMorning),
      });

      // Create the second object for this afternoon
      var thisAfternoon = moment().set({ hour: 13, minute: 0 });

      // Check if the time has passed
      if (thisAfternoon.isAfter(currentDate)) {
        array.push({
          text: "This afternoon " + formatDate(thisAfternoon),
          value: formatDateTime(thisAfternoon),
        });
      }

      // Create the third object for Monday morning
      var mondayMorning = moment()
        .day(1)
        .add(1, "weeks")
        .set({ hour: 8, minute: 0 });

      // Check if the time has passed
      array.push({
        text: "Monday Morning " + formatDate(mondayMorning),
        value: formatDateTime(mondayMorning),
      });

      // Print the generated array
      this.schedule_options = array;

      // Helper function to format the date as "Month Dayth"
      function formatDate(date) {
        return date.format("MMMM Do hh:mm A");
      }

      // Helper function to format the date and time as "YYYY-MM-DD HH:MM"
      function formatDateTime(date) {
        return date.format("YYYY-MM-DD HH:mm");
      }
    },
    formatCustomTimezone(date, options) {
      return date.toLocaleString("en-US", {
        ...options,
        timeZone: this.data_local.timezone,
      });
    },
    getUserTimezone() {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
      return timezone;
    },
    confirmCloseButton() {
      if (this.hasChanged) {
        this.$swal({
          title: "Are you sure?",
          text: "You are about to exit this form , if you proceed the form data will be lost.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$refs.sidebar.hide();
            this.data_local = {
              channel_type: "community",
              post_anonymously: false,
            };
          }
        });
      } else {
        this.$refs.sidebar.hide();
        this.data_local = {
          channel_type: "community",
          post_anonymously: false,
        };
      }
    },
    resetFormData() {
      this.$refs.accountRules.reset();
      // this.data_local = {
      //   priority: "medium",
      //   status: "open",
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      //   this.data_local.category_id = this.itemData.support_category.hashid;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted(draft = false) {

      this.postSubmittedClicked= true;
      const self = this;
      const formData = new FormData(document.getElementById("post-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      // const files = self.$refs.pond.files;
      // const keys = Object.keys(self.$refs.pond.files);
      // for (let i = 0; i < keys.length; i += 1) {
      //   formData.append(`files[${i}]`, files[i]);
      // }

      formData.append("channel_type", self.data_local.channel_type);
      formData.append("feedback_type", self.data_local.feedback_type);
      formData.append("body", self.data_local.body);
      formData.append("scheduled_time", self.data_local.scheduled_time);
      formData.append("scheduled_timezone", self.data_local.scheduled_timezone);
      formData.append("is_scheduled", self.data_local.is_scheduled);

      if (draft) {
        formData.append("is_draft", true);
        formData.append("is_published", false);
      } else if (self.data_local.is_scheduled) {
        formData.append("is_scheduled", true);
        formData.append("is_published", false);
      } else {
        formData.append("is_draft", false);
        formData.append("is_scheduled", false);
        formData.append("is_published", true);
      }
      let data = {};
      data.url = "posts";
      self.data_local.files = this.myFiles;
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          bus.$emit("loadPosts");
          self.data_local = {
            channel_type: "community",
            post_anonymously: false,
          };
          this.postSubmittedClicked = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          bomb.realistic();
        })
        .catch((error) => {
          this.isBusy = false;
          this.postSubmittedClicked = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Post Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("post-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }
      let data = {};
      data.url = "posts/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Post Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });


          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-posts/fetchPost", hashid)
        .then((res) => {
          this.data_local = res.data.data;
          this.data_local.category_id = res.data.data.support_category.hashid;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
